import { useSelector } from "react-redux";

import { queryParamsSelector } from "store/selectors/routerSelectors";
import { REPORT_TYPES } from "store/utils/constants";
import { QP } from "utils/defaultQueryParams";
import { Values } from "utils/types";

const PERIOD_PARAMS = {
  [REPORT_TYPES.CHART]: QP.CHART_PERIOD,
  [REPORT_TYPES.TABLE]: QP.TABLE_PERIOD
};

const getPeriodParam = (type: Values<typeof REPORT_TYPES>) =>
  PERIOD_PARAMS[type];

export const useExportPeriod = (type: Values<typeof REPORT_TYPES>) => {
  const queryParams = useSelector(queryParamsSelector);
  return String(queryParams[getPeriodParam(type)]);
};
