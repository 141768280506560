import { useSelector } from "react-redux";

import { useRankingFullChartData } from "api/ranking/mappers/useRankingFullChartData";
import { RankingSorting } from "api/ranking/types";

import { useRankingSorting } from "pages/Reports/partials/Chart/HorizontalBarChart/RankingChartSection";
import { itemsPerChartSelector } from "store/selectors/appSelectors";

export const useRankingData = () => {
  const data = useRankingFullChartData();
  const { selected: sorting } = useRankingSorting();
  const itemsPerChart = useSelector(itemsPerChartSelector);

  const sorted = data.sort((a, b) => {
    const valueA = a.primaryValue;
    const valueB = b.primaryValue;
    if (sorting.value === RankingSorting.ASC) return valueA - valueB;
    if (sorting.value === RankingSorting.DESC) return valueB - valueA;

    const sharesA = a.sharesValue || 0;
    const sharesB = b.sharesValue || 0;
    if (sorting.value === RankingSorting.SHARES_ASC) return sharesA - sharesB;
    if (sorting.value === RankingSorting.SHARES_DESC) return sharesB - sharesA;

    return 0;
  });

  return sorted.slice(0, itemsPerChart);
};
