import { useSelector } from "react-redux";

import {
  EXPORT_DATA_TYPE,
  exportDataTypeSelector
} from "pages/Reports/redux/reducers/filters/exportDataTypeReducer";
import { useAreTopFiltersSelected } from "pages/Reports/redux/reducers/filters/filtersReducer";
import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import {
  AggregatedHooks,
  AggregationTypes
} from "pages/Reports/redux/reducers/sweetStateHooks/useAggregated";
import { CountyHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCounty";
import { ShopTypeHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useShopType";
import { ShopTypeMetaHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useShopTypeMeta";
import {
  getSelectedCompanies,
  VendorHooks
} from "pages/Reports/redux/reducers/sweetStateHooks/useVendor";
import { VoivodeshipHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVoivodeship";
import { isCompaniesDropdownMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import {
  hasFreeTrialSelector,
  isPowerUserSelector
} from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { APP_PATHS, isThisPage } from "utils";

const useRegionDisabledFilter = () => {
  const pathname = useSelector(pathnameSelector);

  const [shopsState] = ShopTypeHooks.useShopType();
  const [metaShopsState] = ShopTypeMetaHooks.useShopTypeMeta();

  const exportDataType = useSelector(exportDataTypeSelector);
  const isFreeTrialAccess = useSelector(hasFreeTrialSelector);

  const areAnyShopTypesSelected =
    shopsState.selected.length > 0 || metaShopsState.selected.length > 0;
  const isExportPage = isThisPage(pathname, APP_PATHS.EXPORT);
  const isHoursPage = isThisPage(pathname, REPORTS_FULL_PATHS.HOURS_PATH);
  const isSegmentsPage = isThisPage(pathname, REPORTS_FULL_PATHS.SEGMENTS_PATH);
  const isLoyaltyPage = isThisPage(pathname, REPORTS_FULL_PATHS.LOYALTY_PATH);
  const isMultipackPage = isThisPage(
    pathname,
    REPORTS_FULL_PATHS.MULTIPACK_PATH
  );
  const isExportDataTypeDisabled = exportDataType === EXPORT_DATA_TYPE.SEGMENTS;

  return [
    isFreeTrialAccess,
    areAnyShopTypesSelected,
    isSegmentsPage,
    isHoursPage,
    isLoyaltyPage,
    isMultipackPage,
    isExportPage && isExportDataTypeDisabled
  ].some(Boolean);
};

const useStoreTypeDisabledFilter = () => {
  const pathname = useSelector(pathnameSelector);
  const [voivodeships] = VoivodeshipHooks.useVoivodeship();
  const [counties] = CountyHooks.useCounty();
  const exportDataType = useSelector(exportDataTypeSelector);
  const isMultipackPage = isThisPage(
    pathname,
    REPORTS_FULL_PATHS.MULTIPACK_PATH
  );

  const hasVoivodeships = voivodeships.selected.length > 0;
  const hasCounties = counties.selected.length > 0;
  const isRegionExportDataType =
    exportDataType === EXPORT_DATA_TYPE.VOIVODESHIPS ||
    exportDataType === EXPORT_DATA_TYPE.COUNTIES;

  return [
    hasVoivodeships,
    hasCounties,
    isRegionExportDataType,
    isMultipackPage
  ].some(Boolean);
};

export const useDisabledFilters = (filters: string[]) => {
  const [state] = VendorHooks.useVendor();
  const [{ selected }] = AggregatedHooks.useAggregated();
  const isCategorySelected = useAreTopFiltersSelected();
  const isRegionDisabled = useRegionDisabledFilter();
  const isStoreTypeDisabled = useStoreTypeDisabledFilter();
  const pathname = useSelector(pathnameSelector);
  const isHoursPage = isThisPage(pathname, REPORTS_FULL_PATHS.HOURS_PATH);
  const isLoyaltyPage = isThisPage(pathname, REPORTS_FULL_PATHS.LOYALTY_PATH);
  const isMultipackPage = isThisPage(
    pathname,
    REPORTS_FULL_PATHS.MULTIPACK_PATH
  );
  const isMultiChoice = useSelector(isCompaniesDropdownMultiChoiceSelector);

  const selectedCompanies = getSelectedCompanies(state, isMultiChoice);
  const isCompanySelected = selectedCompanies.length > 0;
  const isPowerUser = useSelector(isPowerUserSelector);

  if (!isCategorySelected) {
    return filters.map(filter => {
      let isDisabled = true;
      if (filter === FILTER_NAMES.CATEGORY_1) {
        isDisabled = false;
      }
      return {
        name: filter,
        isDisabled
      };
    });
  }

  return filters.map(filter => {
    let isDisabled = false;
    switch (filter) {
      case FILTER_NAMES.COUNTY:
      case FILTER_NAMES.VOIVODESHIP:
        isDisabled = isRegionDisabled;
        break;

      case FILTER_NAMES.STORE_TYPE:
      case FILTER_NAMES.STORE_TYPE_META:
        isDisabled = isStoreTypeDisabled;
        break;

      case FILTER_NAMES.AGGREGATION:
      case FILTER_NAMES.REFERENCE_CATEGORY_AGGREGATION:
        isDisabled = isLoyaltyPage;
        break;
      case FILTER_NAMES.COMPETITOR_COMPANY:
      case FILTER_NAMES.COMPETITOR_BRAND:
      case FILTER_NAMES.COMPETITOR_SUB_BRAND:
      case FILTER_NAMES.COMPETITOR_PRODUCT:
      case FILTER_NAMES.DELAY:
        isDisabled =
          (isPowerUser && !isCompanySelected) || isHoursPage || isLoyaltyPage;
        break;
      case FILTER_NAMES.REFERENCE_CATEGORY:
        isDisabled = isPowerUser && !isCompanySelected;
        break;
      case FILTER_NAMES.COMPETITOR_AGGREGATION:
        isDisabled = selected !== AggregationTypes.total;
        break;
      case FILTER_NAMES.LFL:
        isDisabled = isHoursPage || isMultipackPage;
        break;

      default:
        isDisabled = false;
    }
    return {
      name: filter,
      isDisabled
    };
  });
};
