import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { VERSION } from "pages/KnowledgeBase/content";
import { useNewsletter } from "pages/News/queries";
import {
  isAgreementAcceptedSelector,
  tierSelector,
  userSelector
} from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import {
  APP_PATHS,
  BOOL_STRING_VALUES,
  HTTP,
  isThisPage,
  REST_API_ENDPOINTS
} from "utils";
import {
  KNOWLEDGE_BASE_VERSION,
  NEWS_BASE_VERSION,
  USER_ROLES
} from "utils/constants";
import { RESTRICTIONS } from "utils/restrictions";

import {
  ADMIN_MENU_ITEMS,
  MenuItem,
  NEWS_MENU_ITEMS,
  REPORTS_MENU_ITEMS,
  SecondaryMenuType
} from "./constants";

const { TRUE } = BOOL_STRING_VALUES;

export const useFilteredItems = (items: MenuItem[]) => {
  const currentUser = useSelector(userSelector);
  const currentTier = useSelector(tierSelector);

  const userRole = currentUser.role || USER_ROLES.ANALYST;

  const restricted = Object.values(RESTRICTIONS).find(settings => {
    return settings.ID === currentUser.company?.warehouseId;
  });

  return items.filter(({ path, users, tier, key }) => {
    if (restricted) {
      const paths = restricted.PATHS.includes(path);
      if (!paths) return false;
    }

    const isUserValid = users ? users.includes(userRole) : true;
    const isTierValid = tier ? currentTier >= tier : true;
    const isKeyValid = key ? localStorage.getItem(key) === TRUE : true;

    return isUserValid && isTierValid && isKeyValid;
  });
};

export const useInsightsBadge = () => {
  const [badge, setBadge] = useState("");
  const isAgreementAccepted = useSelector(isAgreementAcceptedSelector);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isAgreementAccepted) return;

    const getBadge = async () => {
      const response = await HTTP.get<{ new_insights: boolean }>(
        REST_API_ENDPOINTS.INSIGHTS.CHECK_NEW
      );

      if (response.data.new_insights) {
        setBadge("Nowe");
      }
    };

    getBadge();
  }, [isAgreementAccepted]);

  useEffect(() => {
    if (pathname !== APP_PATHS.INSIGHTS) return;
    setBadge("");
  }, [pathname]);

  return badge;
};

export const useKnowledgeBaseBadge = () => {
  const [badge, setBadge] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    const savedVersion = Number(localStorage.getItem(KNOWLEDGE_BASE_VERSION));

    if (savedVersion < VERSION) {
      setBadge("Nowość");
    }

    if (pathname === APP_PATHS.KNOWLEDGE_BASE) {
      localStorage.setItem(KNOWLEDGE_BASE_VERSION, String(VERSION));
      setBadge("");
    }
  }, [pathname]);

  return badge;
};

export const useNewsBadge = () => {
  const { data } = useNewsletter(1);

  if (!data?.results.length) return "";

  const savedTime = localStorage.getItem(NEWS_BASE_VERSION) || "";
  const latestTime = new Date(data.results[0].created_at).toISOString();

  return latestTime > savedTime ? "Nowość" : "";
};

export const useSecondaryMenu = () => {
  const pathname = useSelector(pathnameSelector);
  let type: SecondaryMenuType = null;
  let items: MenuItem[] = [];

  if (isThisPage(pathname, APP_PATHS.REPORTS)) {
    type = "reports";
    items = REPORTS_MENU_ITEMS;
  }

  if (isThisPage(pathname, APP_PATHS.NEWS)) {
    type = "news";
    items = NEWS_MENU_ITEMS;
  }

  if (isThisPage(pathname, APP_PATHS.ADMIN)) {
    type = "admin";
    items = ADMIN_MENU_ITEMS;
  }

  return { type, items: useFilteredItems(items) };
};
