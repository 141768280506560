import qs from "query-string";

export const transformFetchQueryForLogisticsTable = (
  beQuery: string,
  date: string
) => {
  const parsedQuery = qs.parse(beQuery);

  return qs.stringify({
    ...parsedQuery,
    date_from: date,
    date_to: date
  });
};
