import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { useIsDistributionDataDisabled } from "api/utils/useIsDistributionDataDisabled";

import {
  areLogisticsDataDisabledSelector,
  isValueQuantityDataDisabledSelector
} from "pages/Reports/redux/selectors/dataTypeWarningsSelectors";
import { arePromotionsDisabledSelector } from "pages/Reports/redux/selectors/promotionsSelectors";
import {
  AVG_SHOPS_COUNT_AND_DISTRIBUTION_RANGE_BLOCKED_TOOLTIP,
  INTERNAL_USE_ONLY,
  TOTAL_BLOCKED_BY_COUNTIES_TOOLTIP
} from "pages/Reports/sections/Sell/constants";
import { ChartDataTypes } from "pages/Reports/types/chart";
import {
  CHART_DATA_TYPE,
  LOGISTICS_DATA_TYPES_ONLY_IN_HISTORICAL,
  PROMOTIONS_FOR_DAY_PERIOD_ONLY,
  PROMOTIONS_FOR_HISTORICAL_DATA_ONLY
} from "utils";
import { Values } from "utils/types";

interface Warning {
  value: string;
  isVisible: boolean;
  types: Values<typeof CHART_DATA_TYPE>[];
  isDisabled: boolean;
}

const {
  VALUE,
  QUANTITY,
  AVG_SHOPS_COUNT,
  DISTRIBUTION_RANGE,
  TOTAL_SUPPLY,
  SHOPS_SUPPLY,
  INCOMING_SUPPLY,
  CL_TOTAL_SUPPLY,
  BED_SUPPLY,
  NAD_SUPPLY,
  SZA_SUPPLY,
  TYCH_SUPPLY,
  TYN_SUPPLY,
  RADZ_SUPPLY,
  KM_SUPPLY,
  WPZ_SHOPS_7,
  PLE_SUPPLY,
  WPZ_CL_TOTAL_7,
  WPZ_BED_7,
  WPZ_NAD_7,
  WPZ_PLE_7,
  WPZ_SZA_7,
  WPZ_TYCH_7,
  WPZ_TYN_7,
  WPZ_RADZ_7,
  WPZ_KM_7,
  WPZ_SHOPS_30,
  WPZ_CL_TOTAL_30,
  WPZ_BED_30,
  WPZ_NAD_30,
  WPZ_PLE_30,
  WPZ_SZA_30,
  WPZ_TYCH_30,
  WPZ_TYN_30,
  WPZ_RADZ_30,
  WPZ_KM_30,
  WPZ_SHOPS_90,
  WPZ_CL_TOTAL_90,
  WPZ_BED_90,
  WPZ_NAD_90,
  WPZ_PLE_90,
  WPZ_SZA_90,
  WPZ_TYCH_90,
  WPZ_TYN_90,
  WPZ_RADZ_90,
  WPZ_KM_90,
  ZESTAWY,
  PODKLADKA_ALKOHOLOWA,
  ODLICZANIE,
  ODLICZANIE_WSO,
  MENUBOARD,
  KATALOG,
  KATALOG_WSO,
  HAPPY_HOURS,
  GAZETKA,
  GAZETKA_WSO,
  APLIKACJA,
  AKCJA_POLECAMY,
  PLANOGRAM_DISTRIBUTION_RANGE,
  AVG_PLANOGRAM_SHOPS_COUNT
} = CHART_DATA_TYPE;

export const DISTRIBUTION_METRICS = [
  AVG_SHOPS_COUNT,
  DISTRIBUTION_RANGE,
  PLANOGRAM_DISTRIBUTION_RANGE,
  AVG_PLANOGRAM_SHOPS_COUNT
];

export const useGetDisabledDataTypeTooltip = () => {
  const isValueQuantityDataDisabled = useSelector(
    isValueQuantityDataDisabledSelector
  );
  const arePromotionsDisabled = useSelector(arePromotionsDisabledSelector);
  const isDistributionDataDisabled = useIsDistributionDataDisabled();
  const areLogisticsDataDisabled = useSelector(
    areLogisticsDataDisabledSelector
  );

  const WARNINGS: Warning[] = useMemo(
    () => [
      {
        value: TOTAL_BLOCKED_BY_COUNTIES_TOOLTIP,
        isVisible: isValueQuantityDataDisabled,
        types: [VALUE, QUANTITY],
        isDisabled: true
      },
      {
        value: PROMOTIONS_FOR_DAY_PERIOD_ONLY,
        isVisible: arePromotionsDisabled,
        types: [
          ZESTAWY,
          PODKLADKA_ALKOHOLOWA,
          ODLICZANIE,
          ODLICZANIE_WSO,
          MENUBOARD,
          KATALOG,
          KATALOG_WSO,
          HAPPY_HOURS,
          GAZETKA,
          GAZETKA_WSO,
          APLIKACJA,
          AKCJA_POLECAMY
        ],
        isDisabled: true
      },
      {
        value: PROMOTIONS_FOR_HISTORICAL_DATA_ONLY,
        isVisible: areLogisticsDataDisabled,
        types: [
          ZESTAWY,
          PODKLADKA_ALKOHOLOWA,
          ODLICZANIE,
          ODLICZANIE_WSO,
          MENUBOARD,
          KATALOG,
          KATALOG_WSO,
          HAPPY_HOURS,
          GAZETKA,
          GAZETKA_WSO,
          APLIKACJA,
          AKCJA_POLECAMY
        ],
        isDisabled: true
      },
      {
        value: AVG_SHOPS_COUNT_AND_DISTRIBUTION_RANGE_BLOCKED_TOOLTIP,
        isVisible: isDistributionDataDisabled,
        types: DISTRIBUTION_METRICS,
        isDisabled: true
      },
      {
        value: LOGISTICS_DATA_TYPES_ONLY_IN_HISTORICAL,
        isVisible: areLogisticsDataDisabled,
        types: [
          TOTAL_SUPPLY,
          SHOPS_SUPPLY,
          INCOMING_SUPPLY,
          CL_TOTAL_SUPPLY,
          BED_SUPPLY,
          NAD_SUPPLY,
          PLE_SUPPLY,
          SZA_SUPPLY,
          TYCH_SUPPLY,
          TYN_SUPPLY,
          RADZ_SUPPLY,
          KM_SUPPLY,
          WPZ_SHOPS_7,
          WPZ_CL_TOTAL_7,
          WPZ_BED_7,
          WPZ_NAD_7,
          WPZ_PLE_7,
          WPZ_SZA_7,
          WPZ_TYCH_7,
          WPZ_TYN_7,
          WPZ_RADZ_7,
          WPZ_KM_7,
          WPZ_SHOPS_30,
          WPZ_CL_TOTAL_30,
          WPZ_BED_30,
          WPZ_NAD_30,
          WPZ_PLE_30,
          WPZ_SZA_30,
          WPZ_TYCH_30,
          WPZ_TYN_30,
          WPZ_RADZ_30,
          WPZ_KM_30,
          WPZ_SHOPS_90,
          WPZ_CL_TOTAL_90,
          WPZ_BED_90,
          WPZ_NAD_90,
          WPZ_PLE_90,
          WPZ_SZA_90,
          WPZ_TYCH_90,
          WPZ_TYN_90,
          WPZ_RADZ_90,
          WPZ_KM_90,
          AVG_SHOPS_COUNT,
          AVG_PLANOGRAM_SHOPS_COUNT
        ],
        isDisabled: true
      },
      {
        value: INTERNAL_USE_ONLY,
        isVisible: !isDistributionDataDisabled,
        types: [PLANOGRAM_DISTRIBUTION_RANGE, AVG_PLANOGRAM_SHOPS_COUNT],
        isDisabled: false
      }
    ],
    [
      isValueQuantityDataDisabled,
      arePromotionsDisabled,
      areLogisticsDataDisabled,
      isDistributionDataDisabled
    ]
  );

  const callback = useCallback(
    (type: ChartDataTypes) => {
      const warning = WARNINGS.find(
        ({ types, isVisible }) => isVisible && types.includes(type)
      );

      if (!warning) return { value: "", isDisabled: false };

      return { value: warning.value, isDisabled: warning.isDisabled };
    },
    [WARNINGS]
  );

  return callback;
};
