import { getLocationChartUniqData } from "api/location/utils/getLocationChartUniqData";
import { shouldMergeTimeline } from "api/utils";

import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { LocationRawDataset } from "pages/Reports/sections/Location/redux/types/locationRawTypes";
import {
  findDateDomain,
  findLastYearDomainLength
} from "pages/Reports/sections/utils/chartData";

export const useLocationGridChartDomain = (
  data: LocationRawDataset | undefined,
  isForHistoricalChart: boolean
) => {
  const dataset = useCurrentDataset();
  const { isGroupedBarChart } = useChartTypeBoolean();

  if (!data) {
    return { domain: [], lastYearDomainLength: 0, isTimelineMerged: false };
  }

  const uniqData = data.data.data.reduce(getLocationChartUniqData, []);
  const timeline = uniqData[0]?.data[0]?.timeline || [];
  let domain = findDateDomain(
    timeline,
    isForHistoricalChart || dataset.historical
  );
  const lastYearDomainLength = findLastYearDomainLength(timeline);
  const mergeTimeline = shouldMergeTimeline({
    isBothDatasets: dataset.both,
    isGroupedBarChart,
    domainLength: domain.length
  });

  if (!isForHistoricalChart && mergeTimeline) {
    domain = [...findDateDomain(timeline, true), ...domain];
  }

  return { domain, lastYearDomainLength, isTimelineMerged: mergeTimeline };
};
