import {
  Action,
  createHook,
  createStore,
  defaultRegistry
} from "react-sweet-state";

import { DropdownItem } from "components/molecules/types";
import {
  getSelectedCountLabel,
  remainingElementsToSelect,
  selectElement
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import { sortItemsBySelectionOrder } from "pages/Reports/redux/utils";
import { VOIVODESHIPS_ITEMS } from "utils";

export type VoivodeshipItem = DropdownItem<string>;

type State = { all: VoivodeshipItem[]; selected: VoivodeshipItem[] };
type Actions = typeof actions;

const actions = {
  updateAllVoivodeships: (items: VoivodeshipItem[]): Action<State> => ({
    setState
  }) => setState({ all: items }),
  updateSelectedVoivodeships: (items: VoivodeshipItem[]): Action<State> => ({
    setState
  }) => setState({ selected: items }),
  selectVoivodeship: (
    selectedVoivodeship: VoivodeshipItem | null
  ): Action<State> => ({ setState, getState }) =>
    setState({
      selected: selectElement(selectedVoivodeship, getState().selected)
    }),
  selectAllVoivodeships: (inputValue: string): Action<State> => ({
    setState,
    getState
  }) => {
    const state = getState();
    const allVoivodeships = state.all;
    const selectedVoivodeships = state.selected;

    const remainingToSelect = remainingElementsToSelect(
      selectedVoivodeships,
      allVoivodeships,
      inputValue
    );
    setState({
      selected: [...selectedVoivodeships, ...remainingToSelect]
    });
  },
  sortVoivodeships: (): Action<State> => ({ setState, getState }) => {
    const state = getState();

    const allVoivodeships = state.all;
    const selectedVoivodeshipsIds = state.selected.map(item => item.value);

    const sortedAllVoivodeships = sortItemsBySelectionOrder(
      selectedVoivodeshipsIds,
      allVoivodeships
    );
    setState({
      all: sortedAllVoivodeships
    });
  },
  clearVoivodeships: (): Action<State> => ({ setState }) => {
    setState({ selected: [] });
  },
  selectInitialVoivodeships: (params: string | string[]): Action<State> => ({
    setState,
    getState,
    dispatch
  }) => {
    const selected = getState().all.filter(({ value }) =>
      typeof params === "string" ? value === params : params.includes(value)
    );

    setState({ selected });
    dispatch(actions.sortVoivodeships());
  }
};

export const VoivodeshipStore = createStore<State, Actions>({
  name: "voivodeship",
  initialState: {
    all: VOIVODESHIPS_ITEMS,
    selected: []
  },
  actions
});

export const VoivodeshipHooks = {
  useVoivodeship: createHook(VoivodeshipStore),
  useVoivodeshipLabel: createHook(VoivodeshipStore, {
    selector: (state: State) => {
      if (state.selected.length === 1) {
        return state.selected[0].label;
      }
      return getSelectedCountLabel(state.selected.length, state.all.length);
    }
  }),
  useSelectedVoivodeshipIds: createHook(VoivodeshipStore, {
    selector: (state: State) => state.selected.map(item => item.value)
  })
};

export const VoivodeshipStoreInstance = defaultRegistry.getStore(
  VoivodeshipStore
);
