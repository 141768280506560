import { useSelector } from "react-redux";

import { EMPTY_CHART } from "api/consts";
import { useGetLoyaltyByPeriod } from "api/loyalty/mappers/useGetLoyaltyByPeriod";
import { ChartDataOptions } from "api/sells/mappers/useSellsChartData";
import {
  createHistoricalTimeline,
  createMergedTimeline,
  createTimeline,
  shouldMergeTimeline,
  sortBarChartData
} from "api/utils";
import { useChartLabels } from "api/utils/useChartLabels";
import { omit } from "ramda";

import { ChartData } from "components/D3/types";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useBarChartSorting } from "hooks/useBarChartSorting";
import { useLegendContentNames } from "pages/Reports/partials/Chart/Legend/hooks/useLegendContentNames";
import { createLegendId } from "pages/Reports/partials/Chart/Legend/utils/createLegendId";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import {
  hiddenLinesByReportSelector,
  pinnedTimepointSelector,
  updateHoveredTimepoint,
  updatePinnedTimepoint
} from "pages/Reports/redux/reducers/chartReducer";
import {
  checkedDataTypes,
  findDateDomain,
  findLastYearDomainLength
} from "pages/Reports/sections/utils/chartData";
import { addColor } from "pages/Reports/sections/utils/color";
import { getChartLabel } from "pages/Reports/sections/utils/getChartLabel";
import {
  chartRange,
  valueByDataType,
  valueByDataTypeBoolean
} from "pages/Reports/utils";
import {
  chartPeriodSelector,
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import {
  mapLastYearTimelineByType,
  mapTimelineByType
} from "store/utils/chartUtils";
import { PERIOD_TYPE } from "utils";
import { isSameScaleMetrics } from "utils/isSameScaleMetrics";

export const useLoyaltyChartData = ({
  isForHistoricalChart = false
}: ChartDataOptions): ChartData[] => {
  const {
    data: loyalty,
    isLoading: isLoyaltyLoading,
    isIdle: isLoyaltyIdle
  } = useGetLoyaltyByPeriod();
  const firstChartType = useSelector(firstChartDataTypeSelector);
  const secondChartType = useSelector(secondChartDataTypeSelector);
  const hiddenLines = useSelector(hiddenLinesByReportSelector);
  const period = useSelector(chartPeriodSelector);
  const pinnedTimepoint = useSelector(pinnedTimepointSelector);
  const dataset = useCurrentDataset();
  const labels = useChartLabels();
  const { isGroupedBarChart, isIncrementalComboChart } = useChartTypeBoolean();
  const contentNames = useLegendContentNames();
  const barChartSorting = useBarChartSorting();

  const isFetching = isLoyaltyIdle || isLoyaltyLoading;

  if (!loyalty) {
    return [EMPTY_CHART];
  }

  const timeline = loyalty.currentPeriod[0]?.timeline || [];
  let domain = findDateDomain(
    timeline,
    isForHistoricalChart || dataset.historical
  );
  const mergeTimeline = shouldMergeTimeline({
    isBothDatasets: dataset.both,
    isGroupedBarChart,
    domainLength: domain.length
  });

  if (isForHistoricalChart && mergeTimeline) {
    return [EMPTY_CHART];
  }

  const dataTypes = checkedDataTypes([firstChartType, secondChartType]);
  const isTotal = period.toUpperCase() === PERIOD_TYPE.TOTAL;
  const lastYearDomainLength = isTotal ? 1 : findLastYearDomainLength(timeline);
  const showBarChart = domain.length === 1;
  const barChartSortingTimepoint = barChartSorting.getSortingTimepoint({
    dataTypes,
    showBarChart,
    isGroupedBarChart,
    pinnedTimepoint
  });

  if (mergeTimeline) {
    domain = [...findDateDomain(timeline, true), ...domain];
  }

  // Sorting is important. By name and type and then by existing metrics, so existing are first in array and color is not wasted on non existing metrics
  const sorted = loyalty?.currentPeriod
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => b.type.localeCompare(a.type))
    .sort(
      (a, b) =>
        Number(valueByDataType(firstChartType, b.existingMetrics)) -
        Number(valueByDataType(firstChartType, a.existingMetrics))
    );

  const chartData = dataTypes.map((dataType, dataTypeIndex) => {
    const dataWithColor = addColor(sorted, dataType);

    if (barChartSortingTimepoint !== null) {
      dataWithColor.sort(
        sortBarChartData(
          barChartSorting.dataKey,
          barChartSorting.isDesc,
          barChartSortingTimepoint
        )
      );
    }

    const allLines = dataWithColor.map(loyalty => {
      const chartLabel = getChartLabel({
        vendorId: loyalty.vendorId,
        resultId: loyalty.resultId,
        type: loyalty.type
      });

      const id = createLegendId(chartLabel, contentNames, loyalty.type);

      return {
        ...omit(["timeline"], loyalty),
        chartLabel,
        id,
        timeline: mergeTimeline
          ? createMergedTimeline(dataType, loyalty.timeline)
          : dataset.historical
          ? createHistoricalTimeline(dataType, loyalty.timeline, true)
          : createTimeline(dataType, loyalty.timeline),
        ...(dataset.both && {
          lastYearTimeline: createHistoricalTimeline(
            dataType,
            loyalty.timeline,
            isForHistoricalChart
          )
        })
      };
    });

    const visibleLines = allLines
      .filter(timeline => !hiddenLines.includes(timeline.id))
      .filter(timeline =>
        valueByDataTypeBoolean(dataType, timeline.existingMetrics, dataset)
      );

    return {
      dataType,
      visibleLines,
      allLines,
      ...chartRange(visibleLines, {
        isRightChartData: dataTypeIndex === 1,
        isGroupedBarChart,
        isIncrementalComboChart
      })
    };
  });

  const isSameScale = isSameScaleMetrics(dataTypes);
  const overallMaxValue = isSameScale
    ? Math.max(...chartData.map(data => data.maxValue))
    : 0;

  const filtered = chartData.map(chart => ({
    dataType: chart.dataType,
    minValue: chart.minValue,
    maxValue: overallMaxValue || chart.maxValue,
    chart: chart.visibleLines.map(chart => ({
      id: chart.id,
      label: chart.chartLabel,
      color: chart.color,
      timeline: dataset.historical
        ? mapLastYearTimelineByType(
            chart.type,
            chart.timeline,
            lastYearDomainLength
          )
        : mapTimelineByType(chart.type, chart.timeline),
      ...(chart.lastYearTimeline && {
        lastYearTimeline: mapLastYearTimelineByType(
          chart.type,
          chart.lastYearTimeline,
          lastYearDomainLength
        )
      }),
      type: chart.type
    }))
  }));

  return [
    {
      leftChart: filtered[0],
      rightChart: filtered[1],
      domain,
      period,
      labels,
      isFetching,
      showBarChart: domain.length === 1,
      onTimelineHover: updateHoveredTimepoint,
      onTimelineClick: updatePinnedTimepoint,
      isHistoricalGroupedChart: isForHistoricalChart,
      isTimelineMerged: mergeTimeline
    }
  ];
};
