import { ParsedQuery } from "query-string";

import { User } from "store/reducers/userReducer";
import { toArray } from "store/utils";
import { QP } from "utils/defaultQueryParams";

const FILTERS_TO_CLEAR_WITHOUT_VENDOR = [
  QP.COMPETING_COMPANIES,
  QP.COMPETING_BRANDS,
  QP.COMPETING_SUB_BRANDS,
  QP.COMPETING_MATERIALS,
  QP.REF_CAT1,
  QP.REF_CAT2,
  QP.REF_CAT3
];

export const adjustVendorIdParam = (
  params: ParsedQuery<string>,
  user: User,
  isPowerUser: boolean,
  isCompaniesDropdownMultiChoice: boolean
): ParsedQuery<string | string[]> => {
  const userVendorId = user.company?.warehouseId;
  const hasUserOwnVendorId: boolean = !isPowerUser && Boolean(userVendorId);

  // apply warehouse id assigned to the non-power users
  if (hasUserOwnVendorId) {
    params[QP.VENDOR_ID] = userVendorId;
    return params;
  }

  // [PMD-5079]: clear other related filters if no vendor is selected
  if (!params[QP.VENDOR_ID]) {
    FILTERS_TO_CLEAR_WITHOUT_VENDOR.forEach(key => {
      if (key in params) params[key] = "";
    });
    return params;
  }

  // select first vendor id in case company dropdown isn't multi choice and there is many vendors
  const vendorIds = toArray(params[QP.VENDOR_ID]);
  const isMultipleVendorsSelected =
    !isCompaniesDropdownMultiChoice && vendorIds.length > 1;

  if (isMultipleVendorsSelected) {
    params[QP.VENDOR_ID] = vendorIds[0];
  }

  return params;
};
