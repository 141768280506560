import React from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";

import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useFiltersFetchingParams } from "pages/Reports/redux/reducers/filters/hooks/useFiltersFetchingParams";
import {
  BrandHooks,
  BrandItem,
  BrandType
} from "pages/Reports/redux/reducers/sweetStateHooks/useBrand";
import { referenceSubBrandQueryParamSelector } from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";

export type BrandRequestParams = {
  brands: string;
  categories: string;
  reference_companies: boolean;
  sub_brands: boolean;
  tier: string;
};

const useFilterParams = (): BrandRequestParams => {
  const params = useFiltersFetchingParams([
    "category1",
    "tier",
    "referenceBrand"
  ]);

  return {
    brands: params.referenceBrand,
    categories: params.category1,
    reference_companies: true,
    sub_brands: true,
    tier: params.tier
  };
};

const useFetchingFlag = (
  params: {
    categories: string;
    tier: string;
  },
  isDisabled: boolean
) => {
  const isLoading = useSelector(asyncFiltersSelector).fetching
    .referenceSubBrand;
  const lastQuery = useSelector(asyncFiltersSelector).query.referenceSubBrand;

  const query = qs.stringify(params);
  if (isLoading || isDisabled) {
    return false;
  }

  if (query === lastQuery) {
    return false;
  }

  if (!params.categories || !params.tier) {
    return false;
  }

  return true;
};

const useReferenceSubBrandFetching = (isDisabled: boolean) => {
  const filter = BrandType.referenceSubBrand;
  const [, actions] = BrandHooks.useBrand();
  const dispatch = useDispatch();
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const refSubBrandQP = useSelector(referenceSubBrandQueryParamSelector);

  const params = useFilterParams();
  const isFetchingPossible = useFetchingFlag(params, isDisabled);

  const callback = React.useCallback(
    (brands: BrandItem[]) => {
      actions.updateAllBrands(brands, filter);
      if (isPristine) {
        actions.checkPristineSelectionsAfterFetching(
          brands,
          refSubBrandQP,
          filter
        );
      }
      actions.checkSelectionsAfterFetching(
        brands.map(cmp => cmp.value),
        filter
      );
      actions.sortBrands(filter);
    },
    [isPristine, actions, refSubBrandQP, filter]
  );

  React.useEffect(() => {
    if (!isFetchingPossible) {
      return;
    }

    const fetch = async () => {
      try {
        dispatch(updateQuery({ filter, query: qs.stringify(params) }));

        dispatch(setFlag({ filter, isFetching: true }));

        const brands = await HTTP.get<{ id: number; name: string }[]>(
          REST_API_ENDPOINTS.BRANDS,
          { params }
        );

        dispatch(setError({ filter, status: "" }));
        return brands.data.map(brand => ({
          value: String(brand.id),
          label: brand.name
        }));
      } catch (e) {
        dispatch(setError({ filter, status: "Błąd pobierania submarek ref." }));
        return [];
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };

    fetch().then(callback);
  }, [dispatch, isFetchingPossible, params, callback, filter]);
};

export const useReferenceSubBrandBehaviour = (isDisabled: boolean) => {
  useReferenceSubBrandFetching(isDisabled);
};
