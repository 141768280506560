import { useSelector } from "react-redux";

import {
  color as Color,
  curveNatural,
  line,
  ScaleLinear,
  ScalePoint
} from "d3";

import { ChartDataPart, ChartTimePoint } from "components/D3/types";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { hoveredLineTypeSelector } from "pages/Reports/redux/reducers/chartReducer";
import { REPORTS_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { DATASET_TYPES } from "utils";
import { BOOL_STRING_VALUES, isWeatherDataType } from "utils/constants";
import { Nullable } from "utils/types";

import {
  LineShape,
  PRIMARY_RIGHT,
  REFERENCE_LEFT,
  REFERENCE_RIGHT,
  SECONDARY_LEFT,
  SECONDARY_RIGHT
} from "../LinesLegend/constants";
import { LINE_TYPES } from "../LinesLegend/LinesLegend";
import s from "./linesGroup.module.scss";
import { getLineOpacity } from "./utils/getLineOpacity";

type Props = {
  isHistoricalGroupedChart?: boolean;
  data: Nullable<ChartDataPart>;
  xScale: ScalePoint<string>;
  yScale: ScaleLinear<number, number>;
  hoveredLine: string[];
  isOneTimepoint: boolean;
  isRightChart?: boolean;
};

const calcPath = ({
  xScale,
  yScale,
  timeline,
  isCurved
}: {
  xScale: ScalePoint<string>;
  yScale: ScaleLinear<number, number>;
  timeline: Nullable<ChartTimePoint[]>;
  isCurved: boolean;
}) => {
  if (!timeline) return null;

  const lineGenerator = line<[number, Nullable<number>]>().defined(
    el => el[1] !== null
  );

  if (isCurved) {
    lineGenerator.curve(curveNatural);
  }

  return (
    lineGenerator(
      timeline.map(({ valueX, valueY }) => [
        xScale(valueX) || 0,
        valueY === null ? null : yScale(valueY)
      ])
    ) || ""
  );
};

export const LinesGroup: React.FC<Props> = ({
  data,
  xScale,
  yScale,
  hoveredLine,
  isOneTimepoint,
  isRightChart = false,
  isHistoricalGroupedChart = false
}: Props) => {
  const pathname = useSelector(pathnameSelector);
  const hoveredLineType = useSelector(hoveredLineTypeSelector);
  const { isGroupedBarChart } = useChartTypeBoolean();
  const dataType = data?.dataType;
  const isLocationPage = pathname.includes(REPORTS_PATHS.LOCATION);
  const isSegmentsPage = pathname.includes(REPORTS_PATHS.SEGMENTS);
  const isLogisticsPage = pathname.includes(REPORTS_PATHS.LOGISTICS);

  if ((isGroupedBarChart && isOneTimepoint) || !dataType) {
    //show DotsChart instead of LinesGroup
    return null;
  }

  const isWeatherData = isWeatherDataType(dataType);

  const lines = data?.chart || null;
  if (!lines || lines.length === 0) return null;

  const linesToRender = isWeatherData ? [lines[0]] : lines;

  const getMainLineStrokeDetails = (isReference: boolean): LineShape => {
    const isNotReferenceLinesOnGroupedBarChart =
      !isReference && isGroupedBarChart;

    if (isRightChart && isWeatherData) return PRIMARY_RIGHT;
    if (isRightChart && isReference) return REFERENCE_RIGHT;
    if (isRightChart) return PRIMARY_RIGHT;
    if (isReference && !isWeatherData) return REFERENCE_LEFT;
    if (isNotReferenceLinesOnGroupedBarChart && !isOneTimepoint)
      // hide all regular lines on GroupedBarChart (show only reference lines)
      return ["0, 1", undefined];

    return ["0", "square"]; // regular line
  };

  const getStrokeWidth = (lineId: string, lineType: string) => {
    return hoveredLine.includes(lineId) || hoveredLineType.includes(lineType)
      ? 4
      : 2;
  };

  return (
    <g>
      {linesToRender.map(
        ({
          timeline,
          color,
          label,
          id,
          chartName = "",
          type,
          secondaryTimeline = null,
          lastYearTimeline = null,
          secondaryLastYearTimeline = null
        }) => {
          const isReference =
            type === DATASET_TYPES.REFERENCE ||
            type === DATASET_TYPES.REFERENCE_BRAND;
          let lineId = id || "";
          if (isSegmentsPage || isLogisticsPage || isLocationPage)
            lineId = label;

          const opacity = getLineOpacity({
            chartName,
            id: lineId,
            isLocationPage,
            hoveredLine
          });
          const [dasharray, linecap] = getMainLineStrokeDetails(isReference);
          const isCurved = isReference && !isWeatherData;

          //on grouped bar chart we're showing lastYearPath in separate chart
          const defualtTimeline =
            isGroupedBarChart && isHistoricalGroupedChart
              ? lastYearTimeline
              : timeline;

          const defaultPath = calcPath({
            xScale,
            yScale,
            timeline: defualtTimeline,
            isCurved
          });
          const secondaryPath = calcPath({
            xScale,
            yScale,
            timeline: secondaryTimeline,
            isCurved
          });
          const lastYearPath = calcPath({
            xScale,
            yScale,
            timeline: lastYearTimeline,
            isCurved
          });
          const secondaryLastYearPath = calcPath({
            xScale,
            yScale,
            timeline: secondaryLastYearTimeline,
            isCurved
          });

          const transform = `translate(${xScale.bandwidth() / 2}, ${0})`;
          const transformedColor = isGroupedBarChart
            ? Color(color)
                ?.brighter(0.4)
                .toString()
            : color;

          const pathAttributes = {
            strokeDasharray: dasharray,
            strokeLinecap: linecap,
            transform,
            fill: "none"
          };

          const ghostPathAttributes = {
            stroke: "transparent",
            "data-multiline": BOOL_STRING_VALUES.TRUE,
            "data-is-right-chart": String(isRightChart),
            strokeDasharray: 0,
            strokeLinecap: "square" as const,
            strokeWidth: 8,
            id: lineId,
            pointerEvents: "auto"
          };

          const primaryLineType = isRightChart
            ? LINE_TYPES.PRIMARY_RIGHT
            : LINE_TYPES.PRIMARY_LEFT;
          const secondaryLineType = isRightChart
            ? LINE_TYPES.SECONDARY_RIGHT
            : LINE_TYPES.SECONDARY_LEFT;

          // exception for location report
          const key = chartName
            ? `line-group-${chartName}-${label}`
            : `line-group-${id}`;

          return (
            <g key={key}>
              {defaultPath && (
                <g>
                  <path
                    {...pathAttributes}
                    key={`regular-data-line-${label}`}
                    d={defaultPath}
                    opacity={opacity}
                    stroke={transformedColor}
                    className={s.linePath}
                    strokeWidth={getStrokeWidth(lineId, primaryLineType)}
                    pointerEvents="none"
                  />
                  <path
                    {...pathAttributes}
                    key={`regular-data-line-${label}-ghost`}
                    d={defaultPath}
                    {...ghostPathAttributes}
                  />
                </g>
              )}

              {secondaryPath && (
                <g>
                  <path
                    {...pathAttributes}
                    key={`secondary-data-line-${label}`}
                    strokeDasharray={
                      isRightChart ? SECONDARY_RIGHT[0] : SECONDARY_LEFT[0]
                    }
                    d={secondaryPath}
                    opacity={opacity}
                    stroke={transformedColor}
                    className={s.linePath}
                    strokeWidth={getStrokeWidth(lineId, secondaryLineType)}
                    pointerEvents="none"
                  />
                  <path
                    {...pathAttributes}
                    key={`secondary-data-line-${label}-ghost`}
                    d={secondaryPath}
                    {...ghostPathAttributes}
                    data-secondary
                  />
                </g>
              )}

              {!isGroupedBarChart && (
                <>
                  {lastYearPath && (
                    <g>
                      <path
                        {...pathAttributes}
                        key={`back-data-line-${label}`}
                        d={lastYearPath}
                        opacity={Number(opacity) * 0.4}
                        stroke={color}
                        className={s.linePath}
                        strokeWidth={getStrokeWidth(lineId, primaryLineType)}
                        pointerEvents="none"
                      />
                      <path
                        {...pathAttributes}
                        key={`back-data-line-${label}-ghost`}
                        d={lastYearPath}
                        {...ghostPathAttributes}
                        data-historical
                      />
                    </g>
                  )}

                  {secondaryLastYearPath && (
                    <g>
                      <path
                        {...pathAttributes}
                        key={`secondary-back-data-line-${label}`}
                        strokeDasharray={
                          isRightChart ? SECONDARY_RIGHT[0] : SECONDARY_LEFT[0]
                        }
                        d={secondaryLastYearPath}
                        opacity={Number(opacity) * 0.4}
                        stroke={color}
                        className={s.linePath}
                        strokeWidth={getStrokeWidth(lineId, secondaryLineType)}
                        pointerEvents="none"
                      />
                      <path
                        {...pathAttributes}
                        key={`secondary-back-data-line-${label}-ghost`}
                        d={secondaryLastYearPath}
                        {...ghostPathAttributes}
                        data-secondary
                        data-historical
                      />
                    </g>
                  )}
                </>
              )}
            </g>
          );
        }
      )}
    </g>
  );
};
