export const shouldMergeTimeline = ({
  isBothDatasets,
  isGroupedBarChart,
  domainLength
}: {
  isBothDatasets: boolean;
  isGroupedBarChart: boolean;
  domainLength: number;
}) => {
  return isBothDatasets && isGroupedBarChart && domainLength === 1;
};
