import { useSelector } from "react-redux";

import { EMPTY_CHART } from "api/consts";
import { ChartDataOptions } from "api/sells/mappers/useSellsChartData";
import { useGetSharesByPeriod, useIsSharesLoading } from "api/shares/mappers";
import {
  createHistoricalTimeline,
  createMergedTimeline,
  createTimeline,
  getDataTypeKey,
  shouldMergeTimeline,
  sortBarChartData
} from "api/utils";
import { useChartLabels } from "api/utils/useChartLabels";
import { omit } from "ramda";

import { ChartData } from "components/D3/types";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useBarChartSorting } from "hooks/useBarChartSorting";
import { useLegendContentNames } from "pages/Reports/partials/Chart/Legend/hooks/useLegendContentNames";
import { createLegendId } from "pages/Reports/partials/Chart/Legend/utils/createLegendId";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { useDataTypeSuffix } from "pages/Reports/partials/Chart/StandardChart/components/WithZappkaSelect";
import {
  hiddenLinesByReportSelector,
  pinnedTimepointSelector,
  updateHoveredTimepoint,
  updatePinnedTimepoint
} from "pages/Reports/redux/reducers/chartReducer";
import { useGetZappkaSharesSuffix } from "pages/Reports/sections/NewChartDropdown/ZappkaSharesCheckbox/hooks";
import {
  checkedDataTypes,
  findDateDomain,
  findLastYearDomainLength
} from "pages/Reports/sections/utils/chartData";
import { addColor } from "pages/Reports/sections/utils/color";
import { getChartLabel } from "pages/Reports/sections/utils/getChartLabel";
import {
  chartRange,
  valueByDataType,
  valueByDataTypeBoolean
} from "pages/Reports/utils";
import {
  chartPeriodSelector,
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import {
  mapLastYearTimelineByType,
  mapTimelineByType
} from "store/utils/chartUtils";
import { CHART_SORT_ORDER_BY_TYPE, PERIOD_TYPE } from "utils";
import { isSameScaleMetrics } from "utils/isSameScaleMetrics";

export const useSharesChartData = ({
  isForHistoricalChart = false
}: ChartDataOptions): ChartData[] => {
  const { data: shares } = useGetSharesByPeriod();
  const period = useSelector(chartPeriodSelector);
  const firstChartType = useSelector(firstChartDataTypeSelector);
  const secondChartType = useSelector(secondChartDataTypeSelector);
  const hiddenLines = useSelector(hiddenLinesByReportSelector);
  const pinnedTimepoint = useSelector(pinnedTimepointSelector);
  const dataset = useCurrentDataset();
  const labels = useChartLabels();
  const isChartLoading = useIsSharesLoading();
  const { isGroupedBarChart, isIncrementalComboChart } = useChartTypeBoolean();
  const contentNames = useLegendContentNames();
  const [suffix1, suffix2] = useDataTypeSuffix();
  const getZappkaSharesSuffix = useGetZappkaSharesSuffix();
  const barChartSorting = useBarChartSorting();

  if (!shares) {
    return [EMPTY_CHART];
  }

  const timeline = shares.currentPeriod[0]?.timeline || [];
  let domain = findDateDomain(
    timeline,
    isForHistoricalChart || dataset.historical
  );
  const mergeTimeline = shouldMergeTimeline({
    isBothDatasets: dataset.both,
    isGroupedBarChart,
    domainLength: domain.length
  });

  if (mergeTimeline && isForHistoricalChart) {
    return [EMPTY_CHART];
  }

  const dataTypes = checkedDataTypes([firstChartType, secondChartType]);
  const isTotal = period.toUpperCase() === PERIOD_TYPE.TOTAL;
  const lastYearDomainLength = isTotal ? 1 : findLastYearDomainLength(timeline);
  const showBarChart = domain.length === 1;
  const barChartSortingTimepoint = barChartSorting.getSortingTimepoint({
    dataTypes,
    showBarChart,
    isGroupedBarChart,
    pinnedTimepoint
  });

  if (mergeTimeline) {
    domain = [...findDateDomain(timeline, true), ...domain];
  }

  // Sorting is important. By name and type and then by existing metrics, so existing are first in array and color is not wasted on non existing metrics
  const sorted = shares.currentPeriod
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort(
      (a, b) =>
        CHART_SORT_ORDER_BY_TYPE.indexOf(a.type) -
        CHART_SORT_ORDER_BY_TYPE.indexOf(b.type)
    )
    .sort(
      (a, b) =>
        Number(valueByDataType(firstChartType, b.existingMetrics)) -
        Number(valueByDataType(firstChartType, a.existingMetrics))
    );

  const dataTypeKeys = dataTypes.map((dataType, index) => {
    const sharesSuffix = getZappkaSharesSuffix(firstChartType ? index : 1);
    const primaryKey = getDataTypeKey({
      dataType,
      suffix: suffix1,
      sharesSuffix
    });
    const secondaryKey = getDataTypeKey({
      dataType,
      suffix: suffix2,
      sharesSuffix,
      isSecondary: true
    });
    return { primaryKey, secondaryKey };
  });

  const chartData = dataTypes.map((dataType, index) => {
    const dataWithColor = addColor(sorted, dataType);
    const { primaryKey, secondaryKey } = dataTypeKeys[index];
    const hasSecondaryTimeline = {
      base: Boolean(secondaryKey),
      historical: Boolean(secondaryKey) && dataset.both
    };

    if (barChartSortingTimepoint !== null) {
      dataWithColor.sort(
        sortBarChartData(
          barChartSorting.dataKey,
          barChartSorting.isDesc,
          barChartSortingTimepoint
        )
      );
    }

    const allLines = dataWithColor.map(shares => {
      const chartLabel = getChartLabel({
        vendorId: shares.vendorId,
        resultId: shares.resultId,
        type: shares.type
      });

      const id = createLegendId(chartLabel, contentNames, shares.type);

      return {
        ...omit(["timeline"], shares),
        chartLabel,
        id,
        timeline: mergeTimeline
          ? createMergedTimeline(primaryKey, shares.timeline)
          : dataset.historical
          ? createHistoricalTimeline(primaryKey, shares.timeline, true)
          : createTimeline(primaryKey, shares.timeline),
        ...(hasSecondaryTimeline.base && {
          secondaryTimeline: mergeTimeline
            ? createMergedTimeline(secondaryKey, shares.timeline)
            : dataset.historical
            ? createHistoricalTimeline(secondaryKey, shares.timeline, true)
            : createTimeline(secondaryKey, shares.timeline)
        }),
        ...(dataset.both && {
          lastYearTimeline: createHistoricalTimeline(
            primaryKey,
            shares.timeline,
            isForHistoricalChart
          )
        }),
        ...(hasSecondaryTimeline.historical && {
          secondaryLastYearTimeline: createHistoricalTimeline(
            secondaryKey,
            shares.timeline,
            isForHistoricalChart
          )
        })
      };
    });

    const visibleLines = allLines
      .filter(timeline => !hiddenLines.includes(timeline.id))
      .filter(timeline =>
        valueByDataTypeBoolean(
          primaryKey,
          timeline.existingMetrics,
          dataset,
          secondaryKey
        )
      );

    return {
      dataType,
      visibleLines,
      allLines,
      ...chartRange(visibleLines, {
        isRightChartData: index === 1,
        isGroupedBarChart,
        isIncrementalComboChart
      })
    };
  });

  const isSameScale = isSameScaleMetrics(
    dataTypeKeys.map(dataType => dataType.primaryKey)
  );
  const overallMaxValue = isSameScale
    ? Math.max(...chartData.map(data => data.maxValue))
    : 0;

  const filtered = chartData.map(chart => ({
    dataType: chart.dataType,
    minValue: chart.minValue,
    maxValue: overallMaxValue || chart.maxValue,
    chart: chart.visibleLines.map(chart => ({
      id: chart.id,
      label: chart.chartLabel,
      color: chart.color,
      type: chart.type,
      timeline: dataset.historical
        ? mapLastYearTimelineByType(
            chart.type,
            chart.timeline,
            lastYearDomainLength
          )
        : mapTimelineByType(chart.type, chart.timeline),
      ...(chart.secondaryTimeline && {
        secondaryTimeline: dataset.historical
          ? mapLastYearTimelineByType(
              chart.type,
              chart.secondaryTimeline,
              lastYearDomainLength
            )
          : mapTimelineByType(chart.type, chart.secondaryTimeline)
      }),
      ...(chart.lastYearTimeline && {
        lastYearTimeline: mapLastYearTimelineByType(
          chart.type,
          chart.lastYearTimeline,
          lastYearDomainLength
        )
      }),
      ...(chart.secondaryLastYearTimeline && {
        secondaryLastYearTimeline: mapLastYearTimelineByType(
          chart.type,
          chart.secondaryLastYearTimeline,
          lastYearDomainLength
        )
      })
    }))
  }));

  return [
    {
      leftChart: filtered[0],
      rightChart: filtered[1],
      domain,
      period: period,
      labels,
      isFetching: isChartLoading,
      showBarChart,
      onTimelineHover: updateHoveredTimepoint,
      onTimelineClick: updatePinnedTimepoint,
      isHistoricalGroupedChart: isForHistoricalChart,
      isTimelineMerged: mergeTimeline
    }
  ];
};
