import { useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { QueryKey } from "api/consts";
import { logisticsServices } from "api/logistics/services/logistics.services";
import qs from "query-string";
import { pick } from "ramda";

import { useFiltersQP } from "hooks";
import { updateLogisticsHiddenLines } from "pages/Reports/redux/reducers/chartReducer";
import { transformFetchQueryForLogisticsTable } from "pages/Reports/sections/Logistics/utils/transformFetchQueryForLogisticsTable";
import { REPORTS_PATHS } from "pages/Reports/utils";
import { normalizeLogisticsResponse } from "pages/Reports/utils/normalize/normalizeLogisticsResponse";
import {
  PARSE_OPTIONS,
  STRINGIFY_OPTIONS
} from "pages/Reports/utils/validateParams/utils/adjustObjectProperty";
import {
  isLogisticsTableVisibleSelector,
  searchStringSelector
} from "store/selectors/routerSelectors";
import { isProductWithdrawn, isThisPage } from "utils";
import { QP } from "utils/defaultQueryParams";
import { translateFeParamsToBeParams } from "utils/translateFEParamsToBEParams";

const validateQuery = (query: string, isTable: boolean) => {
  const params = qs.parse(query, PARSE_OPTIONS);
  const filteredParams = pick(
    [
      QP.VENDOR_ID,
      QP.CATEGORY1,
      QP.CATEGORY2,
      QP.CATEGORY3,
      QP.BRANDS,
      QP.SUB_BRANDS,
      QP.PRODUCT,
      QP.DATE_FROM,
      QP.DATE_TO,
      ...(isTable ? [QP.STORE_TYPES] : [])
    ],
    params
  );
  return qs.stringify(filteredParams, STRINGIFY_OPTIONS);
};

export const useGetLogistics = () => {
  const search = useSelector(searchStringSelector);
  const isLogisticsTableVisible = useSelector(isLogisticsTableVisibleSelector);
  const beQuery = translateFeParamsToBeParams(
    validateQuery(search, isLogisticsTableVisible)
  );
  const tableDate = String(qs.parse(beQuery).date_to) || "";
  const { cat1QP, cat2QP, cat3QP } = useFiltersQP();

  const fetchQuery = isLogisticsTableVisible
    ? transformFetchQueryForLogisticsTable(beQuery, tableDate)
    : beQuery;

  const { pathname } = useLocation();
  const isCategorySelected = [cat1QP, cat2QP, cat3QP].some(Boolean);
  const isLogisticPage = useMemo(
    () => isThisPage(pathname, REPORTS_PATHS.LOGISTICS),
    [pathname]
  );

  const dispatch = useDispatch();

  const queryResponse = useQuery(
    [QueryKey.LOGISTICS_DATA, fetchQuery],
    () => logisticsServices.fetchLogistics(fetchQuery),
    {
      enabled: isCategorySelected && isLogisticPage,
      onSuccess: ({ data }) => {
        //hide lines with unactive products by defualt
        const inactiveProducts = data.filter(item =>
          isProductWithdrawn(item.name)
        );

        dispatch(
          updateLogisticsHiddenLines(
            inactiveProducts.map(item => item.name) || [""]
          )
        );
      }
    }
  );

  const logisticsDataset = useMemo(
    () => ({
      query: fetchQuery,
      timestamp: Date.now(),
      isActive: true,
      currentPeriod: queryResponse.data
        ? normalizeLogisticsResponse(queryResponse.data.data)
        : []
    }),
    [fetchQuery, queryResponse.data]
  );

  return { ...queryResponse, data: logisticsDataset };
};
