import { MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

import { equals, uniq } from "ramda";
import { useDebouncedCallback } from "use-debounce/lib";

import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { TourHooks } from "components/organisms/Tour/hooks/useTours";
import { KeyboardHooks } from "hooks/useKeyboardKeys";
import {
  ChartOnClickAction,
  ChartOnHoverAction,
  hoveredLineSelector,
  hoveredTimepointPositionSelector,
  updateHoveredLine,
  updateHoveredLineType,
  updateHoveredTimepoint,
  updateHoveredTimepointPosition
} from "pages/Reports/redux/reducers/chartReducer";
import { updateCurrentChart } from "pages/Reports/sections/Segments/redux/reducers/segmentsChartReducer";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { updateQueryParams } from "store/actions/routerActions";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { BOOL_STRING_VALUES, isMac, isThisPage } from "utils";
import { QP } from "utils/defaultQueryParams";
import { Nullable } from "utils/types";

import { LINE_TYPES } from "../../LinesLegend/LinesLegend";
import { useChartValueTiles } from "./useChartValueTiles";

const { SEGMENTS_PATH } = REPORTS_FULL_PATHS;
const TILE_TRIGGER_KEYDOWN = isMac() ? "Meta" : "Control";

interface Props {
  onTimelineHover?: ChartOnHoverAction;
  domain?: string[];
  gridWidth?: number;
  onTimelineClick?: ChartOnClickAction;
}

const filterLines = (elements: SVGElement[]) =>
  elements.filter(
    svg => (svg as SVGElement)?.dataset?.multiline === BOOL_STRING_VALUES.TRUE
  );

const calculateDomainPoint = (
  relativePos: Nullable<number>,
  domain: string[],
  gridWidth: number
) => {
  if (!relativePos || !domain.length) return null;
  const pxPerDomainPoint = gridWidth / domain.length;
  const domainPoint = Math.floor(relativePos / pxPerDomainPoint);
  return Math.min(domainPoint, domain.length - 1);
};

export const useChartHover = ({
  domain = [],
  gridWidth = 0,
  onTimelineHover,
  onTimelineClick
}: Props) => {
  const dispatch = useDispatch();
  const { isLineChart } = useChartTypeBoolean();
  const [{ keyDown }] = KeyboardHooks.useKeyboardKeys();
  const isTileTriggerKeyDown = keyDown.includes(TILE_TRIGGER_KEYDOWN);
  const isHistoricalTileTriggerKeyDown =
    keyDown.includes("Shift") &&
    keyDown.includes(TILE_TRIGGER_KEYDOWN) &&
    isLineChart;
  const hoveredLine = useSelector(hoveredLineSelector);
  const hoveredTimepointPosition = useSelector(
    hoveredTimepointPositionSelector
  );
  const [, tileActions] = useChartValueTiles();
  const pathname = useSelector(pathnameSelector);
  const isSegmentsPage = isThisPage(pathname, SEGMENTS_PATH);
  const [isSidebarTourHidden] = TourHooks.useIsSidebarTourHidden();
  const isOneTimepoint = domain.length === 1;

  const hoverLines = (elements: SVGElement[]) => {
    const paths = filterLines(elements);
    const linesHovered = paths.map(path => path.id);

    if (equals(hoveredLine, linesHovered)) return;

    const lineTypes = paths.map(path => {
      const secondary = path.dataset.secondary === BOOL_STRING_VALUES.TRUE;
      const right = path.dataset.isRightChart === BOOL_STRING_VALUES.TRUE;
      if (secondary && right) return LINE_TYPES.SECONDARY_RIGHT;
      if (secondary && !right) return LINE_TYPES.SECONDARY_LEFT;
      if (!secondary && right) return LINE_TYPES.PRIMARY_RIGHT;
      return LINE_TYPES.PRIMARY_LEFT;
    });

    dispatch(updateHoveredLine(linesHovered));
    dispatch(updateHoveredLineType(lineTypes));
  };

  const hoverGrid = (elements: Element[], clientX: number) => {
    const gridElement = elements.find(
      svg => svg.id === "chart-grid-rect"
    ) as SVGAElement;

    if (isSegmentsPage) {
      const segmentChartId = gridElement?.dataset.segmentId;
      if (!segmentChartId) return;

      dispatch(updateCurrentChart(segmentChartId));
    }

    if (!gridElement) {
      dispatch(updateHoveredTimepointPosition(null));
      return;
    }

    const bounds: Nullable<DOMRect> =
      gridElement?.getBoundingClientRect() || null;

    if (bounds) {
      const { x } = bounds;
      const relativePos = clientX - x;
      dispatch(updateHoveredTimepointPosition(relativePos));

      if (typeof onTimelineHover === "undefined") return;
      const domainPoint = calculateDomainPoint(relativePos, domain, gridWidth);
      if (domainPoint === null) return;
      dispatch(onTimelineHover(domainPoint));
    }
  };

  // onMouseMove event
  const onMouseMove = (e: MouseEvent<SVGElement>) => {
    const elements = document.elementsFromPoint(
      e.clientX,
      e.clientY
    ) as SVGElement[];

    hoverLines(elements);
    hoverGrid(elements, e.clientX);
  };
  const [onMouseMoveDebounced] = useDebouncedCallback(onMouseMove, 5);

  // onMouseLeave event
  const onMouseLeave = (e: MouseEvent<SVGElement>) => {
    const elements = document.elementsFromPoint(e.clientX, e.clientY);
    const lineHovered = elements.find(
      svg => (svg as SVGElement)?.dataset?.multiline === BOOL_STRING_VALUES.TRUE
    );

    if (!isSidebarTourHidden || lineHovered) return;

    dispatch(updateHoveredTimepointPosition(null));
    dispatch(updateHoveredTimepoint(null));
    dispatch(updateHoveredLine([]));
    dispatch(updateHoveredLineType([]));
  };

  const [onMouseLeaveDebounced] = useDebouncedCallback(onMouseLeave, 5);

  // onClick event
  const onClick = (e: MouseEvent<SVGElement>) => {
    const elements = document.elementsFromPoint(
      e.clientX,
      e.clientY
    ) as SVGElement[];
    const gridElement = elements.find(
      svg => svg.id === "chart-grid-rect"
    ) as SVGElement;

    if (isSegmentsPage) {
      const segmentChartId = gridElement?.dataset.segmentId || "";
      if (!segmentChartId) {
        dispatch(updateCurrentChart(segmentChartId));
      }
    }

    const domainPoint = isOneTimepoint
      ? 0
      : calculateDomainPoint(hoveredTimepointPosition, domain, gridWidth);

    if (domainPoint === null) return;

    if (!isTileTriggerKeyDown) {
      if (typeof onTimelineClick === "undefined") return;
      dispatch(onTimelineClick(domainPoint));
    }

    if (isTileTriggerKeyDown) {
      const linesClicked = filterLines(elements);

      const tiles = linesClicked.flatMap(line => {
        const baseTile = {
          lineName: line.id,
          timepoint: domainPoint || 0,
          isRightChart: line.dataset.isRightChart === BOOL_STRING_VALUES.TRUE,
          isHistorical: line.dataset.historical === BOOL_STRING_VALUES.TRUE,
          isSecondary: line.dataset.secondary === BOOL_STRING_VALUES.TRUE // for secondary timeline
        };

        if (!isHistoricalTileTriggerKeyDown) return baseTile;

        return [
          baseTile,
          { ...baseTile, isHistorical: !baseTile.isHistorical }
        ];
      });
      const uniqTiles = uniq(tiles);

      tileActions.handleTiles(uniqTiles);

      dispatch(
        updateQueryParams({
          [QP.HIDE_TILES]: BOOL_STRING_VALUES.FALSE
        })
      );
    }
  };

  return {
    onMouseMove: onMouseMoveDebounced,
    onMouseLeave: onMouseLeaveDebounced,
    onClick
  };
};
