import React from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";

import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import {
  checkCompetitorProductsPristineSelectionsAfterFetching,
  fetchCompetitorsProducts
} from "pages/Reports/redux/reducers/filters/competition/competitionProductsFilterReducer";
import { useFetchingCallback } from "pages/Reports/redux/reducers/filters/hooks/useFetchingCallback";
import { useFiltersFetchingParams } from "pages/Reports/redux/reducers/filters/hooks/useFiltersFetchingParams";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { competitionProductsQueryParamSelector } from "store/selectors/routerSelectors";

export type CompetitorProductRequestParams = {
  competing_materials: boolean;
  categories: string;
  category_level: string;
  companies: string | string[];
  vendor_id: string;
  brands: string;
  sub_brands: string;
};

const useCompetitorFilterParams = (): CompetitorProductRequestParams => {
  const params = useFiltersFetchingParams([
    "category",
    "categoryLevel",
    "vendor",
    "competitorCompany",
    "competitorBrand",
    "competitorSubBrand",
    "attributes"
  ]);

  const base: CompetitorProductRequestParams = {
    competing_materials: true,
    categories: params.category,
    category_level: params.categoryLevel,
    vendor_id: params.vendor,
    companies: params.competitorCompany,
    brands: params.competitorBrand,
    sub_brands: params.competitorSubBrand
  };

  if (params.attributes) {
    Object.assign(base, { attributes: params.attributes });
  }

  return base;
};

const useFetchingFlag = (
  params: {
    categories: string;
    category_level: string;
    vendor_id: string;
  },
  isDisabled: boolean
) => {
  const isLoading = useSelector(asyncFiltersSelector).fetching
    .competitorProducts;
  const lastQuery = useSelector(asyncFiltersSelector).query.competitorProducts;
  const isPowerUser = useSelector(isPowerUserSelector);
  const query = qs.stringify(params);

  if (isLoading || isDisabled) {
    return;
  }

  if (query === lastQuery) {
    return;
  }

  if (!params.categories || !params.category_level) {
    return;
  }

  if (isPowerUser && !params.vendor_id) {
    return;
  }
  return true;
};

const useQueryParamsSelections = () => {
  const dispatch = useDispatch();
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const productsQP = useSelector(competitionProductsQueryParamSelector);

  React.useEffect(() => {
    if (isPristine) {
      dispatch(checkCompetitorProductsPristineSelectionsAfterFetching());
    }
  }, [dispatch, isPristine, productsQP]);
};

export const useCompetitorProductFetching = (isDisabled: boolean) => {
  const filter = "competitorProducts";
  const dispatch = useDispatch();

  const params = useCompetitorFilterParams();
  const isFetchingPossible = useFetchingFlag(params, isDisabled);
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const productsQP = useSelector(competitionProductsQueryParamSelector);
  const callback = useFetchingCallback(filter);

  React.useEffect(() => {
    if (isPristine) {
      dispatch(checkCompetitorProductsPristineSelectionsAfterFetching());
    }
  }, [dispatch, isPristine, productsQP]);

  React.useEffect(() => {
    if (!isFetchingPossible) {
      return;
    }

    const fetch = async () => {
      const filter = "competitorProducts";
      try {
        dispatch(updateQuery({ filter, query: qs.stringify(params) }));

        dispatch(setFlag({ filter, isFetching: true }));
        const products = await fetchCompetitorsProducts(params);
        callback(products);
        dispatch(setError({ filter, status: "" }));

        return products;
      } catch (e) {
        dispatch(setError({ filter, status: "Błąd pobierania produktów" }));
        return [];
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };

    fetch();
  }, [dispatch, isFetchingPossible, params, callback]);
};

export const useCompetitionProductBehaviour = (isDisabled: boolean) => {
  useCompetitorProductFetching(isDisabled);
  useQueryParamsSelections();
};
