import { combineReducers } from "redux";
import { createSelector } from "reselect";

import {
  competitionCompaniesFilterReducer,
  selectedCompetitionCompaniesIdsSelector
} from "./competitionCompaniesFilterReducer";
import {
  competitionProductsFilterReducer,
  selectedCompetitionProductsIdsSelector
} from "./competitionProductsFilterReducer";

// SELECTORS

export const selectedCompetitionFiltersSelector = (delay: boolean) =>
  createSelector(
    selectedCompetitionCompaniesIdsSelector,
    selectedCompetitionProductsIdsSelector,
    (selectedCompetitionCompanies, selectedCompetitionProducts) => {
      return {
        competing_companies: selectedCompetitionCompanies,
        competing_materials: selectedCompetitionProducts,
        delay
      };
    }
  );

// REDUCER
export const competitionReportsFiltersReducer = combineReducers({
  competitionCompanies: competitionCompaniesFilterReducer,
  competitionProducts: competitionProductsFilterReducer
});
