import { Action, createHook, createStore } from "react-sweet-state";

import { DropdownItem } from "components/molecules/types";
import {
  getMatched,
  getSelectedCountLabel,
  remainingElementsToSelect,
  selectElement
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import { sortItemsBySelectionOrder } from "pages/Reports/redux/utils";
import { Nullable } from "utils/types";

export type PromotionItem = DropdownItem<string>;

export type PromotionApi = { id: string; name: string };

type State = { all: PromotionItem[]; selected: PromotionItem[] };

type Actions = typeof actions;

const actions = {
  updateAllPromotions: (all: PromotionItem[]): Action<State> => ({
    setState
  }) => {
    setState({ all });
  },
  updateSelectedPromotions: (selected: PromotionItem[]): Action<State> => ({
    setState
  }) => {
    setState({ selected });
  },
  selectPromotion: (
    selectedPromotion: Nullable<PromotionItem>
  ): Action<State> => ({ setState, getState }) => {
    setState({
      selected: selectElement(selectedPromotion, getState().selected)
    });
  },
  selectAllPromotions: (inputValue: string): Action<State> => ({
    setState,
    getState
  }) => {
    const state = getState();
    const remainingToSelect = remainingElementsToSelect(
      state.selected,
      state.all,
      inputValue
    );
    setState({
      selected: [...state.selected, ...remainingToSelect]
    });
  },
  sortPromotions: (): Action<State> => ({ setState, getState }) => {
    const state = getState();
    const selectedPromotionsIds = state.selected.map(item => item.value);
    const sortedAllPromotions = sortItemsBySelectionOrder(
      selectedPromotionsIds,
      state.all
    );
    setState({ all: sortedAllPromotions });
  },
  clearPromotions: (): Action<State> => ({ setState }) => {
    setState({ selected: [] });
  },
  checkSelectionsAfterFetching: (ids: string[]): Action<State> => ({
    getState,
    setState
  }) => {
    setState({
      selected: getMatched(getState().selected, ids)
    });
  },
  checkPristineSelectionsAfterFetching: (
    promotions: PromotionItem[],
    promotionsQueryParam: string | string[]
  ): Action<State> => ({ setState }) => {
    const updatedSelectedPromotions = promotions.filter(({ value }) =>
      promotionsQueryParam.includes(value)
    );
    setState({ selected: updatedSelectedPromotions });
  }
};

export const PromotionsStore = createStore<State, Actions>({
  name: "promotions",
  initialState: { all: [], selected: [] },
  actions
});

export const getSelectedPromotionsIds = (state: State) => {
  return state.selected.map(item => item.value);
};

export const getPromotionsLabel = (state: State) => {
  return state.selected.length === 1
    ? state.selected[0].label
    : getSelectedCountLabel(state.selected.length, state.all.length);
};

export const PromotionsHooks = {
  usePromotions: createHook(PromotionsStore),
  usePromotionsLabel: createHook(PromotionsStore, {
    selector: getPromotionsLabel
  }),
  useSelectedPromotionsIds: createHook(PromotionsStore, {
    selector: getSelectedPromotionsIds
  })
};
