import { ProductItemApi } from "pages/Reports/redux/reducers/filters/competition/competitionProductsFilterReducer";

export const mapCompetitionProductsResponse = (data: ProductItemApi[]) => {
  if (!data.length) {
    return [];
  }
  return data.map(({ material, display }) => ({
    label: display,
    value: material
  }));
};
