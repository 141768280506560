import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";

import { APP_PATHS, PRODUCTION_URL } from "./constants";

const isProduction = window.location.hostname === PRODUCTION_URL;

export const RESTRICTIONS = {
  // PMD-4838
  OWN_VENDOR: {
    ID: isProduction ? "0000069810" : "0000000096",
    PATHS: [
      APP_PATHS.REPORTS,
      REPORTS_FULL_PATHS.SELL_PATH,
      REPORTS_FULL_PATHS.LOGISTICS_PATH
    ],
    FILTERS: {
      SALES: [
        FILTER_NAMES.CATEGORY_1,
        FILTER_NAMES.CATEGORY_2,
        FILTER_NAMES.CATEGORY_3,
        FILTER_NAMES.BRAND,
        FILTER_NAMES.SUB_BRAND,
        FILTER_NAMES.PRODUCT,
        FILTER_NAMES.DATE,
        FILTER_NAMES.TIME_AGGREGATION,
        FILTER_NAMES.AGGREGATION
      ] as string[]
    }
  }
};
