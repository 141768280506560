import IconDynamicPeriod from "assets/images/icon-dynamic-period.png";
import IconFavourite from "assets/images/icon-favourite.png";
import IconNewUserKnowledgebase from "assets/images/icon-new-user.png";
import IconNewsletter_2020_11 from "assets/images/icon-newsletter-2020-11.png";
import IconNewsletter_2020_12 from "assets/images/icon-newsletter-2020-12.png";
import IconNewsletter_2021_01 from "assets/images/icon-newsletter-2021-01.png";
import IconNewsletter_2021_02 from "assets/images/icon-newsletter-2021-02.png";
import IconNewsletter_2021_03 from "assets/images/icon-newsletter-2021-03.png";
import IconNewsletter_2021_04 from "assets/images/icon-newsletter-2021-04.png";
import IconNewsletter_2021_05 from "assets/images/icon-newsletter-2021-05.png";
import IconNewsletter_2021_06 from "assets/images/icon-newsletter-2021-06.png";
import IconNewsletter_2021_08 from "assets/images/icon-newsletter-2021-08.png";
import IconNewsletter_2021_09 from "assets/images/icon-newsletter-2021-09.png";
import IconNewsletter_2021_10 from "assets/images/icon-newsletter-2021-10.png";
import IconNewsletter_2021_11 from "assets/images/icon-newsletter-2021-11.png";
import IconNewsletter_2021_12 from "assets/images/icon-newsletter-2021-12.png";
import IconNewsletter_2022_Q1 from "assets/images/icon-newsletter-2022-Q1.png";
import IconSegments from "assets/images/icon-segments.png";

interface Data {
  header: string;
  description: string;
  source: string;
  iconSource: string;
  ga: {
    [key: string]: string;
  };
}

export const DYNAMIC_PERIOD = {
  HEADER: "Dynamiczny zakres dat",
  VIDEO_URL:
    "https://zabkastorageprd.blob.core.windows.net/news/Dynamiczny%20zakres%20dat%20-%20filmik.mp4",
  VIDEO_GA: {
    "data-ga-baza-wiedzy-prezentacja": "dynamiczny zakres dat - video"
  }
};

export const PRESENTATIONS: Data[] = [
  {
    header: DYNAMIC_PERIOD.HEADER,
    description:
      "Sprawdź jak zapisywać linki z ulubionymi raportami wykorzystując możliwość automatycznej aktualizacji dat",
    source:
      "https://zabkastorageprd.blob.core.windows.net/news/Dynamiczny_zakres_dat.pdf",
    iconSource: IconDynamicPeriod,
    ga: {
      "data-ga-baza-wiedzy-prezentacja": "dynamiczny zakres dat - pdf"
    }
  },
  {
    header: "Dodanie nowego użytkownika",
    description:
      "Sprawdź w jaki sposób możesz dodać nowego użytkownika do portalu ACIT",
    source:
      "https://zabkastorageprd.blob.core.windows.net/docs/Dodanie%20nowego%20uz%CC%87ytkownika%20ACIT.pdf",
    iconSource: IconNewUserKnowledgebase,
    ga: {
      "data-ga-baza-wiedzy-prezentacja": "dodanie nowego użytkownika"
    }
  },
  {
    header: "Segmentacja sklepów",
    description:
      "Prezentacja przedstawiająca dokładny opis poszczególnych segmentów sklepowych w Żabce",
    source:
      "https://zabkastorageprd.blob.core.windows.net/docs/2022_02%20Segmentacja%20Z%CC%87abka.pdf",
    iconSource: IconSegments,
    ga: {
      "data-ga-baza-wiedzy-prezentacja": "segmenty"
    }
  },
  {
    header: "Tworzenie ulubionych raportów",
    description:
      "Zobacz jak możesz stworzyć ulubione raporty w ACIT i udostępnić je współpracownikom",
    source:
      "https://zabkastorageprd.blob.core.windows.net/docs/acit-ulubione.pdf",
    iconSource: IconFavourite,
    ga: {
      "data-ga-baza-wiedzy-prezentacja": "ulubione"
    }
  }
];

export const NEWSLETTERS: Data[] = [
  {
    header: "Q2 2022",
    description: "Powrót do przeszłości",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/newsletter-22-09.png",
    iconSource: IconNewsletter_2020_12,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2022-Q2"
    }
  },
  {
    header: "Q1 2022",
    description: "Wiosenne nowości w ACIT",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/newsletter-q1-2022-full.png",
    iconSource: IconNewsletter_2022_Q1,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2022-Q1"
    }
  },
  {
    header: "Grudzień 2021",
    description: "ACIT'owo Mikołajowo!",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/newsletter-2021-12.jpeg",
    iconSource: IconNewsletter_2021_12,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2021-12"
    }
  },
  {
    header: "Listopad 2021",
    description: "Nieprzebrane marki i submarki",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/newsletter-2021-11.png",
    iconSource: IconNewsletter_2021_11,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2021-11"
    }
  },
  {
    header: "Październik 2021",
    description: "Lata, miesiące, tygodnie, dni, GODZINY!",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/newsletter-2021-10.png",
    iconSource: IconNewsletter_2021_10,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2021-10"
    }
  },
  {
    header: "Wrzesień 2021",
    description: "Prezenty w ACIT",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/newsletter-2021-09.png",
    iconSource: IconNewsletter_2021_09,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2021-09"
    }
  },
  {
    header: "Lipiec - sierpień 2021",
    description: "ACIT - mały wielki portal!",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/2021-08-newsletter.png",
    iconSource: IconNewsletter_2021_08,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2021-08"
    }
  },
  {
    header: "Czerwiec 2021",
    description: "Jedyną stałą rzeczą w ACIT jest zmiana",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/2021-06-newsletter.png",
    iconSource: IconNewsletter_2021_06,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2021-06"
    }
  },
  {
    header: "Maj 2021",
    description:
      "Logistyka nie jest wszystkim, ale wszystko bez logistyki jest niczym",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/newsletter-2021-05.png",
    iconSource: IconNewsletter_2021_05,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2021-05"
    }
  },
  {
    header: "Kwiecień 2021",
    description: "Myśl globalnie, sprzedawaj lokalnie",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/newsletter-2021-04.png",
    iconSource: IconNewsletter_2021_04,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2021-04"
    }
  },
  {
    header: "Marzec 2021",
    description: "Najlepszy sposób na przewidywanie przyszłości",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/newsletter-2021-03.png",
    iconSource: IconNewsletter_2021_03,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2021-03"
    }
  },
  {
    header: "Luty 2021",
    description: "7000 powodów by korzystać z ACIT",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/newsletter-2021-02.png",
    iconSource: IconNewsletter_2021_02,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2021-02"
    }
  },
  {
    header: "Styczeń 2021",
    description: "Sales make data, data make sales",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/newsletter-2021-01.png",
    iconSource: IconNewsletter_2021_01,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2021-01"
    }
  },
  {
    header: "Grudzień 2020",
    description: "Segmentuj albo giń",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/newsletter-2020-12.png",
    iconSource: IconNewsletter_2020_12,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2020-12"
    }
  },
  {
    header: "Listopad 2020",
    description: "Opinia vs dane",
    source:
      "https://zabkastorageprd.blob.core.windows.net/newsletters/newsletter-ogolny-11-2020.jpg",
    iconSource: IconNewsletter_2020_11,
    ga: {
      "data-ga-baza-wiedzy-newsletter": "2020-11"
    }
  }
];

//To display badge in navigation, this value has to be different on each newsletter update
export const VERSION = NEWSLETTERS.length;
