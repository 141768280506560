import { Action, createHook, createStore } from "react-sweet-state";

import { includes } from "ramda";

import { pushChartSwitchEvent } from "utils/googleTagManager/dataLayer";

export const SELECT_ALL_TOOLTIP =
  "Włącz wszystkie możliwe etykiety na wykresie";

export interface ValueTileData {
  lineName: string;
  timepoint: number;
  isRightChart: boolean;
  isHistorical: boolean;
  isSecondary: boolean;
}

interface State {
  tiles: ValueTileData[];
  selectAllTooltip: string;
  showBarChartLabels: boolean;
}

const initialState: State = {
  tiles: [],
  selectAllTooltip: SELECT_ALL_TOOLTIP,
  showBarChartLabels: false
};

type Actions = typeof actions;

const actions = {
  handleTiles: (
    newTiles: ValueTileData[],
    pushEvent = true
  ): Action<State> => ({ setState, getState }) => {
    const tiles = getState().tiles;
    const isTileInState = tiles.some(el => includes(el, newTiles));

    if (pushEvent) {
      pushChartSwitchEvent(isTileInState ? "Label-off" : "Label-on");
    }

    setState({
      tiles: isTileInState
        ? tiles.filter(el => !includes(el, newTiles))
        : [...tiles, ...newTiles]
    });
  },
  clearTiles: (): Action<State> => ({ setState }) => {
    setState({ tiles: [] });
  },
  updateSelectAllTooltip: (message: string): Action<State> => ({
    setState
  }) => {
    setState({ selectAllTooltip: message });
  },
  updateShowBarChartLabels: (show: boolean): Action<State> => ({
    setState
  }) => {
    setState({ showBarChartLabels: show });
  }
};

export const ChartValueTilesStore = createStore<State, Actions>({
  name: "chartValueTiles",
  initialState,
  actions
});

export const useChartValueTiles = createHook(ChartValueTilesStore);
