import { ParsedQuery } from "query-string";
import { createSelector } from "reselect";

import { EXPORT_TABLE_COLUMN_IDS } from "pages/Reports/utils";
import { AppState } from "store";
import { isPowerUserSelector } from "store/reducers/userReducer";

import { ExportTableData } from "../types";

type ColumnAccessor =
  | string
  | string[]
  | ParsedQuery
  | { from: string; to: string };

interface ExportTableColumn {
  Header: string;
  id: string;
  accessor?: (row: ExportTableData) => ColumnAccessor;
}

const exportSelector = (state: AppState) => state.reports.export;

export const exportFetchingStatusSelector = createSelector(
  exportSelector,
  ({ fetchingStatus }) => fetchingStatus
);

export const exportGenerationStatusSelector = createSelector(
  exportSelector,
  ({ generationStatus }) => generationStatus
);

export const exportTableDataSelector = createSelector(
  exportSelector,
  ({ tableData }) => tableData
);

export const exportTableColumnsSelector = createSelector<
  AppState,
  boolean,
  ExportTableColumn[]
>(isPowerUserSelector, isPowerUser => {
  const powerUserColumns: ExportTableColumn[] = isPowerUser
    ? [
        {
          Header: "Firma",
          accessor: data => data.vendorNames,
          id: EXPORT_TABLE_COLUMN_IDS.VENDOR_NAME
        }
      ]
    : [];

  return [
    {
      Header: "Data",
      accessor: data => data.date,
      id: EXPORT_TABLE_COLUMN_IDS.DATE
    },
    {
      Header: "Zakres czasowy",
      accessor: data => ({
        from: data.dateFrom,
        to: data.dateTo,
        compareFrom: data.compareDateFrom,
        compareTo: data.compareDateTo
      }),
      id: EXPORT_TABLE_COLUMN_IDS.PERIOD
    },
    ...powerUserColumns,
    {
      Header: "Podkategorie / produkty",
      accessor: data => data.categories,
      id: EXPORT_TABLE_COLUMN_IDS.CATEGORIES
    },
    {
      Header: "Kategorie referencyjne",
      accessor: data => data.referenceObjectsNames,
      id: EXPORT_TABLE_COLUMN_IDS.REFERENCE_CATEGORIES
    },
    {
      Header: "Segmenty / województwa lub powiaty",
      accessor: data => data.segments,
      id: EXPORT_TABLE_COLUMN_IDS.SEGMENTS
    },
    {
      Header: "Rodzaj danych",
      accessor: data => data.dataType,
      id: EXPORT_TABLE_COLUMN_IDS.DATA_TYPE
    },
    {
      Header: "Agregacja",
      accessor: data => data.timelineUnit,
      id: EXPORT_TABLE_COLUMN_IDS.TIMELINE_UNIT
    },
    {
      Header: "Opcje",
      accessor: data => data.params,
      id: EXPORT_TABLE_COLUMN_IDS.OPTIONS
    },
    {
      Header: "Plik",
      accessor: data => data.url,
      id: EXPORT_TABLE_COLUMN_IDS.FILE
    }
  ];
});
