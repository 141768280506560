import { Action, createHook, createStore } from "react-sweet-state";

import {
  getMatched,
  getSelectedCountLabel,
  remainingElementsToSelect,
  selectElement
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import { sortItemsBySelectionOrder } from "pages/Reports/redux/utils";
import { Nullable } from "utils/types";

import { ShopTypeItem, sortBySpecificOrder } from "./useShopType";

type State = { all: ShopTypeItem[]; selected: ShopTypeItem[] };
type Actions = typeof actions;

const actions = {
  updateAllShopTypesMeta: (all: ShopTypeItem[]): Action<State> => ({
    setState
  }) =>
    setState({
      all
    }),
  updateSelectedShopTypesMeta: (selected: ShopTypeItem[]): Action<State> => ({
    setState
  }) =>
    setState({
      selected
    }),
  selectShopTypeMeta: (
    selectedShopTypeMeta: Nullable<ShopTypeItem>
  ): Action<State> => ({ setState, getState }) =>
    setState({
      selected: selectElement(selectedShopTypeMeta, getState().selected)
    }),
  selectAllShopTypesMeta: (inputValue: string): Action<State> => ({
    setState,
    getState
  }) => {
    const state = getState();
    const allShopTypesMeta = state.all;
    const selectedShopTypesMeta = state.selected;

    const remainingToSelect = remainingElementsToSelect(
      selectedShopTypesMeta,
      allShopTypesMeta,
      inputValue
    );
    setState({
      selected: [...selectedShopTypesMeta, ...remainingToSelect]
    });
  },
  sortShopTypesMeta: (): Action<State> => ({ setState, getState }) => {
    const state = getState();

    const allShopTypesMeta = state.all;
    const selectedShopTypesMetaIds = state.selected.map(item => item.value);

    const sortedAllShopTypesMeta = sortItemsBySelectionOrder(
      selectedShopTypesMetaIds,
      allShopTypesMeta
    );

    const sortedBySpecificOrder = sortBySpecificOrder(sortedAllShopTypesMeta);

    setState({
      all: sortedBySpecificOrder
    });
  },
  clearShopTypesMeta: (): Action<State> => ({ setState }) =>
    setState({
      selected: []
    }),
  checkSelectionsAfterFetching: (ids: string[]): Action<State> => ({
    getState,
    setState
  }) =>
    setState({
      selected: getMatched(getState().selected, ids)
    }),
  checkPristineSelectionsAfterFetching: (
    shopTypesMeta: ShopTypeItem[],
    shopTypeMetaQueryParam: string | string[]
  ): Action<State> => ({ setState }) => {
    const updatedSelectedShopTypesMeta = shopTypesMeta.filter(({ value }) =>
      shopTypeMetaQueryParam.includes(value)
    );

    setState({
      selected: updatedSelectedShopTypesMeta
    });
  }
};

export const ShopTypeMetaStore = createStore<State, Actions>({
  name: "shopTypeMeta",
  initialState: {
    all: [],
    selected: []
  },
  actions
});

export const getSelectedShopTypesMetaIds = (state: State) =>
  state.selected.map(item => item.value);

export const getShopTypeMetaLabel = (state: State) => {
  if (state.selected.length === 1) {
    return state.selected[0].label;
  }
  return getSelectedCountLabel(state.selected.length, state.all.length);
};

export const ShopTypeMetaHooks = {
  useShopTypeMeta: createHook(ShopTypeMetaStore),
  useShopTypeMetaLabel: createHook(ShopTypeMetaStore, {
    selector: getShopTypeMetaLabel
  }),
  useSelectedShopTypesMetaIds: createHook(ShopTypeMetaStore, {
    selector: getSelectedShopTypesMetaIds
  })
};
