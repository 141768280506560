import { startOfYesterday } from "date-fns";

import { logisticsExportParamsSelector } from "pages/Reports/sections/Logistics/redux/selectors/logisticsReportSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { Thunk, Values } from "utils/types";

import { logisticsReportLoadingSelector } from "../selectors/logisticsReportSelectors";

// TYPES
interface State {
  isFetching: boolean;
  tableDate: Date;
}

interface LogisticsReportExportApi {
  url: string;
}
interface SetLogisticsReportLoadingAction {
  type: typeof SET_LOGISTICS_REPORT_LOADING;
  payload: boolean;
}

interface SetLogisticsTableDateAction {
  type: typeof SET_LOGISTICS_TABLE_DATE;
  payload: Date;
}

type Actions = {
  SET_LOGISTICS_REPORT_LOADING: SetLogisticsReportLoadingAction;
  SET_LOGISTICS_TABLE_DATE: SetLogisticsTableDateAction;
};

// ACTIONS
const SET_LOGISTICS_REPORT_LOADING = "Logistics.SET_LOGISTICS_REPORT_LOADING";
const SET_LOGISTICS_TABLE_DATE = "Logistics.SET_LOGISTICS_TABLE_DATE";

const setLogisticsReportLoading = (
  payload: boolean
): SetLogisticsReportLoadingAction => ({
  type: SET_LOGISTICS_REPORT_LOADING,
  payload
});

export const setLogisticsTableDate = (
  payload: Date
): SetLogisticsTableDateAction => ({
  type: SET_LOGISTICS_TABLE_DATE,
  payload
});

// THUNKS
export const generateLogisticsReport: (
  exportType: string
) => Thunk<SetLogisticsReportLoadingAction> = exportType => async (
  dispatch,
  getState
) => {
  const state = getState();
  const isFetching = logisticsReportLoadingSelector(state);

  if (isFetching) return;

  const params = logisticsExportParamsSelector(state)(exportType);

  try {
    const response = await HTTP.get<LogisticsReportExportApi>(
      REST_API_ENDPOINTS.REPORTS.LOGISTICS_EXPORT,
      {
        params
      }
    );

    dispatch(setLogisticsReportLoading(false));

    window.location.assign(response.data.url);
  } catch (err) {
    dispatch(setLogisticsReportLoading(false));
    console.error(err.message);
  }

  return;
};

// REDUCER
const initialState: State = {
  isFetching: false,
  tableDate: startOfYesterday()
};

export const logisticsReportReducer = (
  state: State = initialState,
  action: Values<Actions>
): State => {
  switch (action.type) {
    case SET_LOGISTICS_REPORT_LOADING:
      return {
        ...state,
        isFetching: action.payload
      };
    case SET_LOGISTICS_TABLE_DATE:
      return { ...state, tableDate: action.payload };
    default:
      return state;
  }
};
