import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";
import qs from "query-string";
import { equals } from "ramda";

import { SpinnerLoader, Text } from "components/atoms";
import { Search } from "components/atoms/Icon";
import {
  Dropdown,
  FilterDropdownInput,
  FilterDropdownListItem,
  FilterDropdownMenu
} from "components/molecules";
import { updateUserActivityTimestamp } from "pages/Login/redux/reducer";
import s from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/CompetitorsFilters/competitorsFilters.module.scss";
import {
  DROPDOWN_LABELS,
  DROPDOWN_PLACEHOLDERS
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/constants";
import { FilterErrorMsg } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/FilterErrorMsg/FilterErrorMsg";
import {
  filterItems,
  isActiveFn
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import {
  asyncFiltersSelector,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import {
  allCompetitionCompaniesSelector,
  CompanyItem,
  handleCompetitionCompanySelection,
  selectAllCompetitionCompanies,
  selectedCompetitionCompaniesLabelSelector,
  sortAllCompetitionCompaniesBySelectionOrder
} from "pages/Reports/redux/reducers/filters/competition/competitionCompaniesFilterReducer";
import { useCompetitionCompanyBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useCompetitorCompanyFetching";
import { pushFiltersEvent } from "utils/googleTagManager/dataLayer";

type Props = {
  selectedItems: CompanyItem[];
  isDisabled: boolean;
};

export const CompetitionCompanyDropdown = ({
  selectedItems,
  isDisabled
}: Props) => {
  const dispatch = useDispatch();
  const companyItems = useSelector(allCompetitionCompaniesSelector, equals);
  const isLoading = useSelector(asyncFiltersSelector).fetching
    .competitorCompanies;
  const selectedCompetitionCompaniesLabel = useSelector(
    selectedCompetitionCompaniesLabelSelector
  );
  const errorMsg = useSelector(asyncFiltersSelector).error.competitorCompanies;

  useCompetitionCompanyBehaviour(false);

  const [isOpen, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const handleChange = (item: CompanyItem | null) => {
    setOpen(true);
    dispatch(handleCompetitionCompanySelection(item));
    setTimeout(() => {
      dispatch(updateUserActivityTimestamp(Date.now())); // Fix to update dropdown which required double click to save selection. This can be removed when react sweet state management system is implemented
    }, 200);
  };

  const handleInputValueChange = (value: string) => {
    if (!value && value !== "") {
      return;
    }

    setInputValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
    setInputValue("");
  };

  const handleClose = (selectedItem?: CompanyItem | null) => {
    setOpen(false);
    if (!selectedItem) {
      dispatch(sortAllCompetitionCompaniesBySelectionOrder());
      setInputValue(selectedCompetitionCompaniesLabel);
    }
  };

  const handleStateChange = (changes: StateChangeOptions<CompanyItem[]>) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem);
    }
  };

  const handleSelectAll = () => {
    dispatch(selectAllCompetitionCompanies(inputValue));
    pushFiltersEvent("suppliers - select all");
  };

  const handleClear = () => {
    handleChange(null);
    pushFiltersEvent("suppliers - clear");
  };

  const filterCompanyByInput = (inputValue: string) => (item: CompanyItem) => {
    const isItemSelected = selectedItems.some(
      ({ value }) => value === item.value
    );

    //always show selected item
    if (isItemSelected) {
      return true;
    }

    const competitionCompanyMatchInputValue = filterItems<CompanyItem>(
      inputValue
    )(item);

    return competitionCompanyMatchInputValue;
  };

  React.useEffect(() => {
    if (!isOpen) setInputValue(selectedCompetitionCompaniesLabel);
  }, [isOpen, selectedCompetitionCompaniesLabel]);

  if (!isLoading && errorMsg) {
    return (
      <FilterErrorMsg
        label={DROPDOWN_LABELS.COMPANY}
        callback={() => {
          dispatch(
            updateQuery({
              filter: "competitorCompanies",
              query: qs.stringify({})
            })
          );
        }}
        errorMsg={errorMsg}
      />
    );
  }

  return (
    <Dropdown<CompanyItem[]>
      className={s.dropdownWrapper}
      isDisabled={isLoading || isDisabled}
      isMultiChoice
      // @ts-ignore
      itemToString={() => {}}
      selectedItem={selectedItems}
      items={companyItems}
      label={DROPDOWN_LABELS.COMPANY}
      openedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.COMPANY.OPEN
      }
      closedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.COMPANY.CLOSED
      }
      inputValue={inputValue}
      onInputValueChange={handleInputValueChange}
      isOpen={isOpen}
      // @ts-ignore
      onChange={handleChange}
      onClear={handleClear}
      onStateChange={handleStateChange}
      onSelectAll={handleSelectAll}
      input={
        <FilterDropdownInput
          customIcon={isLoading ? <SpinnerLoader size="small" /> : <Search />}
          isTypingEnabled
          automaticInputValue={selectedCompetitionCompaniesLabel}
          testId="competition-company-dropdown"
        />
      }
      menu={
        <FilterDropdownMenu<CompanyItem>
          isItemActiveFn={isActiveFn}
          filterItemsFn={filterCompanyByInput}
          listItem={<FilterDropdownListItem />}
          minimumItemsToVirtualize={30}
          inlinePlaceholder={
            isLoading && (
              <Text className={s.inlinePlaceholder}>Pobieranie danych</Text>
            )
          }
        />
      }
      onOuterClick={() => setOpen(false)}
    />
  );
};
