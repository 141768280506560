import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "components/molecules";
import {
  sortCompetitionProductsBySelectionOrder,
  toggleUnactiveCompetitionProductsBottom,
  unactiveCompetitionProductsBottomSelector
} from "pages/Reports/redux/reducers/filters/competition/competitionProductsFilterReducer";
import { ProductHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useProduct";
import { pushFiltersEvent } from "utils/googleTagManager/dataLayer";

import s from "./unactiveProductsCheckbox.module.scss";

interface Props {
  isCompetition?: boolean;
}

export const UnactiveProductsCheckbox = ({ isCompetition = false }: Props) => {
  const [
    { unactiveProductsBottom },
    { toggleUnactiveProductsBottom, sortProducts }
  ] = ProductHooks.useProduct();
  const unactiveCompetitionProductsBottom = useSelector(
    unactiveCompetitionProductsBottomSelector
  );
  const dispatch = useDispatch();

  const isChecked = isCompetition
    ? unactiveCompetitionProductsBottom
    : unactiveProductsBottom;

  const onChange = () => {
    if (isCompetition) {
      dispatch(
        toggleUnactiveCompetitionProductsBottom(
          !unactiveCompetitionProductsBottom
        )
      );
      dispatch(sortCompetitionProductsBySelectionOrder());
      pushFiltersEvent("suppliers products - inactive down");
      return;
    }

    toggleUnactiveProductsBottom();
    sortProducts();
    pushFiltersEvent("own products - inactive down");
  };

  const testId = isCompetition
    ? "toggle-unactive-competition-products-checkbox"
    : "toggle-unactive-products-checkbox";

  return (
    <Checkbox
      className={{
        base: s.unactiveProductsCheckbox,
        label: s.unactiveProductsLabel
      }}
      label="Przesuń produkty nieaktywne na dół"
      onChange={onChange}
      isChecked={isChecked}
      testId={testId}
    />
  );
};
