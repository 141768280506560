import { useSelector } from "react-redux";

import { useGetReceiptsByPeriod } from "api/receipts/mappers/useGetReceiptsByPeriod";
import { TooltipContent } from "api/types";
import {
  getDataTypeKey,
  getRankingPromotionsSidebar,
  shouldMergeTimeline
} from "api/utils";
import { formatSidebarValue } from "api/utils/formatSidebarValue";
import { getChangeFirstValueType } from "api/utils/getChangeFirstValueType";
import { mergeAll } from "ramda";

import {
  checkPromotionOccurence,
  transformPromotionsForTable
} from "components/atoms/PromotionContent/utils";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useSidebarMetric } from "components/organisms/SidebarMetricSelect/SidebarMetricSelect";
import { useFiltersQP } from "hooks";
import {
  FetchedPromotionTypes,
  usePromotionsForDates
} from "hooks/usePromotionsForDates";
import { useLegendContentNames } from "pages/Reports/partials/Chart/Legend/hooks/useLegendContentNames";
import { createLegendId } from "pages/Reports/partials/Chart/Legend/utils/createLegendId";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { useDataTypeSuffix } from "pages/Reports/partials/Chart/StandardChart/components/WithZappkaSelect";
import {
  hiddenLinesSelector,
  timepointSelector
} from "pages/Reports/redux/reducers/chartReducer";
import { findPromotionsForProduct } from "pages/Reports/redux/utils";
import { getSidebarHeader } from "pages/Reports/redux/utils/getSidebarHeader";
import { sortSidebarData } from "pages/Reports/redux/utils/sortSidebarData";
import { useGetZappkaSharesSuffix } from "pages/Reports/sections/NewChartDropdown/ZappkaSharesCheckbox/hooks";
import { ReceiptsTimeline } from "pages/Reports/sections/Receipts/redux/types";
import { checkedDataTypes } from "pages/Reports/sections/utils/chartData";
import { getChartLabel } from "pages/Reports/sections/utils/getChartLabel";
import {
  formatNumber,
  valueByDataType,
  valueByDataTypeBoolean
} from "pages/Reports/utils";
import { NUMBER_TYPE } from "pages/Reports/utils/formatNumber";
import {
  chartPeriodSelector,
  chartSidebarQueryParamsSelector,
  firstChartDataTypeSelector,
  isSidebarChangeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { newDateByTimezone } from "store/utils";
import { PERIOD_TYPE } from "utils";
import { isWeatherDataType, NO_DATA_SHORT } from "utils/constants";
import { Nullable } from "utils/types";

export const useReceiptsSidebarData = (): Nullable<TooltipContent> => {
  const chartPeriod = useSelector(chartPeriodSelector);
  const firstChartType = useSelector(firstChartDataTypeSelector);
  const secondChartType = useSelector(secondChartDataTypeSelector);
  const timepoint = useSelector(timepointSelector);
  const hiddenLines = useSelector(hiddenLinesSelector);
  const promotions = usePromotionsForDates({
    fetchedPromotionTypes: FetchedPromotionTypes.BOTH
  });
  const isSidebarChangeVisible = useSelector(isSidebarChangeSelector);
  const dataset = useCurrentDataset();
  const { isGroupedBarChart } = useChartTypeBoolean();
  const { dateFromQP, dateToQP } = useFiltersQP();
  const {
    sidebarSortBy,
    sidebarDesc,
    sidebarSortType,
    sidebarSortGroup,
    sidebarSortEnd
  } = useSelector(chartSidebarQueryParamsSelector);
  const contentNames = useLegendContentNames();
  const [suffix1, suffix2] = useDataTypeSuffix();
  const getZappkaSharesSuffix = useGetZappkaSharesSuffix();
  const { value: sidebarDataType } = useSidebarMetric();

  const { data: receipts } = useGetReceiptsByPeriod();

  if (chartPeriod === PERIOD_TYPE.RANKING) {
    return getRankingPromotionsSidebar({
      dateFromQP,
      dateToQP,
      promotions
    });
  }

  if (timepoint === null || timepoint < 0 || !receipts) {
    return null;
  }

  const dataTypes = checkedDataTypes([
    firstChartType,
    secondChartType,
    sidebarDataType
  ]).map((dataType, index) => {
    const sharesSuffix = getZappkaSharesSuffix(firstChartType ? index : 1);
    return {
      baseKey: dataType,
      primaryKey: getDataTypeKey({
        dataType,
        suffix: suffix1,
        sharesSuffix
      }),
      secondaryKey: getDataTypeKey({
        dataType,
        suffix: suffix2,
        sharesSuffix,
        isSecondary: true
      })
    };
  });

  const data = receipts.currentPeriod
    .filter(period =>
      dataTypes.some(type =>
        valueByDataTypeBoolean(
          type.primaryKey,
          period.existingMetrics,
          dataset,
          type.secondaryKey
        )
      )
    )
    .map(period => {
      const { vendorId, resultId, type, timeline } = period;
      const mergeTimeline = shouldMergeTimeline({
        isBothDatasets: dataset.both,
        isGroupedBarChart,
        domainLength: timeline.length
      });

      const legendId = createLegendId(
        getChartLabel({
          vendorId,
          type,
          resultId
        }),
        contentNames,
        period.type
      );

      const isInHiddenLines = hiddenLines.other.includes(legendId);

      return {
        label: resultId,
        vendorId,
        type,
        data: timeline[mergeTimeline ? 0 : timepoint],
        legendId,
        isInHiddenLines,
        isTimelineMerged: mergeTimeline
      };
    });

  const regularDate = data[0]?.data?.date || "";

  if (!regularDate) {
    return null;
  }

  const sortedData = sortSidebarData(
    sidebarSortBy,
    sidebarDesc,
    sidebarSortType,
    sidebarSortGroup,
    sidebarSortEnd
  )(data);

  const firstDataElement = sortedData[0]?.data as ReceiptsTimeline;
  const hoveredDate = newDateByTimezone(firstDataElement?.date);
  const hoveredLastYearDate = newDateByTimezone(
    firstDataElement?.dateLastYear || undefined
  );

  const promotionsInSelectedPeriod = checkPromotionOccurence(
    hoveredDate,
    promotions,
    chartPeriod
  );

  const promotionsInSelectedLastYearPeriod =
    isGroupedBarChart && dataset.both
      ? checkPromotionOccurence(hoveredLastYearDate, promotions, chartPeriod)
      : [];

  const promotionsForSidebar = transformPromotionsForTable([
    ...promotionsInSelectedPeriod,
    ...promotionsInSelectedLastYearPeriod
  ]);

  const lastYearDate = data[0]?.data?.dateLastYear;
  const header = getSidebarHeader(
    regularDate,
    lastYearDate,
    chartPeriod,
    dataset
  );

  return {
    header,
    date: newDateByTimezone(data[0].data.date),
    promotions: promotionsForSidebar,
    data: sortedData.map(data =>
      mergeAll(
        dataTypes.map(type => {
          const lastYear = {
            primaryKey: type.primaryKey.concat("_LAST_YEAR"),
            secondaryKey: type.secondaryKey.concat("_LAST_YEAR")
          };
          const change = {
            primaryKey: type.primaryKey.concat("_CHANGE"),
            secondaryKey: type.secondaryKey.concat("_CHANGE")
          };
          const changePercentage = {
            primaryKey: type.primaryKey.concat("_CHANGE_PERCENTAGE"),
            secondaryKey: type.secondaryKey.concat("_CHANGE_PERCENTAGE")
          };

          const showChangeColumn =
            dataset.both &&
            !isWeatherDataType(type.baseKey) &&
            isSidebarChangeVisible;

          return {
            item: {
              vendorId: data.vendorId,
              type: data.type,
              label: data.label,
              promotions:
                findPromotionsForProduct(
                  data.label,
                  promotionsInSelectedPeriod
                ) || null,
              legendId: data.legendId,
              isInHiddenLines: data.isInHiddenLines
            },
            // primary key
            [type.primaryKey]: {
              firstValue: formatSidebarValue(
                type.baseKey,
                dataset.historical ? lastYear.primaryKey : type.primaryKey,
                data.data
              ),
              ...(dataset.both && {
                secondValue: formatSidebarValue(
                  type.baseKey,
                  lastYear.primaryKey,
                  data.data
                ),
                secondValueBaseType: lastYear.primaryKey
              })
            },
            // primary change column
            ...(showChangeColumn && {
              [change.primaryKey]: {
                firstValue: formatNumber(
                  valueByDataType(change.primaryKey, data.data),
                  getChangeFirstValueType(change.primaryKey),
                  NO_DATA_SHORT
                ),
                secondValue: formatNumber(
                  valueByDataType(changePercentage.primaryKey, data.data),
                  NUMBER_TYPE.PERCENT,
                  NO_DATA_SHORT
                ),
                secondValueType: changePercentage.primaryKey
              }
            }),
            // secondary column
            ...(Boolean(type.secondaryKey) && {
              [type.secondaryKey]: {
                firstValue: formatSidebarValue(
                  type.baseKey,
                  dataset.historical
                    ? lastYear.secondaryKey
                    : type.secondaryKey,
                  data.data
                ),
                ...(dataset.both && {
                  secondValue: formatSidebarValue(
                    type.baseKey,
                    lastYear.secondaryKey,
                    data.data
                  ),
                  secondValueBaseType: lastYear.secondaryKey
                })
              },
              ...(showChangeColumn && {
                [change.secondaryKey]: {
                  firstValue: formatNumber(
                    valueByDataType(change.secondaryKey, data.data),
                    NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
                  ),
                  secondValue: formatNumber(
                    valueByDataType(changePercentage.secondaryKey, data.data),
                    NUMBER_TYPE.PERCENT
                  ),
                  secondValueType: changePercentage.secondaryKey
                }
              })
            })
          };
        })
      )
    )
  };
};
