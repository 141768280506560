import { useSelector } from "react-redux";

import { TooltipContent } from "api/types";
import { shouldMergeTimeline } from "api/utils";
import { mergeAll } from "ramda";

import {
  checkPromotionOccurence,
  transformPromotionsForTable
} from "components/atoms/PromotionContent/utils";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useSidebarMetric } from "components/organisms/SidebarMetricSelect/SidebarMetricSelect";
import {
  FetchedPromotionTypes,
  usePromotionsForDates
} from "hooks/usePromotionsForDates";
import { useLegendContentNames } from "pages/Reports/partials/Chart/Legend/hooks/useLegendContentNames";
import { createLegendId } from "pages/Reports/partials/Chart/Legend/utils/createLegendId";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import {
  hiddenLinesSelector,
  timepointSelector
} from "pages/Reports/redux/reducers/chartReducer";
import { findPromotionsForProduct } from "pages/Reports/redux/utils";
import { getSidebarHeader } from "pages/Reports/redux/utils/getSidebarHeader";
import { sortSidebarData } from "pages/Reports/redux/utils/sortSidebarData";
import { checkedDataTypes } from "pages/Reports/sections/utils/chartData";
import { getChartLabel } from "pages/Reports/sections/utils/getChartLabel";
import {
  formatNumber,
  formatTooltipValue,
  valueByDataType,
  valueByDataTypeBoolean
} from "pages/Reports/utils";
import { NUMBER_TYPE } from "pages/Reports/utils/formatNumber";
import {
  chartPeriodSelector,
  chartSidebarQueryParamsSelector,
  firstChartDataTypeSelector,
  isSidebarChangeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { newDateByTimezone } from "store/utils";
import { NO_DATA_SHORT } from "utils";
import { Nullable } from "utils/types";

import { MultipackTimeline } from "../types";
import { useGetMultipackByPeriod } from "./useGetMultipackByPeriod";

export const useMultipackSidebarData = (): Nullable<TooltipContent> => {
  const chartPeriod = useSelector(chartPeriodSelector);
  const firstChartType = useSelector(firstChartDataTypeSelector);
  const secondChartType = useSelector(secondChartDataTypeSelector);
  const timepoint = useSelector(timepointSelector);
  const hiddenLines = useSelector(hiddenLinesSelector);
  const isSidebarChangeVisible = useSelector(isSidebarChangeSelector);
  const {
    sidebarSortBy,
    sidebarDesc,
    sidebarSortType,
    sidebarSortGroup,
    sidebarSortEnd
  } = useSelector(chartSidebarQueryParamsSelector);
  const dataset = useCurrentDataset();
  const promotions = usePromotionsForDates({
    fetchedPromotionTypes: FetchedPromotionTypes.BOTH
  });
  const contentNames = useLegendContentNames();
  const { isGroupedBarChart } = useChartTypeBoolean();
  const { value: sidebarDataType } = useSidebarMetric();

  const { data: multipack } = useGetMultipackByPeriod();

  if (timepoint === null || timepoint < 0 || !multipack) {
    return null;
  }

  const dataTypes = checkedDataTypes([
    firstChartType,
    secondChartType,
    sidebarDataType
  ]);

  const data = multipack.data.data
    .filter(result =>
      dataTypes.some(dataType =>
        valueByDataTypeBoolean(dataType, result.existingMetrics, dataset)
      )
    )
    .map(period => {
      const { vendorId, resultId, type, timeline } = period;
      const mergeTimeline = shouldMergeTimeline({
        isBothDatasets: dataset.both,
        isGroupedBarChart,
        domainLength: timeline.length
      });

      const legendId = createLegendId(
        getChartLabel({
          vendorId,
          type,
          resultId
        }),
        contentNames,
        period.type
      );

      const isInHiddenLines = hiddenLines.other.includes(legendId);

      return {
        label: resultId,
        vendorId,
        type,
        data: timeline[mergeTimeline ? 0 : timepoint],
        legendId,
        isInHiddenLines,
        isTimelineMerged: mergeTimeline
      };
    });

  const regularDate = data[0]?.data?.date || "";

  if (!data.length || !regularDate) {
    return null;
  }

  const sortedData = sortSidebarData(
    sidebarSortBy,
    sidebarDesc,
    sidebarSortType,
    sidebarSortGroup,
    sidebarSortEnd
  )(data);

  const firstDataElement = sortedData[0]?.data as MultipackTimeline;
  const hoveredDate = newDateByTimezone(firstDataElement?.date);
  const hoveredLastYearDate = newDateByTimezone(
    firstDataElement?.dateLastYear || undefined
  );

  const promotionsInSelectedPeriod = checkPromotionOccurence(
    hoveredDate,
    promotions,
    chartPeriod
  );

  const promotionsInSelectedLastYearPeriod =
    isGroupedBarChart && dataset.both
      ? checkPromotionOccurence(hoveredLastYearDate, promotions, chartPeriod)
      : [];

  const promotionsForSidebar = transformPromotionsForTable([
    ...promotionsInSelectedPeriod,
    ...promotionsInSelectedLastYearPeriod
  ]);

  const lastYearDate = data[0]?.data?.dateLastYear;
  const header = getSidebarHeader(
    regularDate,
    lastYearDate,
    chartPeriod,
    dataset
  );

  return {
    date: newDateByTimezone(data[0].data.date),
    header,
    promotions: promotionsForSidebar,
    data: sortedData.map(data =>
      mergeAll(
        dataTypes.map(type => {
          const lastYear = `${type}_LAST_YEAR`;
          const base = dataset.historical ? lastYear : type;
          const change = `${type}_CHANGE`;
          const changePercentage = `${type}_CHANGE_PERCENTAGE`;
          const showChangeColumn = dataset.both && isSidebarChangeVisible;

          return {
            item: {
              vendorId: data.vendorId,
              type: data.type,
              label: data.label,
              promotions:
                findPromotionsForProduct(
                  data.label,
                  promotionsInSelectedPeriod
                ) || null,
              legendId: data.legendId,
              isInHiddenLines: data.isInHiddenLines
            },
            [type]: {
              firstValue: formatTooltipValue(
                valueByDataType(base, data.data),
                base
              ),
              ...(dataset.both && {
                secondValue: formatTooltipValue(
                  valueByDataType(lastYear, data.data),
                  lastYear
                ),
                lastYear
              })
            },
            ...(showChangeColumn && {
              [change]: {
                firstValue: formatNumber(
                  valueByDataType(change, data.data),
                  NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE,
                  NO_DATA_SHORT
                ),
                secondValue: formatNumber(
                  valueByDataType(changePercentage, data.data),
                  NUMBER_TYPE.PERCENT,
                  NO_DATA_SHORT
                ),
                secondValueType: changePercentage
              }
            })
          };
        })
      )
    )
  };
};
