import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Text, ToggleButton } from "components/atoms";
import { ToggleButtonList } from "components/molecules";
import { setFilterFormPristine } from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useFiltersClear } from "pages/Reports/redux/reducers/filters/hooks/useFiltersClear";
import { updateQueryParams } from "store/actions/routerActions";
import {
  isCatmanSelector,
  isSuperUserSelector,
  setCurrentTier,
  tierSelector,
  userTiersAvailableSelector
} from "store/reducers/userReducer";
import { TEXT_WEIGHTS, TEXT_WRAPPERS } from "utils";
import { QP } from "utils/defaultQueryParams";
import { Tier } from "utils/types";

import s from "./tierChoice.module.scss";

export const TierChoice = () => {
  const dispatch = useDispatch();
  const tiersAvailable = useSelector(userTiersAvailableSelector);
  const currentTier = useSelector(tierSelector);
  const isSuperUser = useSelector(isSuperUserSelector);
  const isCatman = useSelector(isCatmanSelector);
  const clearFilters = useFiltersClear();

  const handleChange = async (tier: Tier) => {
    await dispatch(setCurrentTier(tier));
    dispatch(setFilterFormPristine(true));
    dispatch(
      updateQueryParams(
        {
          [QP.CATEGORY1]: "",
          [QP.CATEGORY2]: "",
          [QP.CATEGORY3]: ""
        },
        "push"
      )
    );

    clearFilters();
  };

  if (!tiersAvailable || !tiersAvailable.length || isSuperUser || isCatman) {
    return null;
  }

  return (
    <div className={s.tierChoiceWrapper}>
      <Text size="small" className={s.labelText} weight={TEXT_WEIGHTS.BOLD}>
        Dostęp do danych
        <Text Wrapper={TEXT_WRAPPERS.SPAN} className={s.requiredSign}>
          *
        </Text>
      </Text>
      <ToggleButtonList>
        {tiersAvailable.map(tierOption => {
          return (
            <ToggleButton
              className={{
                li: s.toggleButtonLi,
                button: s.toggleButton
              }}
              key={`tierOption${tierOption}`}
              testId={`tier-option${tierOption}`}
              onClick={() => handleChange(tierOption)}
              label={`T${tierOption}`}
              isActive={currentTier === tierOption}
              data-testid={`tierOption${tierOption}`}
            />
          );
        })}
      </ToggleButtonList>
    </div>
  );
};
