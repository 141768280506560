import { format } from "date-fns";
import memoize from "memoize-one";
import { createSelector } from "reselect";

import { reportsSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { transformFetchQueryForLogisticsTable } from "pages/Reports/sections/Logistics/utils/transformFetchQueryForLogisticsTable";
import { generateReportExportParams } from "pages/Reports/sections/Sell/redux/utils";
import { EXPORT_TYPES } from "pages/Reports/utils/constants";
import {
  pathnameSelector,
  searchStringSelector
} from "store/selectors/routerSelectors";
import { DEFAULT_DATE_FORMAT } from "utils";

const logisticsReportSelector = createSelector(
  reportsSelector,
  reports => reports.logistics.report
);

export const logisticsReportLoadingSelector = createSelector(
  logisticsReportSelector,
  report => report.isFetching
);

export const logisticsTableDateSelectorFactory = (
  dateFormat = DEFAULT_DATE_FORMAT
) =>
  createSelector(logisticsReportSelector, ({ tableDate }) =>
    format(tableDate, dateFormat)
  );

export const logisticsExportParamsSelector = createSelector(
  pathnameSelector,
  searchStringSelector,
  logisticsTableDateSelectorFactory(),
  (pathname, search, tableDate) =>
    memoize((exportType: string) => {
      const searchString =
        exportType === EXPORT_TYPES.LOGISTICS_SINGLE_DAY
          ? transformFetchQueryForLogisticsTable(search, tableDate)
          : search;

      return generateReportExportParams(pathname, searchString, "");
    })
);
