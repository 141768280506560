import { useSelector } from "react-redux";

import { isNil } from "ramda";

import { asyncFiltersSelector } from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { selectedCompetitionCompaniesSelector } from "pages/Reports/redux/reducers/filters/competition/competitionCompaniesFilterReducer";
import { AttributesHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useAttributes";
import {
  BrandHooks,
  BrandType
} from "pages/Reports/redux/reducers/sweetStateHooks/useBrand";
import { CategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCategory";
import { ProductHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useProduct";
import { VendorHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVendor";
import { VoivodeshipHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVoivodeship";
import { isCompaniesDropdownMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { tierSelector } from "store/reducers/userReducer";
import {
  brandQueryParamSelector,
  competitionCompaniesQueryParamSelector,
  competitionProductsQueryParamSelector,
  competitorBrandQueryParamSelector,
  competitorSubBrandQueryParamSelector,
  productsQueryParamSelector,
  queryParamsSelector,
  referenceBrandQueryParamSelector,
  referenceSubBrandQueryParamSelector,
  vendorIdQueryParamSelector,
  voivodeshipQueryParamSelector
} from "store/selectors/routerSelectors";
import { QP } from "utils/defaultQueryParams";

import { selectedCompetitionProductsIdsSelector } from "../competition/competitionProductsFilterReducer";

type QueryFilters =
  | "category"
  | "categoryLevel"
  | "category1"
  | "attributes"
  | "tier"
  | "vendor"
  | "brand"
  | "subBrand"
  | "competitorCompany"
  | "competitorBrand"
  | "competitorSubBrand"
  | "referenceBrand"
  | "referenceSubBrand"
  | "voivodeships"
  | "product"
  | "competitorProduct";

export const useFiltersFetchingParams = (
  filters: QueryFilters[]
): {
  [K in QueryFilters]: string;
} => {
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const params = useSelector(queryParamsSelector);

  // CATEGORIES
  const [category1] = CategoryHooks.useSelectedCategoriesIds(1);
  const [category2] = CategoryHooks.useSelectedCategoriesIds(2);
  const [category3] = CategoryHooks.useSelectedCategoriesIds(3);

  let [category, categoryLevel] = ["", ""];
  if (category1.length) {
    [category, categoryLevel] = [String(category1), "1"];
  }
  if (category2.length) {
    [category, categoryLevel] = [String(category2), "2"];
  }
  if (category3.length) {
    [category, categoryLevel] = [String(category3), "3"];
  }

  let [categoryQP, categoryLevelQP] = ["", ""];
  if (params[QP.CATEGORY1]) {
    [categoryQP, categoryLevelQP] = [String(params[QP.CATEGORY1]), "1"];
  }
  if (params[QP.CATEGORY2]) {
    [categoryQP, categoryLevelQP] = [String(params[QP.CATEGORY2]), "2"];
  }
  if (params[QP.CATEGORY3]) {
    [categoryQP, categoryLevelQP] = [String(params[QP.CATEGORY3]), "3"];
  }

  // ATTRIBUTES
  const [attributes] = AttributesHooks.useSelectedAttributes();

  // TIER
  const tier = String(useSelector(tierSelector));

  // VENDORS
  const isMultiChoice = useSelector(isCompaniesDropdownMultiChoiceSelector);
  const [vendor] = VendorHooks.useSelectedVendorsIds(isMultiChoice);
  const vendorQP = String(
    useSelector(vendorIdQueryParamSelector)
      .toString()
      .split(",")
  );

  // BRANDS
  const [brand] = BrandHooks.useSelectedBrandsIds(BrandType.brand);
  const brandQP = useSelector(brandQueryParamSelector);

  // SUB BRANDS
  const [subBrands] = BrandHooks.useSelectedBrandsIds(BrandType.subBrand);
  const subBandsQP = useSelector(competitorSubBrandQueryParamSelector);

  // PRODUCTS
  const [products] = ProductHooks.useSelectedProductsIds();
  const productsQP = useSelector(productsQueryParamSelector);

  // COMPETITOR COMPANY
  const competitionCompaniesIds = useSelector(
    selectedCompetitionCompaniesSelector
  ).map(company => company.value);
  const competitionCompaniesIdsQP = useSelector(
    competitionCompaniesQueryParamSelector
  );

  // COMPETITOR BRAND
  const competitorBrandsQP = String(
    useSelector(competitorBrandQueryParamSelector)
  );
  const [competitorBrands] = BrandHooks.useSelectedBrandsIds(
    BrandType.competingBrand
  );

  // COMPETITOR SUB BRAND
  const competitorSubBrandsQP = String(
    useSelector(competitorSubBrandQueryParamSelector)
  );
  const [competitorSubBrands] = BrandHooks.useSelectedBrandsIds(
    BrandType.competingSubBrand
  );
  // REFERENCE BRAND
  const referenceBrandsQP = String(
    useSelector(referenceBrandQueryParamSelector)
  );
  const [referenceBrands] = BrandHooks.useSelectedBrandsIds(
    BrandType.referenceBrand
  );

  // REFERENCE SUB BRAND
  const referenceSubBrandsQP = String(
    useSelector(referenceSubBrandQueryParamSelector)
  );
  const [referenceSubBrands] = BrandHooks.useSelectedBrandsIds(
    BrandType.referenceSubBrand
  );

  //COMPETITION PRODUCTS
  const competitionProducts = useSelector(
    selectedCompetitionProductsIdsSelector
  );
  const competitionProductsQP = useSelector(
    competitionProductsQueryParamSelector
  );

  // VOIVODESHIP
  const [voivodeships] = VoivodeshipHooks.useSelectedVoivodeshipIds();
  const voivodeshipsQP = useSelector(voivodeshipQueryParamSelector);

  const pristineBasedProperty = (
    filter: QueryFilters,
    [val, valQP]: [string | string[], string | string[]]
  ) => {
    return isPristine ? [filter, valQP] : [filter, val];
  };

  const entries = filters
    .map(filter => {
      switch (filter) {
        case "category":
          return pristineBasedProperty("category", [category, categoryQP]);
        case "categoryLevel":
          return pristineBasedProperty("categoryLevel", [
            categoryLevel,
            categoryLevelQP
          ]);
        case "category1":
          return pristineBasedProperty("category1", [
            String(category1),
            String(params[QP.CATEGORY1])
          ]);
        case "attributes":
          return pristineBasedProperty("attributes", [
            attributes.join(","),
            String(params[QP.ATTRIBUTES] || "")
          ]);
        case "tier":
          return pristineBasedProperty("tier", [tier, tier]);
        case "vendor":
          return pristineBasedProperty("vendor", [String(vendor), vendorQP]);
        case "brand":
          return pristineBasedProperty("brand", [String(brand), brandQP]);
        case "subBrand":
          return pristineBasedProperty("subBrand", [
            String(subBrands),
            subBandsQP
          ]);
        case "product":
          return pristineBasedProperty("product", [
            String(products),
            productsQP
          ]);
        case "competitorCompany":
          return pristineBasedProperty("competitorCompany", [
            String(competitionCompaniesIds),
            competitionCompaniesIdsQP
          ]);
        case "competitorBrand":
          return pristineBasedProperty("competitorBrand", [
            String(competitorBrands),
            competitorBrandsQP
          ]);
        case "competitorSubBrand":
          return pristineBasedProperty("competitorSubBrand", [
            String(competitorSubBrands),
            competitorSubBrandsQP
          ]);
        case "referenceBrand":
          return pristineBasedProperty("referenceBrand", [
            String(referenceBrands),
            referenceBrandsQP
          ]);
        case "referenceSubBrand":
          return pristineBasedProperty("referenceSubBrand", [
            String(referenceSubBrands),
            referenceSubBrandsQP
          ]);
        case "competitorProduct":
          return pristineBasedProperty("competitorProduct", [
            String(competitionProducts),
            competitionProductsQP
          ]);
        case "voivodeships":
          return pristineBasedProperty("voivodeships", [
            voivodeships,
            voivodeshipsQP
          ]);
        default:
          return null;
      }
    })
    .filter(filter => !isNil(filter)) as [string | string[]][]; // Checking types with filter is snippy. In this case values are only string | string[]

  return Object.fromEntries(entries);
};
