import { useSelector } from "react-redux";

import { combineReducers } from "redux";

import { useFiltersQP } from "hooks";
import { clearCompetitionCompaniesFilter } from "pages/Reports/redux/reducers/filters/competition/competitionCompaniesFilterReducer";
import { selectedCompetitionFiltersSelector } from "pages/Reports/redux/reducers/filters/competition/competitionFiltersReducer";
import { clearCompetitionProductsFilter } from "pages/Reports/redux/reducers/filters/competition/competitionProductsFilterReducer";
import {
  AggregatedHooks,
  AggregationTypes
} from "pages/Reports/redux/reducers/sweetStateHooks/useAggregated";
import { AggregatedCompetitionHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useAggregatedCompetition";
import {
  BrandHooks,
  BrandType
} from "pages/Reports/redux/reducers/sweetStateHooks/useBrand";
import { CalendarHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCalendar";
import { CategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCategory";
import { ChartPeriodHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useChartPeriod";
import { ClientTypeHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useClientType";
import { CountyHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCounty";
import { DelayHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useDelay";
import { ExcludedDaysHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useExcludedDays";
import { LFLHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useLFL";
import { ProductHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useProduct";
import { ReferenceCategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useReferenceCategory";
import { ShopTypeHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useShopType";
import { ShopTypeMetaHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useShopTypeMeta";
import { VendorHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVendor";
import { VoivodeshipHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVoivodeship";
import { isCompaniesDropdownMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { RequestStatus } from "pages/Reports/sections/Export/redux/reducers/exportReducer";
import { exportGenerationStatusSelector } from "pages/Reports/sections/Export/redux/reducers/exportSelectors";
import {
  isPowerUserSelector,
  userCompanySelector
} from "store/reducers/userReducer";
import {
  pathnameSelector,
  queryParamsSelector
} from "store/selectors/routerSelectors";
import { toArray } from "store/utils";
import {
  AGGREGATED_COMPETITION_ALLOWED_TIER,
  APP_PATHS,
  isThisPage,
  UNLOCKED_CATEGORIES_COMPANIES
} from "utils";
import { QP } from "utils/defaultQueryParams";
import { Thunk } from "utils/types";

import { areFiltersReadySelector } from "../asyncFiltersReducer";
import { AttributesHooks } from "../sweetStateHooks/useAttributes";
import { PromotionsHooks } from "../sweetStateHooks/usePromotions";
import { categoryFiltersReducer } from "./categoryFilters/categoryFiltersReducer";
import {
  clearExportDataTypeFilter,
  exportDataTypeReducer,
  exportDataTypeSelector
} from "./exportDataTypeReducer";

// SELECTORS
export const useAreTopFiltersSelected = () => {
  const currentCompanyId = useSelector(userCompanySelector)?.warehouseId || "0";
  const [categoriesTopLevel] = CategoryHooks.useCategoriesTopLevel();
  const [categories] = CategoryHooks.useSelectedCategories(categoriesTopLevel);

  if (
    // workaround requested by client to give access to G3 category without selecting G2 for bacardi company
    UNLOCKED_CATEGORIES_COMPANIES.includes(currentCompanyId)
  ) {
    return !!categories.length;
  }

  return !!categories.length;
};

export const useDisabledSubmit = () => {
  const areFiltersReady = useSelector(areFiltersReadySelector);

  const [
    areSelectedCategoriesFake
  ] = CategoryHooks.useSelectedCategoriesFakeIndicator();
  const [topLevelSelectedItems] = CategoryHooks.useSelectedTopLevelCategory();

  const pathname = useSelector(pathnameSelector);
  const isExportPage = isThisPage(pathname, APP_PATHS.EXPORT);
  const exportGenerationStatus = useSelector(exportGenerationStatusSelector);

  if (!areFiltersReady) {
    return true;
  }

  if (areSelectedCategoriesFake || !topLevelSelectedItems.length) {
    return true;
  }

  if (isExportPage && exportGenerationStatus === RequestStatus.IN_PROGRESS) {
    return true;
  }

  return false;
};

export const useSelectedFilters = () => {
  const { vendorIdQP } = useFiltersQP();

  const [category1] = CategoryHooks.useSelectedCategoriesIds(1);
  const [category2] = CategoryHooks.useSelectedCategoriesIds(2);
  const [category3] = CategoryHooks.useSelectedCategoriesIds(3);
  const [attributes] = AttributesHooks.useSelectedAttributes();
  const [product] = ProductHooks.useSelectedProductsIds();
  const [shopTypes] = ShopTypeHooks.useSelectedShopTypesIds();
  const [shopTypesMeta] = ShopTypeMetaHooks.useSelectedShopTypesMetaIds();
  const [clientType] = ClientTypeHooks.useClientTypeValue();
  const [counties] = CountyHooks.useSelectedCountiesIds();
  const [voivodeships] = VoivodeshipHooks.useSelectedVoivodeshipIds();
  const [lfl] = LFLHooks.useLFL();
  const [delay] = DelayHooks.useDelay();
  const [aggregated] = AggregatedHooks.useAggregated();
  const [chartPeriod] = ChartPeriodHooks.useChartPeriod();
  const [brands] = BrandHooks.useSelectedBrandsIds(BrandType.brand);
  const [subBrands] = BrandHooks.useSelectedBrandsIds(BrandType.subBrand);
  const [competingBrand] = BrandHooks.useSelectedBrandsIds(
    BrandType.competingBrand
  );
  const [competingSubBrand] = BrandHooks.useSelectedBrandsIds(
    BrandType.competingSubBrand
  );
  const [referenceBrand] = BrandHooks.useSelectedBrandsIds(
    BrandType.referenceBrand
  );
  const [referenceSubBrand] = BrandHooks.useSelectedBrandsIds(
    BrandType.referenceSubBrand
  );
  const [referenceBrandAggregated] = BrandHooks.useRefBrandAggregated();
  const [
    { ref_cat1, ref_cat2, ref_cat3 }
  ] = ReferenceCategoryHooks.useSelectedIds();
  const [promotions] = PromotionsHooks.useSelectedPromotionsIds();
  const [ref_cat_aggregated] = ReferenceCategoryHooks.useAggregated();
  const [
    {
      selected: basePeriod,
      selectedHistorical: comparePeriod,
      selectedHistoricalOpen: isHistoricalOpen,
      includeLeapYear,
      isDynamicPeriodChecked,
      dynamicPeriod,
      selectedDynamicPeriod
    }
  ] = CalendarHooks.useCalendar();
  const [excludedDays] = ExcludedDaysHooks.useExcludedDays();

  const competitionFilters = useSelector(
    selectedCompetitionFiltersSelector(delay.delay)
  );
  const exportDataType = useSelector(exportDataTypeSelector);

  const isMultiChoice = useSelector(isCompaniesDropdownMultiChoiceSelector);
  const isPowerUser = useSelector(isPowerUserSelector);
  const queryParams = useSelector(queryParamsSelector);

  const [selectedCompaniesIds] = VendorHooks.useSelectedVendorsIds(
    isMultiChoice
  );

  const vendor_id = isPowerUser ? selectedCompaniesIds : toArray(vendorIdQP);

  const compare_date_from = isHistoricalOpen ? comparePeriod.dateFrom : "";
  const compare_date_to = isHistoricalOpen ? comparePeriod.dateTo : "";
  const include_leap_year =
    isHistoricalOpen && includeLeapYear !== null ? String(includeLeapYear) : "";

  const shouldAddAggregatedCompetition =
    queryParams[QP.TIER] === String(AGGREGATED_COMPETITION_ALLOWED_TIER) &&
    String(aggregated.selected) === AggregationTypes.total;

  const [
    aggregated_competition
  ] = AggregatedCompetitionHooks.useAggregatedCompetition();

  return {
    date_from: basePeriod.dateFrom,
    date_to: basePeriod.dateTo,
    compare_date_from,
    compare_date_to,
    include_leap_year,
    dynamic_period_checked: String(isDynamicPeriodChecked),
    dynamic_period: dynamicPeriod,
    initial_date_from: selectedDynamicPeriod.dateFrom,
    initial_date_to: selectedDynamicPeriod.dateTo,
    excluded_days: String(excludedDays.excludedDays),
    category1: String(category1),
    category2: String(category2),
    category3: String(category3),
    ref_cat1: String(ref_cat1),
    ref_cat2: String(ref_cat2),
    ref_cat3: String(ref_cat3),
    ref_cat_aggregated: String(ref_cat_aggregated),
    product: String(product),
    store_types: String(shopTypes),
    store_types_meta: String(shopTypesMeta),
    counties: String(counties),
    voivodeships: String(voivodeships),
    client: String(clientType),
    lfl: String(lfl.lfl),
    delay: String(delay.delay),
    aggregated: String(aggregated.selected),
    competing_companies: String(competitionFilters.competing_companies),
    competing_materials: String(competitionFilters.competing_materials),
    export_data_type: exportDataType,
    chart_period: String(chartPeriod.selected.value),
    vendor_id,
    brands: String(brands),
    sub_brands: String(subBrands),
    competing_brands: String(competingBrand),
    competing_sub_brands: String(competingSubBrand),
    ref_brand: String(referenceBrand),
    ref_subbrand: String(referenceSubBrand),
    ref_brand_aggregated: String(referenceBrandAggregated),
    promotions: String(promotions),
    attributes: String(attributes),
    ...(shouldAddAggregatedCompetition && {
      aggregated_competition: String(aggregated_competition.aggregated)
    })
  };
};

// THUNKS
export const clearNonHookFilters = (): Thunk<any> => dispatch => {
  dispatch(clearCompetitionCompaniesFilter());
  dispatch(clearCompetitionProductsFilter());
  dispatch(clearExportDataTypeFilter());
};

// REDUCER
export const reportsFiltersReducer = combineReducers({
  category: categoryFiltersReducer,
  exportDataType: exportDataTypeReducer
});
