export const DAYS_OF_WEEK_TRANSLATION = {
  MONDAY: {
    en: "monday",
    pl: "poniedziałek"
  },
  TUESDAY: {
    en: "tuesday",
    pl: "wtorek"
  },
  WEDNESDAY: {
    en: "wednesday",
    pl: "środa"
  },
  THURSDAY: {
    en: "thursday",
    pl: "czwartek"
  },
  FRIDAY: {
    en: "friday",
    pl: "piątek"
  },
  SATURDAY: {
    en: "saturday",
    pl: "sobota"
  },
  SUNDAY: {
    en: "sunday",
    pl: "niedziela"
  }
};

export const PROMOTIONS_SEPARATOR = "; ";
