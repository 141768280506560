import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { filtersParamsMap } from "api/utils/constants";
import qs from "query-string";
import { pick } from "ramda";

import { VOIVODESHIPS } from "components/D3/Voivodeship/constants";
import { isHistoricalDataEnabledSelector } from "components/molecules/DateDropdownMenu/HistoricalSection/selectors";
import { useVisibleList } from "pages/Reports/redux/reducers/filters/hooks/useVisibleFilters";
import { CountyHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCounty";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { STRINGIFY_OPTIONS } from "pages/Reports/utils/validateParams/utils/adjustObjectProperty";
import {
  pathnameSelector,
  queryParamsSelector
} from "store/selectors/routerSelectors";
import { REPORT_TYPES } from "store/utils/constants";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { QP } from "utils/defaultQueryParams";
import { translateFeParamsToBeParams } from "utils/translateFEParamsToBEParams";
import { Values } from "utils/types";

const exportEndpointMap = {
  [REPORTS_FULL_PATHS.SELL_PATH]: REST_API_ENDPOINTS.REPORTS.SALES_EXPORT,
  [REPORTS_FULL_PATHS.SHARES_PATH]: REST_API_ENDPOINTS.REPORTS.SHARES_EXPORT,
  [REPORTS_FULL_PATHS.DYNAMICS_PATH]:
    REST_API_ENDPOINTS.REPORTS.DYNAMICS_EXPORT,
  [REPORTS_FULL_PATHS.RECEIPTS_PATH]: REST_API_ENDPOINTS.REPORTS.RECEIPTS_EXPORT
};

const useEndpoint = () => {
  const pathname = useSelector(pathnameSelector);
  if (pathname in exportEndpointMap) {
    return exportEndpointMap[pathname];
  }

  // handle case when location report has additional url segment e.g. reports/location/[voivodeship]
  if (pathname.startsWith(REPORTS_FULL_PATHS.LOCATION_PATH)) {
    return REST_API_ENDPOINTS.REPORTS.SALES_BY_LOCATION_EXPORT;
  }

  return "";
};

// handle case when location report has additional url segment e.g. reports/location/[voivodeship]
// in this case only selected voivodeship and it's coutnies should be returned instead of values from query params
const useLocationReportParams = () => {
  const pathname = useSelector(pathnameSelector);
  const [{ all: allCounties }] = CountyHooks.useCounty();

  if (!pathname.startsWith(REPORTS_FULL_PATHS.LOCATION_PATH)) return {};

  const voivodeship = pathname.split("/").pop();
  if (!voivodeship) return {};

  const valid = Object.values(VOIVODESHIPS).some(item => item === voivodeship);
  if (!valid) return {};

  const counties = allCounties
    .filter(county => county.voivodeship === voivodeship)
    .map(county => county.value)
    .join(",");

  return {
    [QP.VOIVODESHIPS]: voivodeship,
    [QP.COUNTIES]: counties
  };
};

const useQueryString = (type: Values<typeof REPORT_TYPES>) => {
  const params = useSelector(queryParamsSelector);
  const isHistoricalDataEnabled = useSelector(isHistoricalDataEnabledSelector);
  const filters = useVisibleList().map(({ name }) => name);
  const locationParams = useLocationReportParams();

  const exportParams = { ...params, ...locationParams };
  const paramsToPick = filters
    .flatMap(filter => filtersParamsMap[filter])
    .filter(Boolean);

  if (isHistoricalDataEnabled) {
    paramsToPick.push(
      ...[QP.COMPARE_DATE_FROM, QP.COMPARE_DATE_TO, QP.INCLUDE_LEAP_YEAR]
    );
  }

  let period = "";
  if (type === REPORT_TYPES.CHART) {
    period = String(params[QP.CHART_PERIOD] || "");
  } else if (type === REPORT_TYPES.TABLE) {
    period = String(params[QP.TABLE_PERIOD] || "");
  }

  const { host, pathname, search } = window.location;
  const reportUrl = [host, pathname, search].join("");

  return (
    translateFeParamsToBeParams(
      qs.stringify(pick(paramsToPick, exportParams), STRINGIFY_OPTIONS),
      period
    ) + `&report_url=${encodeURIComponent(reportUrl)}`
  );
};

export const useExcelExportUrl = (type: Values<typeof REPORT_TYPES>) => {
  const endpoint = useEndpoint();
  const queryString = useQueryString(type);
  const excelFileQuery = useQuery(
    ["excel-export-url", endpoint, queryString],
    async () => {
      if (!endpoint) {
        throw new Error("Excel export endpoint not found.");
      }
      const response = await HTTP.get<{ url: string }>(
        `${endpoint}?${queryString}`
      );
      return response.data.url;
    },
    {
      enabled: false,
      retry: false,
      staleTime: 1000 * 60 * 60 // 1 hour
    }
  );

  return {
    isRefetching: excelFileQuery.isRefetching,
    refetch: excelFileQuery.refetch
  };
};
