import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";
import qs from "query-string";
import { equals } from "ramda";

import { SpinnerLoader, Text } from "components/atoms";
import { Search } from "components/atoms/Icon";
import {
  Dropdown,
  FilterDropdownInput,
  FilterDropdownListItem,
  FilterDropdownMenu
} from "components/molecules";
import { UnactiveProductsCheckbox } from "components/organisms/UnactiveProductsCheckbox/UnactiveProductsCheckbox";
import { updateUserActivityTimestamp } from "pages/Login/redux/reducer";
import s from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/CompetitorsFilters/competitorsFilters.module.scss";
import {
  DROPDOWN_LABELS,
  DROPDOWN_PLACEHOLDERS
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/constants";
import { FilterErrorMsg } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/FilterErrorMsg/FilterErrorMsg";
import {
  filterItems,
  isActiveFn
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import {
  asyncFiltersSelector,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import {
  allCompetitionProductsSelector,
  handleCompetitionProductSelection,
  selectAllActiveOrInactiveCompetitionProducts,
  selectedCompetitionProductsLabelSelector,
  sortCompetitionProductsBySelectionOrder
} from "pages/Reports/redux/reducers/filters/competition/competitionProductsFilterReducer";
import { useCompetitionProductBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useCompetitorProductFetching";
import { ProductItem } from "pages/Reports/redux/reducers/sweetStateHooks/useProduct";
import { pushFiltersEvent } from "utils/googleTagManager/dataLayer";

type Props = {
  selectedItems: ProductItem[];
  isDisabled: boolean;
};

export const CompetitionProductDropdown = ({
  selectedItems,
  isDisabled
}: Props) => {
  const dispatch = useDispatch();
  const productItems = useSelector(allCompetitionProductsSelector, equals);
  const isLoading = useSelector(asyncFiltersSelector).fetching
    .competitorProducts;
  const selectedCompetitionProductsLabel = useSelector(
    selectedCompetitionProductsLabelSelector,
    equals
  );
  const errorMsg = useSelector(asyncFiltersSelector).error.competitorProducts;

  useCompetitionProductBehaviour(isDisabled);

  const [isOpen, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const handleChange = (item: ProductItem | null) => {
    setOpen(true);
    dispatch(handleCompetitionProductSelection(item));
    setTimeout(() => {
      dispatch(updateUserActivityTimestamp(Date.now())); // Fix to update dropdown which required double click to save selection. This can be removed when react sweet state management system is implemented
    }, 200);
  };

  const handleInputValueChange = (value?: string) => {
    if (!value && value !== "") {
      return;
    }

    setInputValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
    setInputValue("");
  };

  const handleClose = (selectedItem?: ProductItem | null) => {
    setOpen(false);
    if (!selectedItem) {
      setInputValue(selectedCompetitionProductsLabel);
      dispatch(sortCompetitionProductsBySelectionOrder());
    }
  };

  const handleStateChange = (changes: StateChangeOptions<ProductItem[]>) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem);
    }
  };

  const handleSelectAllActive = () => {
    dispatch(selectAllActiveOrInactiveCompetitionProducts(inputValue, true));
    pushFiltersEvent("suppliers product - select active");
  };

  const handleSelectAllInactive = () => {
    dispatch(selectAllActiveOrInactiveCompetitionProducts(inputValue, false));
    pushFiltersEvent("suppliers product - select inactive");
  };

  const handleClear = () => {
    handleChange(null);
    pushFiltersEvent("suppliers product - clear");
  };

  const filterProductByInput = (inputValue: string) => (item: ProductItem) => {
    const isItemSelected = selectedItems.some(
      ({ value }) => value === item.value
    );

    //always show selected item
    if (isItemSelected) {
      return true;
    }
    const competitionProductMatchInputValue = filterItems<ProductItem>(
      inputValue
    )(item);

    return competitionProductMatchInputValue;
  };

  React.useEffect(() => {
    if (!isOpen) setInputValue(selectedCompetitionProductsLabel);
  }, [isOpen, selectedCompetitionProductsLabel]);

  if (!isLoading && errorMsg) {
    return (
      <FilterErrorMsg
        label={DROPDOWN_LABELS.PRODUCT}
        callback={() => {
          dispatch(
            updateQuery({
              filter: "competitorProducts",
              query: qs.stringify({})
            })
          );
        }}
        errorMsg={errorMsg}
      />
    );
  }

  return (
    <Dropdown<ProductItem[]>
      className={s.dropdownWrapper}
      isDisabled={isLoading || isDisabled}
      isMultiChoice
      isMultiChoiceSplitted
      //@ts-ignore
      itemToString={() => {}}
      selectedItem={selectedItems}
      items={productItems}
      label={DROPDOWN_LABELS.PRODUCT}
      openedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.PRODUCT.OPEN
      }
      closedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.PRODUCT.CLOSED
      }
      inputValue={inputValue}
      onInputValueChange={handleInputValueChange}
      isOpen={isOpen}
      //@ts-ignore
      onChange={handleChange}
      onClear={handleClear}
      onStateChange={handleStateChange}
      onSelectAllActive={handleSelectAllActive}
      onSelectAllInactive={handleSelectAllInactive}
      input={
        <FilterDropdownInput
          customIcon={isLoading ? <SpinnerLoader size="small" /> : <Search />}
          isTypingEnabled
          automaticInputValue={selectedCompetitionProductsLabel}
          testId="competition-products-dropdown"
        />
      }
      menu={
        <FilterDropdownMenu<ProductItem>
          isItemActiveFn={isActiveFn}
          filterItemsFn={filterProductByInput}
          listItem={<FilterDropdownListItem shouldMarkWithdrawnItems={true} />}
          minimumItemsToVirtualize={30}
          inlinePlaceholder={
            isLoading && (
              <Text className={s.inlinePlaceholder}>Pobieranie danych</Text>
            )
          }
          bottomElement={<UnactiveProductsCheckbox isCompetition />}
        />
      }
      onOuterClick={() => setOpen(false)}
    />
  );
};
