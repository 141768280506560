import { useSelector } from "react-redux";

import { useReceiptsTableData } from "api/receipts/mappers/useReceiptsTableData";
import { flatten } from "ramda";

import { isDistributionDisabledSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import {
  ReceiptsTableColumn,
  ReceiptsTableRow
} from "pages/Reports/sections/Receipts/ReceiptsTableSection";
import { getRowPeriod } from "pages/Reports/utils";
import { generateTableColumnId } from "pages/Reports/utils/generateTableColumnId";
import { tierSelector } from "store/reducers/userReducer";
import { tablePeriodSelector } from "store/selectors/routerSelectors";
import { NO_DATA, sortTypeByFloatValue, sortTypeByPeriod } from "utils";
import { getPolishLabelForTableTimePeriod } from "utils/polishLabels/getPolishLabelForTableTimePeriod";

export const useReceiptsTableColumns = (): ReceiptsTableColumn[] => {
  const period = useSelector(tablePeriodSelector);
  const receipts = useReceiptsTableData();
  const isDistributionDisabled = useSelector(isDistributionDisabledSelector);
  const tier = useSelector(tierSelector);

  const tablePeriodHeader = getPolishLabelForTableTimePeriod(period);
  const columns = flatten(
    receipts.map(({ name }) => {
      const idPrefix = generateTableColumnId(name);
      const distributionColumns: ReceiptsTableColumn[] = isDistributionDisabled
        ? []
        : [
            {
              Header: `${name} - liczba sklepów`,
              accessor: row => String(row[`${idPrefix}-AVG_SHOPS_COUNT`]),
              id: `${idPrefix}-AVG_SHOPS_COUNT`,
              sortType: sortTypeByFloatValue(`${idPrefix}-AVG_SHOPS_COUNT`)
            },
            {
              Header: `${name} - dystrybucja %`,
              accessor: row => String(row[`${idPrefix}-DISTRIBUTION_RANGE`]),
              id: `${idPrefix}-DISTRIBUTION_RANGE`,
              sortType: sortTypeByFloatValue(`${idPrefix}-DISTRIBUTION_RANGE`)
            }
          ];

      return [
        {
          Header: `${name} - Udział w liczbie paragonów total Żabka (%)`,
          accessor: (row: ReceiptsTableRow) =>
            String(row[`${idPrefix}-SHARE_IN_RECEIPTS_TOTAL_SHOP`] || NO_DATA),
          id: `${idPrefix}-SHARE_IN_RECEIPTS_TOTAL_SHOP`,
          sortType: sortTypeByFloatValue(
            `${idPrefix}-SHARE_IN_RECEIPTS_TOTAL_SHOP`
          )
        },
        {
          Header: `${name} - Udział w liczb. paragonów kategorii (%)`,
          accessor: (row: ReceiptsTableRow) =>
            String(row[`${idPrefix}-SHARE_IN_RECEIPTS_CATEGORY`] || NO_DATA),
          id: `${idPrefix}-SHARE_IN_RECEIPTS_CATEGORY`,
          sortType: sortTypeByFloatValue(
            `${idPrefix}-SHARE_IN_RECEIPTS_CATEGORY`
          )
        },
        {
          Header: `${name} - Liczba szt. na 1 paragon`,
          accessor: (row: ReceiptsTableRow) =>
            String(row[`${idPrefix}-ITEMS_PER_RECEIPT`] || NO_DATA),
          id: `${idPrefix}-ITEMS_PER_RECEIPT`,
          sortType: sortTypeByFloatValue(`${idPrefix}-ITEMS_PER_RECEIPT`)
        },
        {
          Header: `${name} - Wartość sprzed. na 1 paragon`,
          accessor: (row: ReceiptsTableRow) =>
            String(row[`${idPrefix}-WORTH_PER_RECEIPT`] || NO_DATA),
          id: `${idPrefix}-WORTH_PER_RECEIPT`,
          sortType: sortTypeByFloatValue(`${idPrefix}-WORTH_PER_RECEIPT`)
        },
        {
          Header: `${name} - Liczba paragonów`,
          accessor: (row: ReceiptsTableRow) =>
            String(row[`${idPrefix}-RECEIPTS_QUANTITY`] || NO_DATA),
          id: `${idPrefix}-RECEIPTS_QUANTITY`,
          sortType: sortTypeByFloatValue(`${idPrefix}-RECEIPTS_QUANTITY`)
        },
        {
          Header: `${name} - Średnia liczba paragonów / 1 sklep`,
          accessor: (row: ReceiptsTableRow) =>
            String(row[`${idPrefix}-RECEIPTS_PER_SHOP`] || NO_DATA),
          id: `${idPrefix}-RECEIPTS_PER_SHOP`,
          sortType: sortTypeByFloatValue(`${idPrefix}-RECEIPTS_PER_SHOP`)
        },
        ...distributionColumns
      ];
    })
  );

  const promotionColumns: ReceiptsTableColumn[] =
    tier < 3
      ? []
      : [
          {
            Header: "Promocje",
            accessor: row => row.promotions,
            id: "promotions",
            sortType: "alphanumeric"
          }
        ];

  return [
    {
      Header: tablePeriodHeader,
      accessor: (row: ReceiptsTableRow) => getRowPeriod(period, row),
      id: "period",
      sortType: sortTypeByPeriod(period)
    },
    ...columns,
    ...promotionColumns
  ];
};
