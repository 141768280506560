import { useEffect, useRef } from "react";

import { Nullable } from "utils/types";

export const useIntervalAsync = (
  callback: () => void,
  delay: Nullable<number>
) => {
  const callbackRef = useRef(() => {});
  const loadingRef = useRef(false);
  const cancelRef = useRef(false);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    cancelRef.current = delay === null;

    const tick = async () => {
      if (loadingRef.current || cancelRef.current) return;

      loadingRef.current = true;
      await callbackRef.current();
      loadingRef.current = false;

      timeout = setTimeout(tick, delay!);
    };

    const cancel = () => {
      cancelRef.current = true;
      clearTimeout(timeout);
    };

    if (delay !== null) {
      tick();
      return cancel;
    }
  }, [delay]);
};
