import qs from "query-string";

import { FETCHING_PARAMETERS } from "./defaultQueryParams";

export const getValidFetchFilters = (query: string) => {
  const queryParameters = qs.parse(query, { arrayFormat: "comma" });

  const fetchingParameters = FETCHING_PARAMETERS.reduce(
    (filteredParameters, parameter) => {
      if (queryParameters[parameter]) {
        return {
          ...filteredParameters,
          [parameter]: queryParameters[parameter]
        };
      }

      return filteredParameters;
    },
    {}
  );

  return qs.stringify(fetchingParameters, { arrayFormat: "comma" });
};
