import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";

import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { CategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCategory";
import {
  CategoryLevel,
  ReferenceCategoryHooks,
  ReferenceCategoryItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useReferenceCategory";
import { VendorHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVendor";
import { isCompaniesDropdownMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { tierSelector } from "store/reducers/userReducer";
import { queryParamsSelector } from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { Nullable } from "utils/types";

type ReferenceCategoryItemApi = {
  id: number;
  name: string;
  level: CategoryLevel;
  parent: Nullable<ReferenceCategoryItemApi>;
  start_date: string;
  is_fake: boolean;
};

interface FetchParams {
  level: number;
  companies: string;
  tier: string;
  ids?: string;
  parents?: string;
}

type RequestParams = {
  category: string;
  level: string;
  tier: string;
  companies: string;
};

// UTILS
const fetchCategory = (params: FetchParams) =>
  HTTP.get<Promise<ReferenceCategoryItemApi[]>>(REST_API_ENDPOINTS.CATEGORIES, {
    params
  });

const fetchAndStandardizeReferenceCategories = async (
  params: RequestParams
): Promise<ReferenceCategoryItem[][]> => {
  let response1, response2, response3;
  let dataset1: ReferenceCategoryItemApi[] = [],
    dataset2: ReferenceCategoryItemApi[] = [],
    dataset3: ReferenceCategoryItemApi[] = [];

  const { companies, tier, category } = params;

  // if the highest available category for user is G1
  if (params.level === "1") {
    // fetch G1 ref. cat. - selected G1 in filters
    response1 = await fetchCategory({
      level: 1,
      companies,
      tier,
      ids: category
    });
    dataset1 = await response1.data;

    if (!dataset1.length) return [[], [], []];

    // fetch G2 ref. cat. - subcategories of available G1 ref. cat.
    response2 = await fetchCategory({
      level: 2,
      companies,
      tier,
      parents: category
    });
    dataset2 = await response2.data;

    // fetch G3 ref. cat. - subcategories of available G2 ref. cat.
    const parents = dataset2.map(({ id }) => id).join(",");
    response3 = await fetchCategory({ level: 3, companies, tier, parents });
    dataset3 = await response3.data;
  }

  // if the highest available category for user is G2 (in this case there is no G1 ref. cat.)
  if (params.level === "2") {
    // fetch G2 ref. cat. - selected G2 in filters
    response2 = await fetchCategory({
      level: 2,
      companies,
      tier,
      ids: category
    });
    dataset2 = await response2.data;

    if (!dataset2.length) return [[], [], []];

    // fetch G3 ref. cat. - subcategories of available G2 ref. cat.
    const parents = dataset2.map(({ id }) => id).join(",");
    response3 = await fetchCategory({
      level: 3,
      companies,
      tier,
      parents
    });
    dataset3 = await response3.data;
  }

  // if the highest available category for user is G3 (in this case there is no G1 & G2 ref. cat.)
  if (params.level === "3") {
    // fetch G3 ref. cat. - selected G3 in filters
    response3 = await fetchCategory({
      level: 3,
      companies,
      tier,
      ids: category
    });
    dataset3 = await response3.data;
  }

  return [dataset1, dataset2, dataset3].map(data =>
    data.map(({ id, name, parent, start_date, is_fake, level }) => ({
      label: name,
      value: id,
      parent,
      startDate: start_date,
      isFake: is_fake,
      level
    }))
  );
};

// HOOKS
const useFilterParams = (): RequestParams => {
  const isFormPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const params = useSelector(queryParamsSelector);

  const [category1] = CategoryHooks.useSelectedCategoriesIds(1);
  const [category2] = CategoryHooks.useSelectedCategoriesIds(2);
  const [category3] = CategoryHooks.useSelectedCategoriesIds(3);
  const isCompaniesDropdownMultiChoice = useSelector(
    isCompaniesDropdownMultiChoiceSelector
  );
  const [vendorIds] = VendorHooks.useSelectedVendorsIds(
    isCompaniesDropdownMultiChoice
  );
  const tier = useSelector(tierSelector);

  // highest selected category and level
  const [category, level] = category1.length
    ? [category1.join(","), "1"]
    : category2.length
    ? [category2.join(","), "2"]
    : category3.length
    ? [category3.join(","), "3"]
    : ["", ""];
  const [categoryQP, levelQP] = params.category1
    ? [String(params.category1), "1"]
    : params.category2
    ? [String(params.category2), "2"]
    : params.category3
    ? [String(params.category3), "3"]
    : ["", ""];

  const companies = vendorIds.join(",");
  const companiesQP = String(params.vendor_id);

  return isFormPristine
    ? {
        category: categoryQP,
        level: levelQP,
        companies: companiesQP,
        tier: String(params.tier)
      }
    : {
        category,
        level,
        companies: companies ? companies : companiesQP,
        tier: String(tier)
      };
};

const useFetchingFlag = (params: RequestParams, isDisabled: boolean) => {
  const isLoading = useSelector(asyncFiltersSelector).fetching
    .referenceCategory;
  const lastQuery = useSelector(asyncFiltersSelector).query.referenceCategory;
  const query = qs.stringify(params);

  if (isLoading || isDisabled) {
    return false;
  }

  if (query === lastQuery) {
    return false;
  }

  if (!params.category || !params.level || !params.companies || !params.tier) {
    return false;
  }

  return true;
};

export const useReferenceCategoryBehaviour = (isDisabled: boolean) => {
  const [, actions] = ReferenceCategoryHooks.useReferenceCategory();
  const dispatch = useDispatch();
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const params = useFilterParams();
  const isFetchingPossible = useFetchingFlag(params, isDisabled);
  const queryParams = useSelector(queryParamsSelector);

  const saveAndSelectCallback = useCallback(
    (categories: ReferenceCategoryItem[][]) => {
      actions.updateAllCategories(categories[0], 1);
      actions.updateAllCategories(categories[1], 2);
      actions.updateAllCategories(categories[2], 3);

      if (isPristine) {
        actions.checkPristineSelectionsAfterFetching(categories, queryParams);
      } else {
        actions.checkSelectionsAfterFetching(categories);
      }
    },
    [actions, isPristine, queryParams]
  );

  useEffect(() => {
    if (!isFetchingPossible) return;

    const fetch = async () => {
      const filter = "referenceCategory";

      try {
        dispatch(updateQuery({ filter, query: qs.stringify(params) }));

        dispatch(setFlag({ filter, isFetching: true }));
        const referenceCategories = await fetchAndStandardizeReferenceCategories(
          params
        );
        dispatch(setError({ filter, status: "" }));

        return referenceCategories;
      } catch (error) {
        dispatch(
          setError({
            filter,
            status: "Błąd pobierania kategorii referencyjnych"
          })
        );

        return [[], [], []];
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };

    fetch().then(saveAndSelectCallback);
  }, [dispatch, isFetchingPossible, params, saveAndSelectCallback]);
};
