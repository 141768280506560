import {
  Action,
  createHook,
  createStore,
  defaultRegistry
} from "react-sweet-state";

type State = {
  lfl: boolean;
  isDisabledByBackData: boolean;
};

type Actions = typeof actions;

const actions = {
  updateLFL: (lfl: boolean): Action<State> => ({ setState }) => {
    setState({ lfl });
  },
  updateDisabled: (isDisabled: boolean): Action<State> => ({ setState }) => {
    setState({ isDisabledByBackData: isDisabled });
  },
  clearLFL: (): Action<State> => ({ setState }) => {
    setState({ lfl: false });
  }
};

export const LFLStore = createStore<State, Actions>({
  name: "lfl",
  initialState: {
    lfl: false,
    isDisabledByBackData: false
  },
  actions
});

export const LFLHooks = {
  useLFL: createHook(LFLStore)
};

export const LFLStoreInstance = defaultRegistry.getStore(LFLStore);
