import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { useLocationHoveredRegion } from "api/location/mappers/useLocationHoveredRegion";
import { TooltipContent } from "api/types";
import cn from "classnames";

import {
  Button,
  NiceScrollbar,
  PromotionContent,
  SidebarData,
  SpinnerLoader,
  Text
} from "components/atoms";
import { ChevronLeft, ChevronRight, TooltipIcon } from "components/atoms/Icon";
import cardStyles from "components/molecules/Card/card.module.scss";
import {
  useFetchSidebarInsights,
  useSidebarInsightsAccess
} from "pages/Insights/hooks/useSidebarInsights";
import { InsightsDetails } from "pages/Insights/partials/InsightsDetails/InsightsDetails";
import { SidebarInsights } from "pages/Insights/partials/SidebarInsights/SidebarInsights";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import {
  hoveredTimepointSelector,
  pinnedTimepointSelector,
  selectedSidebarTabSelector,
  updateSelectedSidebarTab
} from "pages/Reports/redux/reducers/chartReducer";
import { isSidebarMetricEnabledSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import {
  toggleChartSidebar,
  updateChartSidebarWidth
} from "store/actions/appActions";
import { CHART_SIDEBAR_MIN_WIDTH } from "store/reducers/appReducer";
import {
  chartSidebarWidthSelector,
  isChartSidebarOpenSelector
} from "store/selectors/appSelectors";
import {
  activeInsightQPSelector,
  chartPeriodSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";
import { ICON_SIZES, isThisPage, PERIOD_TYPE } from "utils";
import { pushSidebarEvent } from "utils/googleTagManager/dataLayer";
import { Nullable } from "utils/types";

import { ShowSidebarChange } from "../ShowSidebarChange/ShowSidebarChange";
import { SidebarMetricSelect } from "../SidebarMetricSelect/SidebarMetricSelect";
import { SidebarTour } from "../Tour/components/SidebarTour";
import s from "./chartSidebar.module.scss";
import { useSegmentsSidebarOpenStatus, useSidebarPromotions } from "./hooks";
import { ResizeHandle } from "./ResizeHandle";

type Props = {
  data: Nullable<TooltipContent>;
  isLoading: boolean;
};

enum ContentState {
  data,
  preview
}

export const ChartSidebar: FC<Props> = ({ data, isLoading }) => {
  const [isSidebarBorderClicked, setSidebarBorderClicked] = useState<boolean>(
    false
  );

  const dispatch = useDispatch();
  const selectedSidebarTab = useSelector(selectedSidebarTabSelector);
  const isOpen = useSelector(isChartSidebarOpenSelector);
  const period = useSelector(chartPeriodSelector);
  const chartSidebarWidth = useSelector(chartSidebarWidthSelector);
  const pinnedTimepoint = useSelector(pinnedTimepointSelector);
  const hoveredTimepoint = useSelector(hoveredTimepointSelector);
  const activeInsightQP = useSelector(activeInsightQPSelector);
  const isSidebarMetricEnabled = useSelector(isSidebarMetricEnabledSelector);

  const hoveredRegion = useLocationHoveredRegion();
  const { both: isBothDatasets } = useCurrentDataset();
  const [isInsightsOnly, setInsightsOnly] = useState<boolean>(false);
  const sidebarInsights = useFetchSidebarInsights();
  const areInsightsEnabled = useSidebarInsightsAccess();

  // [PMD-4531]: display count of all matched and max 3 unmatched insights
  const sidebarInsightsCount = useMemo(() => {
    if (!sidebarInsights.data) return 0;
    const allCount = sidebarInsights.data.length;
    const matchedCount = sidebarInsights.data.filter(
      insight => insight.similarity >= 1
    ).length;
    const unmatchedCount = Math.min(3, allCount - matchedCount);
    return matchedCount + unmatchedCount;
  }, [sidebarInsights.data]);

  const insightForDetailsIndex = sidebarInsights?.data
    ? sidebarInsights.data.findIndex(
        insight => insight.insightId === activeInsightQP
      )
    : -1;
  const showDetailsModal = areInsightsEnabled && insightForDetailsIndex > -1;

  const {
    isDisabled: arePromotionsDisabled,
    info: promotionsInfo
  } = useSidebarPromotions(data);

  const pathname = useSelector(pathnameSelector);
  const isSegmentsPage = isThisPage(pathname, REPORTS_FULL_PATHS.SEGMENTS_PATH);

  const sidebarTimepointDate = data?.date || null;
  const isRanking = period === PERIOD_TYPE.RANKING;
  const isDataDisabled = isSegmentsPage || isRanking;

  const isChartTimepoint =
    sidebarTimepointDate !== null || hoveredRegion !== null;

  const shouldShowData =
    isChartTimepoint || isRanking || sidebarInsightsCount !== 0;

  const contentState = shouldShowData
    ? ContentState.data
    : ContentState.preview;

  const tabs = useMemo(() => {
    return [
      ...(isDataDisabled
        ? []
        : [
            {
              label: "Dane",
              isDisabled: isInsightsOnly,
              content: <SidebarData sidebarData={data} />
            }
          ]),
      ...(arePromotionsDisabled
        ? []
        : [
            {
              label: "Promocje",
              isDisabled: isInsightsOnly,
              content: (
                <PromotionContent sidebarData={data} info={promotionsInfo} />
              )
            }
          ]),
      ...(areInsightsEnabled
        ? [
            {
              label: "Insights",
              isDisabled: false,
              content: <SidebarInsights />
            }
          ]
        : [])
    ];
  }, [
    areInsightsEnabled,
    arePromotionsDisabled,
    isDataDisabled,
    isInsightsOnly,
    promotionsInfo,
    data
  ]);

  useEffect(() => {
    if (
      areInsightsEnabled &&
      sidebarInsightsCount > 0 &&
      !isChartTimepoint &&
      !hoveredTimepoint
    ) {
      const insightsTabIndex = tabs.findIndex(tab => tab.label === "Insights");
      dispatch(updateSelectedSidebarTab(insightsTabIndex));
      setInsightsOnly(true);
    }

    if (hoveredTimepoint !== null) {
      dispatch(updateSelectedSidebarTab(0));
      setInsightsOnly(false);
    }

    if (tabs.length - 1 < selectedSidebarTab) {
      dispatch(updateSelectedSidebarTab(0));
    }

    if (isChartTimepoint && pinnedTimepoint !== null) {
      setInsightsOnly(false);
    }
  }, [
    dispatch,
    selectedSidebarTab,
    sidebarInsightsCount,
    tabs,
    isChartTimepoint,
    hoveredTimepoint,
    pinnedTimepoint,
    areInsightsEnabled
  ]);

  useSegmentsSidebarOpenStatus();

  const INFORMATIONS = [
    "Najedź myszą na wykres, aby zobaczyć szczegóły",
    "Kliknij na wykres, aby przypiąć podgląd danych na jeden dzień"
  ];

  const onTabSelect = (index: number) => {
    dispatch(updateSelectedSidebarTab(index));
    pushSidebarEvent(`show ${tabs[index].label}`);
    return false; //this func has to return false because of weird `onSelect` typing
  };

  const toggleSidebar = () => {
    pushSidebarEvent(isOpen ? "hide Panel" : "show Panel");
    dispatch(toggleChartSidebar(!isOpen));

    setTimeout(
      () => dispatch(updateChartSidebarWidth(CHART_SIDEBAR_MIN_WIDTH)),
      800
    );
  };

  return (
    <>
      <Rnd
        className={s.chartSidebarBox}
        enableResizing={{
          top: false,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false
        }}
        disableDragging
        default={{ width: chartSidebarWidth, height: "auto", x: 0, y: 0 }}
        minWidth={isOpen ? CHART_SIDEBAR_MIN_WIDTH : "0%"}
        maxWidth={isOpen ? "60%" : "0%"}
        resizeHandleComponent={{
          left: <ResizeHandle isSidebarBorderClicked={isSidebarBorderClicked} />
        }}
        size={{ width: chartSidebarWidth, height: "auto" }}
        onResizeStop={(e, dir, ref) => {
          if (chartSidebarWidth === ref.style.width) return;

          dispatch(updateChartSidebarWidth(ref.style.width));
          pushSidebarEvent("size change");
          setSidebarBorderClicked(false);
        }}
        onResizeStart={() => {
          setSidebarBorderClicked(true);
        }}
      >
        {isLoading && (
          <SpinnerLoader
            className={{
              wrapper: cardStyles.loader
            }}
          />
        )}
        <Button
          className={cn(s.button, { [s.buttonOpen]: isOpen })}
          onClick={toggleSidebar}
          data-testid="chart-sidebar-toggle-button"
        >
          {isOpen ? (
            <ChevronRight size={ICON_SIZES.EXTRA_LARGE} />
          ) : (
            <ChevronLeft size={ICON_SIZES.EXTRA_LARGE} />
          )}
        </Button>
        <div
          className={cn(s.chartSidebarAbsoluteWrapper, {
            [s.chartSidebarAbsoluteWrapperClosed]: !isOpen
          })}
          data-testid="chart-sidebar-content-wrapper"
        >
          {contentState === ContentState.preview && (
            <div className={s.chartSidebarPreviewWrapper}>
              <Text className={s.previewHeader}>Podgląd</Text>
              {INFORMATIONS.map(info => (
                <div className={s.previewInfoWrapper} key={info}>
                  <TooltipIcon className={s.tooltipIcon} />
                  <Text className={s.previewText}>{info}</Text>
                </div>
              ))}
            </div>
          )}
          {contentState === ContentState.data && (
            <>
              <Text className={s.header} testId="main-sidebar-header">
                {data?.header || ""}
              </Text>
              <div className={s.options}>
                {isSidebarMetricEnabled && <SidebarMetricSelect />}
                {isBothDatasets && <ShowSidebarChange />}
              </div>
              <Tabs
                selectedIndex={selectedSidebarTab}
                onSelect={onTabSelect}
                className={s.tabsWrapper}
                forceRenderTabPanel
              >
                <TabList className={s.tabsList}>
                  {tabs.map(tab => (
                    <Tab
                      key={`tab-${tab.label}`}
                      className={s.tabLabel}
                      disabled={tab.isDisabled}
                      data-testid={`chart-sidebar-tab-${tab.label}`}
                    >
                      <span
                        className={cn({ [s.tabTextDisabled]: tab.isDisabled })}
                      >
                        {tab.label}
                        {tab.label === "Insights" &&
                          sidebarInsightsCount > 0 && (
                            <span className={s.badge}>
                              {sidebarInsightsCount}
                            </span>
                          )}
                      </span>
                    </Tab>
                  ))}
                </TabList>
                {tabs.map(tab => (
                  <TabPanel key={`panel-${tab.label}`}>
                    <NiceScrollbar
                      classNames={{
                        horizontalTrack: s.scrollbarHorizontalTrack,
                        verticalTrack: s.scrollbarVerticalTrack,
                        horizontalThumb: s.scrollbarHorizontalThumb,
                        verticalThumb: s.scrollbarVerticalThumb
                      }}
                    >
                      {tab.content}
                    </NiceScrollbar>
                  </TabPanel>
                ))}
              </Tabs>
            </>
          )}
        </div>
      </Rnd>
      <SidebarTour />
      {showDetailsModal && (
        <InsightsDetails
          index={insightForDetailsIndex}
          data={sidebarInsights.data![insightForDetailsIndex]}
          previousId={
            sidebarInsights.data![insightForDetailsIndex - 1]?.insightId || ""
          }
          nextId={
            sidebarInsights.data![insightForDetailsIndex + 1]?.insightId || ""
          }
        />
      )}
    </>
  );
};
