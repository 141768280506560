import { ThunkAction } from "redux-thunk";
import { createSelector } from "reselect";

import { DropdownItem } from "components/molecules/types";
import { getSelectedCountLabel } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import {
  UpdateAllCompetitionProductAction,
  updateAllCompetitionProducts
} from "pages/Reports/redux/reducers/filters/competition/competitionProductsFilterReducer";
import {
  mapCompetitionCompaniesResponse,
  sortItemsBySelectionOrder
} from "pages/Reports/redux/utils";
import { AppState } from "store";
import { RESET_DATA, resetData } from "store/actions/appActions";
import { competitionCompaniesQueryParamSelector } from "store/selectors/routerSelectors";
import { isAlreadySelected, isIncludedInInput } from "store/utils/filtersUtils";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { Thunk, Values } from "utils/types";

import { Parent } from "../categoryFilters/categoryFiltersActions";

// CONST
const UPDATE_ALL_COMPETITION_COMPANIES = "REPORTS.UPDATE_ALL_COMPETITION_COMPANIES" as const;
const UPDATE_SELECTED_COMPETITION_COMPANIES = "REPORTS.UPDATE_SELECTED_COMPETITION_COMPANIES" as const;

// TYPES
export type CompanyItem = DropdownItem<string> & {
  parent?: Parent | null;
};

export type CompanyItemApi = {
  id: string;
  display: string;
};
type UpdateSelectedCompetitionCompaniesAction = {
  type: typeof UPDATE_SELECTED_COMPETITION_COMPANIES;
  payload: CompanyItem[];
};

type CompetitionCompanies = {
  allItems: CompanyItem[];
  selectedItems: CompanyItem[];
  loadingStatus: boolean;
};

type UpdateAllCompetitionCompanyAction = {
  type: typeof UPDATE_ALL_COMPETITION_COMPANIES;
  payload: CompanyItem[];
};

type UpdateSelectedCompetitionCompanyAction = {
  type: typeof UPDATE_SELECTED_COMPETITION_COMPANIES;
  payload: CompanyItem[];
};

type CompetitionCompaniesActions = {
  UPDATE_COMPETITION_COMPANIES: UpdateAllCompetitionCompanyAction;
  UPDATE_SELECTED_COMPETITION_COMPANIES: UpdateSelectedCompetitionCompanyAction;
};

type ClearCompetitionCompaniesFilterAction =
  | UpdateSelectedCompetitionCompaniesAction
  | UpdateAllCompetitionCompanyAction
  | UpdateAllCompetitionProductAction;

//SELECTORS
const competitionCompaniesSelector = (state: AppState): CompetitionCompanies =>
  state.reports.competitionFilters.competitionCompanies || initialState;

export const allCompetitionCompaniesSelector = createSelector<
  AppState,
  CompetitionCompanies,
  CompanyItem[]
>(competitionCompaniesSelector, ({ allItems }) => allItems);

export const selectedCompetitionCompaniesSelector = createSelector<
  AppState,
  CompetitionCompanies,
  CompanyItem[]
>(competitionCompaniesSelector, ({ selectedItems }) => selectedItems);

export const selectedCompetitionCompaniesIdsSelector = createSelector<
  AppState,
  CompanyItem[],
  string[]
>(selectedCompetitionCompaniesSelector, selectedCompetitionCompanies =>
  selectedCompetitionCompanies.map(({ value }) => value)
);

export const selectedCompetitionCompaniesLabelSelector = createSelector<
  AppState,
  CompanyItem[],
  CompanyItem[],
  string
>(
  selectedCompetitionCompaniesSelector,
  allCompetitionCompaniesSelector,
  (selectedCompetitionCompanies, allCompetitionCompanies) =>
    getSelectedCountLabel(
      selectedCompetitionCompanies.length,
      allCompetitionCompanies.length
    )
);

// ACTIONS
const updateSelectedCompetitionCompanies = (
  payload: CompanyItem[]
): UpdateSelectedCompetitionCompanyAction => ({
  type: UPDATE_SELECTED_COMPETITION_COMPANIES,
  payload
});

export const updateAllCompetitionCompanies = (
  payload: CompanyItem[]
): UpdateAllCompetitionCompanyAction => ({
  type: UPDATE_ALL_COMPETITION_COMPANIES,
  payload
});

//THUNKS
export const fetchCompetitorCompanies = async (params: object) => {
  const response = await HTTP.get<CompanyItemApi[]>(
    REST_API_ENDPOINTS.COMPANIES.GET_COMPETITION_COMPANIES,
    {
      params
    }
  );

  return mapCompetitionCompaniesResponse(response?.data || []);
};

export const checkCompetitorCompaniesPristineSelectionsAfterFetching: () => Thunk<
  Values<CompetitionCompaniesActions>
> = () => (dispatch, getState) => {
  const companies = allCompetitionCompaniesSelector(getState());
  const selectedCompaniesQP = competitionCompaniesQueryParamSelector(
    getState()
  );

  const responseIds = companies.map(cmp => cmp.value);
  const preselectedCompanies = selectedCompaniesQP
    .split(",")
    .filter(company => responseIds.includes(company));

  if (preselectedCompanies.length) {
    const companiesToSelect: CompanyItem[] = preselectedCompanies
      .map(cmp => {
        const existing = companies.find(fetched => fetched.value === cmp);
        if (existing) {
          return {
            label: existing.label,
            value: existing.value
          };
        }
        return {
          label: "",
          value: ""
        };
      })
      .filter(prod => prod !== null);
    dispatch(updateSelectedCompetitionCompanies(companiesToSelect));
  }
};

export const checkCompetitorCompaniesSelectionsAfterFetching: (
  ids: string[]
) => Thunk<Values<CompetitionCompaniesActions>> = ids => (
  dispatch,
  getState
) => {
  const selected = selectedCompetitionCompaniesSelector(getState());
  const matched = selected.filter(cmp => ids.includes(cmp.value));
  dispatch(updateSelectedCompetitionCompanies(matched));
};

export const handleCompetitionCompanySelection = (
  selectedCompany: CompanyItem | null
): ThunkAction<
  void,
  AppState,
  undefined,
  UpdateSelectedCompetitionCompaniesAction
> => (dispatch, getState) => {
  const selectedCompetitionCompanies = selectedCompetitionCompaniesSelector(
    getState()
  );

  if (!selectedCompany) {
    return dispatch(updateSelectedCompetitionCompanies([]));
  }

  const isItemAlreadySelected = selectedCompetitionCompanies.some(
    ({ value }) => value === selectedCompany.value
  );

  if (isItemAlreadySelected) {
    return dispatch(
      updateSelectedCompetitionCompanies(
        selectedCompetitionCompanies.filter(
          ({ value }) => value !== selectedCompany.value
        )
      )
    );
  }

  dispatch(
    updateSelectedCompetitionCompanies([
      ...selectedCompetitionCompanies,
      selectedCompany
    ])
  );
};

export const sortAllCompetitionCompaniesBySelectionOrder: () => ThunkAction<
  void,
  AppState,
  undefined,
  UpdateAllCompetitionCompanyAction
> = () => (dispatch, getState) => {
  const state = getState();

  const allCompetitionCompanies = allCompetitionCompaniesSelector(state);
  const selectedCompetitionCompaniesIds = selectedCompetitionCompaniesIdsSelector(
    state
  );

  const sortedAllCompetitionCompanies = sortItemsBySelectionOrder(
    selectedCompetitionCompaniesIds,
    allCompetitionCompanies
  );
  dispatch(updateAllCompetitionCompanies(sortedAllCompetitionCompanies));
};

export const clearCompetitionCompaniesFilter = (): ThunkAction<
  void,
  AppState,
  undefined,
  ClearCompetitionCompaniesFilterAction
> => dispatch => {
  dispatch(updateSelectedCompetitionCompanies([]));
  dispatch(updateAllCompetitionCompanies([]));
  dispatch(updateAllCompetitionProducts([]));
};

export const selectAllCompetitionCompanies = (
  inputValue: string
): ThunkAction<
  void,
  AppState,
  undefined,
  UpdateSelectedCompetitionCompaniesAction
> => (dispatch, getState) => {
  const state = getState();
  const allCompetitionCompanies = allCompetitionCompaniesSelector(state);
  const selectedCompetitionCompanies = selectedCompetitionCompaniesSelector(
    state
  );

  dispatch(
    updateSelectedCompetitionCompanies([
      ...selectedCompetitionCompanies,
      ...allCompetitionCompanies.filter(
        item =>
          !isAlreadySelected(selectedCompetitionCompanies, item) &&
          isIncludedInInput(inputValue, item)
      )
    ])
  );
};

//REDUCER
const initialState: CompetitionCompanies = {
  loadingStatus: false,
  allItems: [],
  selectedItems: []
};

export const competitionCompaniesFilterReducer = (
  state: CompetitionCompanies = initialState,
  action: Values<CompetitionCompaniesActions> | ReturnType<typeof resetData>
): CompetitionCompanies => {
  switch (action.type) {
    case UPDATE_ALL_COMPETITION_COMPANIES:
      return {
        ...state,
        allItems: action.payload
      };
    case UPDATE_SELECTED_COMPETITION_COMPANIES:
      return {
        ...state,
        selectedItems: action.payload
      };

    case RESET_DATA:
      return initialState;

    default:
      return state;
  }
};
