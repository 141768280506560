import Measure, { ContentRect } from "react-measure";
import { useDispatch, useSelector } from "react-redux";

import { Cursor, TierValidator } from "components/atoms";
import s from "components/common.module.scss";
import { ChartData } from "components/D3/types";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { TilesDropdown } from "components/organisms/TilesDropdown/TilesDropdown";
import { PromotionsChart } from "components/visualisation/charts/PromotionsChart/PromotionsChart";
import { useChartTopLabel } from "hooks/useChartTopLabel";
import { DotsChart, RightChart } from "pages/Reports/partials/Chart";
import { ChartPinTooltip } from "pages/Reports/partials/Chart/StandardChart/ChartPinTooltip";
import { AreaChartSection } from "pages/Reports/partials/Chart/StandardChart/components/AreaChartSection";
import { BarsChart } from "pages/Reports/partials/Chart/StandardChart/components/BarsChart";
import { GridChart } from "pages/Reports/partials/Chart/StandardChart/components/GridChart";
import { LabelsChart } from "pages/Reports/partials/Chart/StandardChart/components/LabelsChart";
import { LinesChart } from "pages/Reports/partials/Chart/StandardChart/components/LinesChart";
import { TIERS_WITH_PROMOTIONS } from "pages/Reports/redux/selectors/promotionsSelectors";
import { isShowDatasetSelectEnabledSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { segmentsChartSelector } from "pages/Reports/sections/Segments/redux/reducers/segmentsChartReducer";
import { REPORTS_PATHS } from "pages/Reports/utils";
import { updateChartWidth } from "store/actions/appActions";
import { chartWidthSelector } from "store/selectors/appSelectors";
import {
  isGroupedBySegmentsSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";

import { ChartValueTiles } from "../ChartValueTiles/ChartValueTiles";
import { GroupedBarChart } from "../GroupedBarChart/GroupedBarChart";
import { IncrementalChart } from "./components/IncrementalChart";
import { IncrementalComboChart } from "./components/IncrementalComboChart";
import { ShowDatasetSelect } from "./components/ShowDatasetSelect/ShowDatasetSelect";
import { ZappkaTransactionSelect } from "./components/WithZappkaSelect";
import { getSegmentsChartType } from "./utils/getSegmentsChartType";
import { useChartHover } from "./utils/useChartHover";
import { useChartValueTilesVisibility } from "./utils/useChartValueTilesSettings";

type Props = {
  lineChart: ChartData & { fakeDomain?: string[] };
  id?: string;
};

const height = 280;

const margin = {
  top: 40,
  bottom: 40,
  left: 100,
  right: 100
};

export const ChartSection: React.FC<Props> = ({ lineChart, id = "" }) => {
  const dispatch = useDispatch();
  const width = useSelector(chartWidthSelector);
  const gridWidth = width - margin.left - margin.right;
  const pathname = useSelector(pathnameSelector);
  const isSegmentsPage = pathname.includes(REPORTS_PATHS.SEGMENTS);
  const isShowDatasetSelectEnabled = useSelector(
    isShowDatasetSelectEnabledSelector
  );

  const { currentChart } = useSelector(segmentsChartSelector);
  const isGroupedBySegments = useSelector(isGroupedBySegmentsSelector);

  const [isTilesSelectionEnabled] = useChartValueTilesVisibility();
  const {
    isAreaChart,
    isGroupedBarChart,
    isIncrementalChart,
    isIncrementalComboChart
  } = useChartTypeBoolean();

  const {
    labels,
    domain,
    fakeDomain = [],
    leftChart,
    rightChart,
    period,
    showBarChart,
    onTimelineHover,
    onTimelineClick,
    isHistoricalGroupedChart,
    isTimelineMerged = false
  } = lineChart;

  const showGroupedBarChart = (() => {
    // [PMD-4031]: display regular bar chart on segments due to differences in domain
    if (isSegmentsPage && !isGroupedBySegments && showBarChart) return false;
    return isGroupedBarChart;
  })();

  //hide ChartValueTiles component if there is barchart or Stacked bar chart
  const showTiles = !showBarChart && !showGroupedBarChart;

  const { wholeDateLabel } = useChartTopLabel({
    isHistoricalGroupedChart,
    isGroupedBarChart: showGroupedBarChart && !isTimelineMerged
  });

  const transformSvgContainer = `translate(${margin.left}, ${margin.top / 2})`;
  const showChartOptions = !isSegmentsPage && !isHistoricalGroupedChart;
  const displayTilesDropdown = isTilesSelectionEnabled && !isSegmentsPage;
  const isCurrentChart = isSegmentsPage ? currentChart === id : true;
  const renderBarChart = [
    showGroupedBarChart,
    isIncrementalChart,
    isIncrementalComboChart
  ].every(isSelected => !isSelected);

  // [PMD-3186]: use fake domain to display promotions on segments bar chart
  const isFakeDomain =
    isSegmentsPage && getSegmentsChartType(lineChart) !== "line-chart";
  const areAllLinesHidden =
    !leftChart?.chart.length && !rightChart?.chart.length;

  const handleResize = ({ entry }: ContentRect) => {
    const chartWidth = Number(entry?.width) || 0;

    if (isHistoricalGroupedChart || !chartWidth || chartWidth === width) return;

    dispatch(updateChartWidth(chartWidth));
  };

  const { onMouseMove, onMouseLeave, onClick } = useChartHover({
    onTimelineHover,
    domain,
    gridWidth,
    onTimelineClick
  });

  return (
    <>
      {!isHistoricalGroupedChart && (
        <div className={s.chartTopOptions}>
          {!showChartOptions ? null : isShowDatasetSelectEnabled ? (
            <ShowDatasetSelect />
          ) : null}
          {!isSegmentsPage && <ZappkaTransactionSelect />}
          {displayTilesDropdown && <TilesDropdown lineChart={lineChart} />}
        </div>
      )}
      <span className={s.chartSectionDatesLabel}>{wholeDateLabel}</span>
      <Measure onResize={handleResize}>
        {({ measureRef }) => (
          <Cursor>
            {({ x, y }) => (
              <div className={s.chart} ref={measureRef} data-testid="chart-box">
                <svg
                  width={width}
                  height={height + margin.top + margin.bottom}
                  onMouseMove={onMouseMove}
                  onMouseLeave={onMouseLeave}
                  onClick={onClick}
                >
                  <g transform={transformSvgContainer}>
                    <TierValidator accessLevels={TIERS_WITH_PROMOTIONS}>
                      <PromotionsChart
                        dimensions={{ width, height }}
                        areAllLinesHidden={areAllLinesHidden}
                        domain={isFakeDomain ? fakeDomain : domain}
                        lineChart={lineChart}
                        isHistoricalGroupedChart={isHistoricalGroupedChart}
                      />
                    </TierValidator>

                    <LabelsChart
                      domain={domain}
                      labels={labels}
                      margin={margin}
                      width={width}
                      height={height}
                      period={period}
                      lineChart={lineChart}
                    />

                    {!showBarChart && isAreaChart && (
                      <AreaChartSection
                        width={width}
                        height={height}
                        margin={margin}
                        lineChart={lineChart}
                      />
                    )}

                    <GridChart
                      chartId={id}
                      y={y}
                      width={gridWidth}
                      originalWidth={width}
                      showTooltip={isCurrentChart}
                      lineChart={lineChart}
                    />

                    {!renderBarChart && (
                      <>
                        {showGroupedBarChart && (
                          <GroupedBarChart
                            lineChart={lineChart}
                            width={gridWidth}
                            originalWidth={width}
                          />
                        )}
                        {isIncrementalChart && (
                          <IncrementalChart
                            lineChart={lineChart}
                            height={height}
                            width={gridWidth}
                            originalWidth={width}
                          />
                        )}
                        {isIncrementalComboChart && (
                          <IncrementalComboChart
                            lineChart={lineChart}
                            width={gridWidth}
                            originalWidth={width}
                          />
                        )}
                        {!isTimelineMerged && (
                          <DotsChart
                            lineChart={lineChart}
                            width={gridWidth}
                            originalWidth={width}
                            onTimelineHover={onTimelineHover}
                            onTimelineClick={onTimelineClick}
                          />
                        )}
                      </>
                    )}

                    <LinesChart
                      showLineChart={!showBarChart}
                      data={leftChart}
                      isOneTimepoint={showBarChart}
                      lineChart={lineChart}
                      width={width}
                    />

                    {renderBarChart && (
                      <BarsChart
                        chartId={id || ""}
                        showBarChart={showBarChart}
                        lineChart={lineChart}
                        domain={domain}
                        height={height}
                        width={gridWidth}
                        originalWidth={width}
                        onTimelineHover={onTimelineHover}
                        onTimelineClick={onTimelineClick}
                      />
                    )}

                    <RightChart
                      lineChart={lineChart}
                      width={width}
                      height={height}
                      margins={margin}
                      axisLabel={labels.right}
                      isOneTimepoint={showBarChart && !isTimelineMerged}
                    />

                    {showTiles && (
                      <ChartValueTiles
                        gridWidth={gridWidth}
                        lineChart={lineChart}
                      />
                    )}
                  </g>
                </svg>

                {isCurrentChart && (
                  <ChartPinTooltip
                    positionX={x}
                    positionY={y}
                    onTimelineClick={onTimelineClick}
                    margins={margin}
                    lineChart={[lineChart]}
                  />
                )}
              </div>
            )}
          </Cursor>
        )}
      </Measure>
    </>
  );
};
