import { DropdownItem } from "components/molecules/types";
import { Values } from "utils/types";

export type Parent = { id: number; parent?: Parent | null };

export type CategoryLevel = 1 | 2 | 3;

export type CategoryItem = DropdownItem<number> & {
  parent?: Parent | null;
  startDate: string;
  isFake: boolean;
};

type UpdateCategoriesErrorMsgAction = {
  type: typeof UPDATE_CATEGORIES_ERROR_MSG;
  payload: string;
};

export type CategoryItemsActions = Values<{
  UpdateCategoriesErrorMsgAction: UpdateCategoriesErrorMsgAction;
}>;

export const UPDATE_CATEGORIES_ERROR_MSG = "Filters.UPDATE_CATEGORIES_ERROR_MSG" as const;

// UTILS
export const getCategoryIds = (categoryParam: string[]): number[] => {
  if (!categoryParam.length) return [];

  const categoryArray = Array.isArray(categoryParam)
    ? categoryParam
    : [categoryParam];

  return categoryArray.reduce<number[]>((result, next) => {
    if (Number.isNaN(Number(next))) return result;

    return [...result, Number(next)];
  }, []);
};
