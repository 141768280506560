import { CompanyItemApi } from "pages/Reports/redux/reducers/filters/competition/competitionCompaniesFilterReducer";

export const mapCompetitionCompaniesResponse = (data: CompanyItemApi[]) => {
  if (!data.length) {
    return [];
  }
  return data.map(({ id, display }) => ({
    label: display,
    value: id
  }));
};
