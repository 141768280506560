import {
  Action,
  createHook,
  createStore,
  defaultRegistry
} from "react-sweet-state";

import { DropdownItem } from "components/molecules/types";
import {
  getMatched,
  getSelectedCountLabel,
  remainingElementsToSelect,
  selectElement
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import { sortItemsBySelectionOrder } from "pages/Reports/redux/utils";
import { Nullable } from "utils/types";

export type CountyItem = DropdownItem & {
  voivodeship: string;
};

export type CountyItemApi = {
  name?: string;
  voivodeship?: string;
};

type State = { all: CountyItem[]; selected: CountyItem[] };
type Actions = typeof actions;

export const getSelectedCounties = (state: State) => state.selected;

const actions = {
  updateAllCounties: (all: CountyItem[]): Action<State> => ({ setState }) =>
    setState({
      all
    }),
  updateSelectedCounties: (selected: CountyItem[]): Action<State> => ({
    setState
  }) =>
    setState({
      selected
    }),
  selectCounty: (selectedCounty: Nullable<CountyItem>): Action<State> => ({
    setState,
    getState
  }) =>
    setState({
      selected: selectElement(selectedCounty, getState().selected)
    }),
  selectAllCounties: (inputValue: string): Action<State> => ({
    setState,
    getState
  }) => {
    const state = getState();
    const allCounties = state.all;
    const selectedCounties = state.selected;

    const remainingToSelect = remainingElementsToSelect(
      selectedCounties,
      allCounties,
      inputValue
    );
    setState({
      selected: [...selectedCounties, ...remainingToSelect]
    });
  },
  sortCounties: (): Action<State> => ({ setState, getState }) => {
    const state = getState();

    const allCounties = state.all;
    const selectedCountiesIds = state.selected.map(item => item.value);

    const sortedAllCounties = sortItemsBySelectionOrder(
      selectedCountiesIds,
      allCounties
    );
    setState({
      all: sortedAllCounties
    });
  },
  clearCounties: (): Action<State> => ({ setState }) =>
    setState({
      selected: []
    }),
  checkSelectionsAfterFetching: (ids: string[]): Action<State> => ({
    getState,
    setState
  }) =>
    setState({
      selected: getMatched(getState().selected, ids)
    }),
  checkPristineSelectionsAfterFetching: (
    counties: CountyItem[],
    countyQueryParam: string | string[]
  ): Action<State> => ({ setState }) => {
    const updatedSelectedCounties = counties.filter(({ value }) => {
      if (typeof countyQueryParam === "string") {
        return value === countyQueryParam;
      }
      return countyQueryParam.includes(value);
    });

    setState({
      selected: updatedSelectedCounties
    });
  }
};

export const CountyStore = createStore<State, Actions>({
  name: "county",
  initialState: {
    all: [],
    selected: []
  },
  actions
});

export const CountyHooks = {
  useCounty: createHook(CountyStore),
  useCountyLabel: createHook(CountyStore, {
    selector: (state: State) => {
      if (state.selected.length === 1) {
        return state.selected[0].label;
      }
      return getSelectedCountLabel(state.selected.length, state.all.length);
    }
  }),
  useSelectedCounties: createHook(CountyStore, {
    selector: getSelectedCounties
  }),
  useSelectedCountiesIds: createHook(CountyStore, {
    selector: (state: State) => state.selected.map(item => item.value)
  }),
  useStringifiedSelectedCountiesIds: createHook(CountyStore, {
    selector: (state: State) =>
      state.selected.map(item => item.label).join(", ")
  })
};

export const CountyStoreInstance = defaultRegistry.getStore(CountyStore);
