import qs from "query-string";

import { getValidFetchFilters } from "utils";
import { translateFeParamsToBeParams } from "utils/translateFEParamsToBEParams";

export const generateReportExportParams = (
  pathname = "",
  query = "",
  period: string
) => {
  const reportUrl = window.location.host + pathname + query;
  const filteredQuery = getValidFetchFilters(query);
  const translatedQuery = translateFeParamsToBeParams(filteredQuery, period, {
    report_url: reportUrl
  });

  return qs.parse(translatedQuery);
};
