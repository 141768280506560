import { sort, uniq } from "ramda";

import { FetchedPromotion } from "pages/Reports/redux/reducers/promotionsReducer";
import { NULL_LABEL, PERIOD_TYPE } from "utils/constants";
import { Periods } from "utils/types";

import { isDateInPromotionInterval } from ".";
import { PROMOTIONS_SEPARATOR } from "./constants";

export const getPromotionsColumn = (
  date: Date,
  promotions: FetchedPromotion[],
  period: Periods
): string => {
  const promotionsForPeriod =
    period === PERIOD_TYPE.TOTAL
      ? promotions
      : promotions.filter(promotion =>
          isDateInPromotionInterval(date, promotion, period)
        );

  return promotionsForPeriod.length === 0
    ? NULL_LABEL
    : uniq(
        sort(
          (nameA, nameB) => nameA.localeCompare(nameB, "pl"),
          promotionsForPeriod.map(({ promotionGroup }) => promotionGroup)
        )
      ).join(PROMOTIONS_SEPARATOR);
};
