import { ProductItemApi } from "pages/Reports/redux/reducers/filters/competition/competitionProductsFilterReducer";

const DEFAULT_PRODUCT_ITEM_RESPONSE = {
  material: 0,
  display: "",
  ean: ""
};

export const mapProductsResponse = (response: { data: ProductItemApi[] }) =>
  response.data.map(({ material, display, ean, category }) => ({
    value: material || DEFAULT_PRODUCT_ITEM_RESPONSE.material,
    label: display || DEFAULT_PRODUCT_ITEM_RESPONSE.display,
    parent: category
      ? {
          id: category.id,
          parent: category.parent?.id
            ? {
                id: category.parent.id,
                parent: category.parent?.parent?.id
                  ? { id: category.parent?.parent?.id }
                  : null
              }
            : null
        }
      : null
  }));
