import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useIsReferenceSummaryAvailable } from "api/utils/useIsReferenceSummaryAvailable";

import { SummaryTab } from "components/molecules/SummarySwitch/SummarySwitch";
import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { useVisibleList } from "pages/Reports/redux/reducers/filters/hooks/useVisibleFilters";
import { REPORTS_FULL_PATHS, SELL_SUMMARY_TABS } from "pages/Reports/utils";
import {
  DYNAMICS_SUMMARY_TABS,
  LOCATION_SUMMARY_TABS,
  LOYALTY_SUMMARY_TABS,
  MULTIPACK_SUMMARY_SUB_TABS,
  MULTIPACK_SUMMARY_TABS,
  RECEIPTS_SUMMARY_TABS,
  SHARES_SUMMARY_TABS,
  SUMMARY_SUB_SWITCH,
  SUMMARY_SUB_TABS,
  SUMMARY_SWITCH
} from "pages/Reports/utils/constants";
import {
  uppercasedDynamicsSummaryTabSelector,
  uppercasedLocationSummaryTabSelector,
  uppercasedLoyaltySummaryTabSelector,
  uppercasedMultipackSummaryTabSelector,
  uppercasedReceiptsSummaryTabSelector,
  uppercasedSellSummaryTabSelector,
  uppercasedSharesSummaryTabSelector,
  uppercasedSummarySubTypeSelector
} from "store/selectors/routerSelectors";
import { isThisPage } from "utils";
import { QP } from "utils/defaultQueryParams";

interface Settings {
  tabs: SummaryTab[];
  active: string;
  param: string;
}

const {
  SELL_PATH,
  LOCATION_PATH,
  SHARES_PATH,
  DYNAMICS_PATH,
  RECEIPTS_PATH,
  LOYALTY_PATH,
  MULTIPACK_PATH
} = REPORTS_FULL_PATHS;
const { REFERENCE_CATEGORY } = FILTER_NAMES;

export const useSummaryTabs = () => {
  const { pathname } = useLocation();
  const filters = useVisibleList();

  const sell = useSelector(uppercasedSellSummaryTabSelector);
  const location = useSelector(uppercasedLocationSummaryTabSelector);
  const shares = useSelector(uppercasedSharesSummaryTabSelector);
  const dynamics = useSelector(uppercasedDynamicsSummaryTabSelector);
  const receipts = useSelector(uppercasedReceiptsSummaryTabSelector);
  const loyalty = useSelector(uppercasedLoyaltySummaryTabSelector);
  const multipack = useSelector(uppercasedMultipackSummaryTabSelector);

  const visibleSubTab = useSelector(uppercasedSummarySubTypeSelector);

  const isRefCatInData = useIsReferenceSummaryAvailable();

  const hasReferenceCategoryFilter = !!filters.find(
    ({ name }) => name === REFERENCE_CATEGORY
  );

  let primary: Settings = { tabs: [], active: "", param: "" };
  let secondary: Settings = { tabs: [], active: "", param: "" };

  // primary tabs
  if (isThisPage(pathname, SELL_PATH)) {
    primary = {
      tabs: SELL_SUMMARY_TABS,
      active: sell,
      param: QP.SELL_SUMMARY_TYPE
    };
  }

  if (isThisPage(pathname, LOCATION_PATH)) {
    primary = {
      tabs: LOCATION_SUMMARY_TABS,
      active: location,
      param: QP.LOCATION_SUMMARY_TYPE
    };
  }

  if (isThisPage(pathname, SHARES_PATH)) {
    primary = {
      tabs: SHARES_SUMMARY_TABS,
      active: shares,
      param: QP.SHARES_SUMMARY_TYPE
    };
  }

  if (isThisPage(pathname, DYNAMICS_PATH)) {
    primary = {
      tabs: DYNAMICS_SUMMARY_TABS,
      active: dynamics,
      param: QP.DYNAMICS_SUMMARY_TYPE
    };
  }

  if (isThisPage(pathname, RECEIPTS_PATH)) {
    primary = {
      tabs: RECEIPTS_SUMMARY_TABS,
      active: receipts,
      param: QP.RECEIPTS_SUMMARY_TYPE
    };
  }

  if (isThisPage(pathname, LOYALTY_PATH)) {
    primary = {
      tabs: LOYALTY_SUMMARY_TABS,
      active: loyalty,
      param: QP.LOYALTY_SUMMARY_TYPE
    };
  }

  if (isThisPage(pathname, MULTIPACK_PATH)) {
    return {
      primary: {
        tabs: MULTIPACK_SUMMARY_TABS,
        active: multipack,
        param: QP.MULTIPACK_SUMMARY_TYPE
      },
      secondary: {
        tabs: MULTIPACK_SUMMARY_SUB_TABS.filter(tab =>
          isRefCatInData
            ? true
            : [
                SUMMARY_SUB_SWITCH.SELL_OWN,
                SUMMARY_SUB_SWITCH.SHARES_OWN,
                SUMMARY_SUB_SWITCH.NATURAL_UNITS_OWN
              ].includes(tab.value)
        ),
        active: visibleSubTab,
        param: QP.SUMMARY_SUB_TYPE
      }
    };
  }

  // secondary tabs
  if (!hasReferenceCategoryFilter) {
    secondary = {
      tabs: [],
      active: "",
      param: QP.SUMMARY_SUB_TYPE
    };

    return { primary, secondary };
  }

  // [PMD-4747]: add tooltip to the shares summary own data tab, clone is needed to prevent overriding original object
  const subTabsClone = structuredClone(SUMMARY_SUB_TABS);
  if (isThisPage(pathname, SHARES_PATH)) {
    Object.assign(subTabsClone[0], {
      tooltip:
        "Udziały w podsumowaniu są prezentowane dla kategorii, do których należą dane produkty."
    });
  }

  // [PMD-2665]: disable ref. cat. for distribution
  if (!isRefCatInData || primary.active === SUMMARY_SWITCH.DISTRIBUTION) {
    secondary = {
      tabs: [subTabsClone[0]],
      active: subTabsClone[0].value,
      param: QP.SUMMARY_SUB_TYPE
    };

    return { primary, secondary };
  }

  secondary = {
    tabs: subTabsClone,
    active: visibleSubTab,
    param: QP.SUMMARY_SUB_TYPE
  };

  // [PMD-2994]: disable distribution for ref. cat.
  if (secondary.active === subTabsClone[1].value) {
    primary.tabs = primary.tabs.filter(
      ({ value }) => value !== SUMMARY_SWITCH.DISTRIBUTION
    );
  }

  return { primary, secondary };
};
