import { useSelector } from "react-redux";

import { useGetRanking } from "api/ranking/hooks/useGetRanking";
import { getDataTypeKey } from "api/utils";
import { isEmpty } from "ramda";

import { getRankingData } from "pages/Reports/partials/Chart/HorizontalBarChart/utils";
import { useDataTypeSuffix } from "pages/Reports/partials/Chart/StandardChart/components/WithZappkaSelect";
import { useGetZappkaSharesSuffix } from "pages/Reports/sections/NewChartDropdown/ZappkaSharesCheckbox/hooks";
import {
  firstChartDataTypeSelector,
  isRanking5DistributionHiddenSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";

export const useRankingFullChartData = () => {
  const { data } = useGetRanking();
  const firstDataType = useSelector(firstChartDataTypeSelector);
  const secondDataType = useSelector(secondChartDataTypeSelector);
  const is5RankingDistributionHidden = useSelector(
    isRanking5DistributionHiddenSelector
  );
  const [suffix] = useDataTypeSuffix();
  const getZappkaSharesSuffix = useGetZappkaSharesSuffix();

  const keys = [firstDataType, secondDataType].map((dataType, index) => {
    if (!dataType) return { base: "", primary: "", shares: "" }; // "base" for formatting, "primary" for metric data, "shares" for online shares data
    const sharesSuffix = getZappkaSharesSuffix(firstDataType ? index : 1);
    return {
      base: dataType,
      primary: getDataTypeKey({ dataType, suffix, sharesSuffix: "" }),
      shares: sharesSuffix
        ? getDataTypeKey({ dataType, suffix, sharesSuffix })
        : ""
    };
  });

  if (isEmpty(data)) return [];

  return getRankingData(
    // @ts-ignore TS complains that data might be {}, but it's guarded few lines above
    data,
    keys[0],
    keys[1],
    is5RankingDistributionHidden
  );
};
