import {
  Action,
  createHook,
  createStore,
  defaultRegistry
} from "react-sweet-state";

type State = { excludedDays: string[] };
type Actions = typeof actions;

const actions = {
  getInitialExcludedDaysState: (excludedDaysQP: string): Action<State> => ({
    setState
  }) => {
    setState({
      excludedDays: excludedDaysQP.split(",").filter(day => day !== "")
    });
  },
  updateExcludedDays: (days: string[]): Action<State> => ({ setState }) => {
    setState({
      excludedDays: days
    });
  },
  clearExcludedDays: (): Action<State> => ({ setState }) => {
    setState({
      excludedDays: []
    });
  }
};

export const ExcludedDaysStore = createStore<State, Actions>({
  name: "excludedDays",
  initialState: {
    excludedDays: []
  },
  actions
});

export const ExcludedDaysHooks = {
  useExcludedDays: createHook(ExcludedDaysStore)
};

export const ExcludedDaysStoreInstance = defaultRegistry.getStore(
  ExcludedDaysStore
);
