import { useRankingWarnings } from "api/ranking/mappers";
import { useGetSharesByPeriod } from "api/shares/mappers";
import { useSharesGeneralWarnings } from "api/shares/mappers/useSharesGeneralWarnings";

export const useSharesWarnings = (): string[] => {
  const generalWarnings = useSharesGeneralWarnings();
  const info = useGetSharesByPeriod().data?.info ?? [];
  const rankingWarnings = useRankingWarnings();

  return [...generalWarnings, ...info, ...rankingWarnings].filter(
    warning => !!warning
  );
};
